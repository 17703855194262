import {
  CREATE_LOCATION,
  CREATE_LOCATION_COMPLETED,
  UPDATE_LOCATION,
  UPDATE_LOCATION_COMPLETED,
  RECEIVE_LOCATIONS,
  REQUEST_LOCATIONS,
  CLEAR_LOCATIONS,
} from "actions/locations.actions";

const defaultState = { isFetching: false, loaded: false, locations: {} };

function location(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_LOCATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case CLEAR_LOCATIONS:
      return { ...defaultState };

    case RECEIVE_LOCATIONS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        locations: action.data,
      };
    case CREATE_LOCATION:
      return {
        ...state,
        // loaded: false,
        // isFetching: true,
      };
    case CREATE_LOCATION_COMPLETED:
      return { ...defaultState };
    case UPDATE_LOCATION:
      return {
        ...state,
        // loaded: false,
        // isFetching: true,
      };
    case UPDATE_LOCATION_COMPLETED:
      return { ...defaultState };
    // return {
    // 	...state,
    // 	loaded: true,
    // 	isFetching: true,
    // 	locations: action.location,
    // 	lastUpdated: action.receivedAt,
    // };
    default:
      return state;
  }
}

export default location;
