import { connect } from "react-redux";

import {
  searchCustomers,
  clearSearchCustomers,
} from "actions/customers.actions";

import CustomersComponent from "./donors.component";

const mapStateToProps = (state) => ({
  searchCustomerResults: {
    ...state.searchCustomers,
  },
});

const mapDispatchToProps = {
  searchCustomers,
  clearSearchCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersComponent);
