import React, { useState } from "react";
import { Modal, Button, ListGroupItem } from "react-bootstrap";

import List from "components/list";
import RenderIf from "hoc/render-if";

const ModalCore = (props) => {
  return (
    <Modal
      animation={false}
      show={props.show}
      onHide={props.onHide}
      style={{ opacity: 1 }}
      // centered
      size={props.size || "lg"}
      backdrop={props.backdrop || "static"}
    >
      <Modal.Header closeButton>
        <div>{props.title}</div>
      </Modal.Header>
      {props.children}
      <RenderIf condition={!!props.footer}>
        <Modal.Footer>{props.footer}</Modal.Footer>
      </RenderIf>
    </Modal>
  );
};

export const ButtonModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        {props.label}
      </Button>
      <ModalCore show={show} onHide={handleClose} {...props}>
        {props.children}
      </ModalCore>
    </React.Fragment>
  );
};

export const ListGroupItemModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <List.Item onClick={handleShow} title={props.label} />
      <ModalCore show={show} onHide={handleClose} {...props}>
        {props.children}
      </ModalCore>
    </React.Fragment>
  );
};

export default ModalCore;
