import React from "react";

const checkComponentOriginProp = (props, componentRow) => {
  let original = null;
  if (props.original) {
    original = props.original;
  } else if (props.row?.original) {
    original = props.row.original;
  }
  if (original) {
    return componentRow(original);
  }
  return <></>;
};

export default checkComponentOriginProp;
