import React, { Component } from "react";
import { withRouter } from "react-router";
import { ListGroupItem, Button } from "react-bootstrap";
import querystring from "query-string";
import DonorUserTable from "./donor-user-table";
import { determineAccountSearchType } from "utils/helpers";

class DonorSearchResults extends Component {
  _clearSearch = () => {
    this.props.clearSearchCustomers();
  };

  _rehydrateSearch = () => {
    this._clearSearch();
    this.searchSubmit();
  };

  render() {
    const {
      searchCustomerResults,
      selectedCurrentTab,
      tab,
      mode,
      activateFirstTab,
    } = this.props;
    const hasCustomers = searchCustomerResults.length > 0;
    const noCustomers = searchCustomerResults.length === 0;
    const isFetching = searchCustomerResults.isFetching;
    const searchError = searchCustomerResults.isError;
    const errorMessage = searchCustomerResults.isError
      ? `${searchCustomerResults.error.error} : ${searchCustomerResults.error.message}`
      : null;

    if (isFetching) {
      return (
        <ListGroupItem>
          <div>loading results...</div>
        </ListGroupItem>
      );
    }

    if (hasCustomers) {
      return (
        <DonorUserTable
          push={this.props.history.push}
          data={searchCustomerResults}
          selected={selectedCurrentTab}
          tab={tab}
          mode={mode}
          onMergeSuccess={() => {
            this._rehydrateSearch();
          }}
          activateFirstTab={activateFirstTab}
          actions={{}}
        />
      );
    }

    if (noCustomers) {
      let addNewButton = null;
      if (this.props.tab.type === "account") {
        const accountSearchType = determineAccountSearchType(
          this.props.tab.name
        );

        if (accountSearchType !== "id")
          addNewButton = (
            <a
              href={`donors/new?${querystring.stringify({
                [determineAccountSearchType(this.props.tab.name)]:
                  this.props.tab.name,
              })}`}
            >
              <Button>Add New</Button>
            </a>
          );
      }

      return (
        <ListGroupItem style={{ border: "none", textAlign: "center" }}>
          <div>There are no results for this search.</div>
          {addNewButton}
        </ListGroupItem>
      );
    }

    if (searchError) {
      return (
        <ListGroupItem>
          <div>{errorMessage}</div>
        </ListGroupItem>
      );
    }
  }
}

export default withRouter(DonorSearchResults);
