import request from "./request";

/**
 * The note API is the same across resources
 * This creates functions that can be used with any of them
 *
 * @param getRoute A function that takes the entity id and returns the route to the notes for that entity
 * @returns {{get: (function(*=): *), create: (function(*=, *): *)}}
 */
const createNotesAPI = (getRoute) => {
  return {
    getCategories: async () =>
      (await request({ url: "/noteCategories" })).data.data,
    get: async (entityId) =>
      (await request({ url: getRoute(entityId) })).data.data,
    create: (entityId, note, noteCategoryId) =>
      request({
        url: getRoute(entityId),
        method: "post",
        data: { note, noteCategoryId },
      }),
    update: (entityId, note) =>
      request({
        url: `${getRoute(entityId)}/${note.id}`,
        method: "put",
        data: { note: note.note },
      }),
    delete: (entityId, note) =>
      request({
        url: `${getRoute(entityId)}/${note.id}`,
        method: "delete",
      }),
  };
};

export default createNotesAPI;
