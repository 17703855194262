import React from "react";
import { Link } from "react-router-dom";
import { Field } from "redux-form";

import FormField from "../FormField";
import { email, required } from "utils/validators";

const ForgotPassword = () => (
  <>
    <Field
      name="email"
      type="text"
      component={FormField}
      label="Email"
      required={true}
      validate={[required, email]}
    />
    <div style={{ textAlign: "right" }}>
      Know your password? <Link to="/login">Login</Link>
    </div>
  </>
);

export default ForgotPassword;
