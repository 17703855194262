import React from "react";
import { Button } from "react-bootstrap";
import Loader from "components/loader";

const styles = {
  button: {
    margin: "0 0 0 4px",
  },
};

class ConfirmButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
    };
  }

  openConfirm = () => {
    const { openConfirmCallback } = this.props;
    this.setState({ showConfirm: true });
    if (openConfirmCallback) {
      openConfirmCallback();
    }
  };
  closeConfirm = () => this.setState({ showConfirm: false });
  renderButtons = () => {
    const { disabled, onConfirm, confirmText, buttonText } = this.props;
    if (this.state.showConfirm) {
      return (
        <div>
          {confirmText || "Are you sure?"}
          <Button
            onClick={() => {
              onConfirm();
              this.closeConfirm();
            }}
            variant="default"
            style={styles.button}
          >
            Yes
          </Button>
          <Button onClick={this.closeConfirm} style={styles.button}>
            No
          </Button>
        </div>
      );
    }

    if (this.props.loading) {
      return (
        <Button disabled={true}>
          <Loader width={"1.75rem"} color={"#fff"} />
        </Button>
      );
    }

    return (
      <Button disabled={disabled} onClick={this.openConfirm}>
        {buttonText}{" "}
      </Button>
    );
  };

  render() {
    return <div>{this.renderButtons()}</div>;
  }
}

export default ConfirmButton;
