import React from "react";
import DateTimePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { FormLabel, FormGroup, FormControl, Alert } from "react-bootstrap";
import { getDateWithoutTimeZone, checkDate, toDate } from "utils/dates";

const DatePicker = ({
  className,
  input,
  label,
  meta: { touched, error, valid },
  type,
  required,
  disabled,
  future = false,
}) => {
  const [value, setValue] = React.useState(undefined);

  const validationError = touched && error;
  let errorComponent;
  let validationState;
  if (validationError) {
    let errorMessage;
    if (error[0] === '"' && error.slice(1).indexOf('"')) {
      errorMessage = `${label || "This"}${error.substr(
        error.slice(1).indexOf('"') + 2
      )}`;
    }
    errorComponent = <Alert variant="danger">{errorMessage || error}</Alert>;
    validationState = "error";
  }

  if (valid) {
    validationState = "success";
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0, 0);

  React.useEffect(() => {
    if (input.value && typeof input.value === "string") {
      checkDate(input.value, ["isIso"], {
        onFail: () => {
          setValue(toDate(getDateWithoutTimeZone(input.value), true));
        },
        onSuccess: () => {
          setValue(toDate(input.value, true));
        },
      });
    } else if (!input.value) {
      setValue(undefined);
    }
  }, [input.value]);

  return (
    <FormGroup
      validationState={validationState}
      className={`${type}-form-group`}
    >
      <FormLabel>
        {label}
        {required && <span className="required-asterick">*</span>}
      </FormLabel>
      <DateTimePicker
        className={`${className} ${validationError ? "rw-widget-error" : ""}`}
        onBlur={input.onBlur}
        {...(future ? { min: today } : {})}
        onChange={(newValue) => {
          if (future && newValue < today) {
            input.onChange(value);
            return;
          }
          input.onChange(getDateWithoutTimeZone(newValue));
        }}
        // time={false}
        value={value}
        disabled={disabled}
      />
      {errorComponent}
      <FormControl.Feedback />
    </FormGroup>
  );
};

export default DatePicker;
