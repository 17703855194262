import { connect } from "react-redux";
import Component from "./donor-search.component";
import {
  searchCustomers,
  clearSearchCustomers,
  removeSearchTab,
} from "actions/donor-search.actions";

const mapStateToProps = (state) => ({
  searchCustomerResults: {
    ...state.searchCustomers,
  },
});

const mapDispatchToProps = {
  searchCustomers,
  clearSearchCustomers,
  removeSearchTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
