import { useContext } from "react";
import { UserContext } from "contexts/user";
import Form from "../context-form";
import EditUserProfileImage from "../edit-user-profile-image";

const UpdateDonorProfile = ({ onCancel, onSubmitSuccess }) => {
  const {
    user,
    userLoading,
    updateUser,
    changeProfileImage,
    resetProfileImage,
  } = useContext(UserContext);

  return (
    <Form
      initialValues={{ fname: user.fname, lname: user.lname }}
      onSubmit={(data) => {
        updateUser({ ...user, ...data }).then(onSubmitSuccess);
      }}
      submitting={userLoading}
      onCancel={onCancel}
    >
      <EditUserProfileImage
        user={user}
        changeProfileImage={changeProfileImage}
        resetProfileImage={resetProfileImage}
      />

      <Form.Field
        type="text"
        label="First Name"
        name="fname"
        options={{ required: "First name is required" }}
      />
      <Form.Field
        type="text"
        label="Last Name"
        name="lname"
        options={{ required: "Last name is required" }}
      />
    </Form>
  );
};

export default UpdateDonorProfile;
