import { createContext } from "react";

export default createContext({
  pickupTypes: [],
  pickupTypesLoading: false,
  pickupTypesError: false,

  getPickupTypes: () => {},
  clearPickupTypesError: () => {},
});
