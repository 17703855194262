import { createContext } from "react";

export default createContext({
  users: [],
  usersPagination: {},
  usersLoading: false,
  usersError: false,

  UserProvider: () => {},
  setUsers: () => {},
  clearUsersError: () => {},
  getUsers: () => {},
});
