import React, { Component } from "react";
import Zones from "pages/zones";
import "../styles.scss";

class RegionZones extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeRegion } = this.props;

    return <Zones regionId={activeRegion.region.id} />;
  }
}

export default RegionZones;
