import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { updateRoutePickup } from "actions/routes.actions";

import RouteAssignPickup from "./route-assign-pickup.component.js";
import withForm from "../form-class";

const connected = withForm(RouteAssignPickup, "RouteAssignPickup");

const formSelector = formValueSelector("RouteAssignPickup");
const mapStateToProps = (state) => ({
  pickupRoutes: {
    ...state.pickupRoutes,
  },
  routeId: formSelector(state, "routeId"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateRoutePickup,
  onSubmit: (values) => {
    const pickup = { ...ownProps.pickup, ...values, sortStopNumbers: true };
    return dispatch(updateRoutePickup(pickup));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(connected);
