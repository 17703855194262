import React, { Component } from "react";
import { isSame, isBefore } from "utils/dates";
import { formatDateHyphenatedYearFirst } from "utils/dates";
import ZoneForm from "forms/create-update-zone";
import submissionErrorHandler from "forms/submissionErrorHandler";
import PickupBlockDeleteModal from "./PickupBlockDeleteModal.component";
import PickupBlockCreateModal from "./PickupBlockCreateModal.component";

import $ from "jquery";
import "fullcalendar";
import "jquery-ui/ui/widgets/draggable";
import "styles/main.scss";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Form,
  Badge,
} from "react-bootstrap";

import Icon from "components/icon";
import Box from "components/box";
import Modal from "components/modal";
import Loader from "components/loader";

// This will store the this point of the component so that the events callback can use it across renders
let context;

class ZonesComponent extends Component {
  constructor(props) {
    super(props);
    context = this;

    this.state = {
      showZoneForm: false,
      editingZone: {},
      showPickupCreateModal: false,
      showPickupBlockDeleteModal: false,
      editPickupBlock: {},
      zoneDeleteError: "",
      eventsLoaded: false,
      calendarLoading: false,
      calendarLoaded: false,
      selectedZones: [],
      isEditingPickupBlock: false,
    };
    this.zoneRef = React.createRef();
    this.handleZoneSelect = this.handleZoneSelect.bind(this);
  }

  componentDidMount() {
    if (this.state.calendarLoading) return;
    this.setState({ calendarLoading: true });

    return Promise.all([
      this.props.requestPickupBlocks({
        futureEvents: true,
        regionId: this.props.regionId,
      }),
      this.props.requestZones({ regionId: this.props.regionId }),
    ]).then(
      () => {
        this.initCalendar();
        this.initDraggableZones();
      }
      // this.setState({eventsLoaded: true})
    );
  }

  _closeModal = () => {
    this.setState({ showPickupCreateModal: false, editPickupBlock: {} });
  };

  _dropEventHandler = (formData) => {
    const editPickupBlock = this.state.editPickupBlock;
    editPickupBlock.stops = formData.stops;

    const pickupBlock = {
      zoneId: editPickupBlock.zoneId,
      date: editPickupBlock.start,
      stops: editPickupBlock.stops,
      regionId: this.props.regionId,
    };

    return this.props
      .createPickupBlock(pickupBlock)
      .then(({ pickupBlock }) => {
        $("#external-calendar").fullCalendar("refetchEvents");
        this._closeModal();
      })
      .catch(() => alert("there was a problem creating pickup block"));
  };

  initCalendar = () => {
    var self = this;

    /* initialize the calendar
         -----------------------------------------------------------------*/
    //TODO: Replace this
    $("#external-calendar").fullCalendar({
      header: {
        left: "prev today",
        center: "title",
        right: "next",
      },
      eventStartEditable: true,
      droppable: true, // this allows things to be dropped onto the calendar !!!
      eventAllow: function (location, event) {
        if (event.id) {
          return false;
        }

        return true;
      },
      eventDrop: (event) => {
        // this.props.updatePickupBlock({ id: event.id, date: event.start })
        // .then(() => alert('pickup block updated'))
        // .catch(() => alert('there was a problem updating pickup block'))
      },
      drop: (date) => {
        // Return if there is already a pickup block for this zone on this day
        if (
          context.props.pickupBlocks.pickupBlocks.find(
            (block) =>
              isSame(block.date, date) &&
              block.zoneId === context.state.currentZone.id
          )
        )
          return;

        if (isBefore(date)) {
          return;
        }

        const { editPickupBlock } = context.state;
        const isEditingPickupBlock = Object.keys(editPickupBlock).length > 0;
        context.setState({
          startDate: date,
          showPickupCreateModal: !isEditingPickupBlock,
          showPickupBlockDeleteModal: isEditingPickupBlock,
        });
      },
      eventRender: (event, element) => {
        element.children()[0].innerHTML +=
          '<span class="edit-pickupBlock float-right">x</span>';

        if (!event.enabled)
          element.css({ opacity: "0.5", filter: "grayscale(1)" });

        element
          .children()
          .children("span")
          .on("click", () => {
            self.setState({
              editPickupBlock: event,
              showPickupBlockDeleteModal: true,
            });
          });
      },
      events: (start, end, timezone, callback) => {
        const { pickupBlocks } = context.props.pickupBlocks;
        const events = pickupBlocks.map((pickupBlock) => {
          const className = [];

          return {
            id: pickupBlock.id,
            title: pickupBlock.zone.name,
            zoneId: pickupBlock.zoneId,
            start: formatDateHyphenatedYearFirst(pickupBlock.date, true),
            stops: pickupBlock.stops,
            enabled: pickupBlock.enabled,
            className: className,
            backgroundColor: pickupBlock.zone.pickupType.color,
          };
        });
        callback(events);
      },
    });

    self.setState({ calendarLoaded: true });
  };

  initDraggableZones = () => {
    const { selectedZones } = this.state;
    const areZonesSelected = selectedZones.length > 0;
    $(".pickup-zones .zone-list-item").each(function () {
      // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
      // it doesn't need to have a start or end

      // make the event draggable using jQuery UI
      $(this).draggable({
        zIndex: 999,
        revert: true, // will cause the event to go back to its
        revertDuration: 0, //  original position after the drag
        helper: function (event) {
          // custom helper component - what shows when dragging
          return $(
            `<div style="background: white; padding: 1rem 3.5rem; text-align: center; width: 100%; border: 1px solid lightgray; border-radius: 4px">
        						<span style="font-weight: bold; text-align: center; color: #6c62ff; font-size: 18px; border-radius: 50%; width: 2.5rem; height: 2.5rem;">${
                      areZonesSelected ? selectedZones.length : 1
                    }</span>
        				</div>`
          );
        },
      });
    });
  };

  createorUpdateZone = (data) => {
    data.zipCodes = data.zipCodes.map((zipCode) => {
      if (zipCode === undefined) {
        return { zip: "" };
      }

      return zipCode;
    });

    let operation;
    if (data.id) {
      //operation = new Promise((fulfilled, rejected) => {
      //    return fulfilled()
      //
      //})
      operation = this.props.updateZone(data.id, {
        name: data.name,
        zipCodes: data.zipCodes,
        defaultBlockStops: data.defaultBlockStops,
        pickupTypeId: data.pickupTypeId,
        regionId: data.regionId,
      });
    } else {
      data.regionId = this.props.regionId;
      if (data.copied) {
        delete data.copied;
      }
      operation = this.props.createZone(data);
    }
    return operation
      .then(async (res) => {
        await this.hideZoneForm();
        await this.props.requestZones({ regionId: this.props.regionId });
        await this.props.requestPickupBlocks({
          futureEvents: true,
          regionId: this.props.regionId,
        });
        $("#external-calendar").fullCalendar("refetchEvents");
        await this.initDraggableZones();
        return res;
      })
      .catch(submissionErrorHandler);
  };

  createOrUpdatePickupBlocks = () => {
    const { selectedZones, startDate, currentZone } = this.state;
    const isAddingOneZone = currentZone && selectedZones.length === 0;
    const zonesToMapOver = isAddingOneZone ? [currentZone] : selectedZones;
    const pickupBlocks = zonesToMapOver.map((zone) => {
      const pickupBlock = {
        zoneId: zone.id,
        date: startDate,
        regionId: this.props.regionId,
        zone,
      };
      return pickupBlock;
    });
    return this.props
      .createPickupBlock(pickupBlocks)
      .then(({ pickupBlock }) => {
        $("#external-calendar").fullCalendar("refetchEvents");
        this._closeModal();
      })
      .catch(() => alert("there was a problem updating pickup block"));
  };

  showZoneForm = (editingZone = {}) =>
    this.setState({ showZoneForm: true, editingZone });

  hideZoneForm = () => this.setState({ showZoneForm: false, editingZone: {} });

  deleteZone = (data) => {
    // Show a confirmation

    return this.props
      .deleteZone(data.id, data)
      .then(() => this.hideZoneForm())
      .then(() => {
        this.props.requestZones({
          regionId: this.props.activeRegion.region.id,
        });
      })
      .catch((err) => {
        this.setState({
          zoneDeleteError:
            "Pickup blocks for this zone must be deleted before zone can be deleted.",
        });
      });
  };

  deletePickupBlock = () => {
    const { editPickupBlock } = this.state;
    this.props.deletePickupBlock(editPickupBlock).then(() => {
      $("#external-calendar").fullCalendar("refetchEvents");
    });
    this.setState({ editPickupBlock: {} });
  };

  clearSelected = () => {
    this.setState({ selectedZones: [] });
    setTimeout(() => this.initDraggableZones(), 0);
  };

  handleZoneSelect = ({ zone }) => {
    const { selectedZones } = this.state;
    const isZoneSelected = selectedZones.some(
      (existingZone) => existingZone.id === zone.id
    );
    if (isZoneSelected) {
      const updatedZones = selectedZones.filter(
        (selectedZones) => selectedZones.id !== zone.id
      );
      this.setState({ selectedZones: updatedZones });
    } else {
      const updatedZones = [...selectedZones, zone];
      this.setState({ selectedZones: updatedZones });
    }
    setTimeout(() => this.initDraggableZones(), 0);
  };

  componentDidUpdate(prevProps) {
    context = this;
  }

  openCopyZoneForm(copy) {
    this.showZoneForm({
      copied: true,
      defaultBlockStops: copy.defaultBlockStops,
      pickupTypeId: copy.pickupTypeId,
      name: copy.name,
      regionId: copy.regionId,
      zipCodes: copy.zipCodes.map((zipCode) => {
        return { zip: zipCode.zip };
      }),
    });
  }

  render() {
    const { zones } = this.props;
    const { selectedZones } = this.state;
    const compare = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };
    return (
      <div>
        <PickupBlockCreateModal
          show={this.state.showPickupCreateModal}
          onConfirm={this.createOrUpdatePickupBlocks}
          close={() =>
            this.setState({
              showPickupCreateModal: false,
            })
          }
        />
        <PickupBlockDeleteModal
          show={this.state.showPickupBlockDeleteModal}
          onConfirm={this.deletePickupBlock}
          close={() =>
            this.setState({
              showPickupBlockDeleteModal: false,
              editPickupBlock: {},
            })
          }
        />
        <Row>
          <Col sm={4} xs={12}>
            <div>
              {selectedZones.length > 0 && (
                <a onClick={() => this.clearSelected()}>Clear</a>
              )}
            </div>
            <ListGroup
              className="pickup-zones"
              style={{ height: 600, overflowY: "auto" }}
            >
              {(zones.zones.sort(compare) || []).map((zone, i) => {
                const isZoneSelected = selectedZones.some(
                  (existingZone) => existingZone.id === zone.id
                );

                if (zone.disabled) {
                  return null;
                }

                return (
                  <ListGroupItem
                    data-zone-id={zone.id}
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="zone-list-item"
                    onMouseDown={() => this.setState({ currentZone: zone })}
                  >
                    <div
                      className={
                        "d-flex align-items-center justify-content-center"
                      }
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ flex: 1 }}
                      >
                        <Form.Check
                          type="checkbox"
                          style={{ margin: "0 .5em 0 0" }}
                          checked={isZoneSelected}
                          onChange={() => this.handleZoneSelect({ zone })}
                        />
                        <h3 style={{ margin: 0 }}>
                          <Badge
                            variant="primary"
                            // className={`badge-${zone.pickupType.color}`}
                            style={{ backgroundColor: zone.pickupType.color }}
                          >
                            {zone.pickupType.label}
                          </Badge>
                          {" " + zone.name}
                        </h3>
                      </div>
                    </div>
                    <div>
                      <Icon
                        className="float-right"
                        onClick={this.showZoneForm.bind(null, zone)}
                        type="edit"
                      />
                    </div>
                  </ListGroupItem>
                );
              })}

              <Modal
                show={this.state.showZoneForm}
                title={this.state.editingZone.id ? "Edit Zone" : "New Zone"}
                onHide={() => {
                  this.setState({ showZoneForm: false });
                }}
              >
                <ZoneForm
                  actionButtons={
                    this.state.editingZone.id
                      ? [
                          {
                            label: "Copy",
                            action: async (props) => {
                              await props.onCancel();
                              this.openCopyZoneForm(props.initialValues);
                            },
                          },
                        ]
                      : []
                  }
                  {...(this.state.editingZone.id ||
                  this.state.editingZone.copied
                    ? {
                        initialValues: this.state.editingZone,
                        onDelete: this.deleteZone,
                        deleteError: this.state.zoneDeleteError,
                      }
                    : {})}
                  onSubmit={this.createorUpdateZone}
                  onCancel={this.hideZoneForm}
                />
              </Modal>
            </ListGroup>
            <Button lg className="float-right" onClick={this.showZoneForm}>
              Add a Zone
            </Button>
          </Col>
          <Col sm={8}>
            <Box>
              {this.state.calendarLoaded ? "" : <Loader />}
              <div id="external-calendar"></div>
            </Box>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ZonesComponent;
