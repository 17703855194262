import { apiModel } from "reducers/_reducer-factory";
import request from "utils/request";

export const getUsers = (query) => request({ url: `/v2/users`, params: query });
export const updateUser = (values) =>
  request({ url: `/v2/users/${values.id}`, method: "put", data: values });
export const createUpdateUser = (values) =>
  values.id
    ? request({ url: `/v2/users/${values.id}`, method: "put", data: values })
    : request({ url: `/v2/users`, method: "post", data: values });

const userActions = new apiModel({
  name: "USERS",
  key: "user_db",
  api: { get: getUsers, update: updateUser, createUpdate: createUpdateUser },
});

export default userActions;
