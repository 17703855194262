import { connect } from "react-redux";

import RouteAssignPickupDriver from "./route-assign-pickup-button.component";

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
});

export default connect(mapStateToProps)(RouteAssignPickupDriver);
