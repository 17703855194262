import React from "react";
import { Field, FieldArray } from "redux-form";
import renderDatePicker from "forms/date-picker";
import FormFieldArray from "forms/FormFieldArray";
import FormField from "forms/FormField";
import { required } from "utils/validators";

const MarketingReportsForm = (props) => {
  const strToNum = (val) => {
    return val && parseInt(val);
  };

  return (
    <React.Fragment>
      <Field
        name="name"
        component={FormField}
        label="Name"
        required={true}
        validate={required}
      />
      <Field
        name="startDate"
        component={renderDatePicker}
        label="Start Date"
        required={true}
        validate={required}
      />
      <Field name="endDate" component={renderDatePicker} label="End Date" />
      <FieldArray
        fieldNames={["name", "zip", "count"]}
        multipleFields={true}
        multiFieldNormalizing={[null, null, strToNum]}
        name="zipCodes"
        component={FormFieldArray}
        label="Zip Codes"
      />
    </React.Fragment>
  );
};

export default MarketingReportsForm;
