import request from "utils/request";
import createNotesAPI from "../utils/createNotesAPI";

export const createLocation = (data) =>
  request({ url: `/locations`, data: data, method: "post" });

// export const updateLocation = (data) => request({ url: `/locations`, data: data, method: 'post' })

export const locationsNotesAPI = createNotesAPI(
  (id) => `/locations/${id}/notes`
);
