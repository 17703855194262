import L from "leaflet";
import { MapLayer, withLeaflet } from "react-leaflet";
import "leaflet.vectorgrid";

class ZipCodeLayer extends MapLayer {
  createLeafletElement(props) {
    // Create the VectorGrid layer
    const layer = new L.VectorGrid.Slicer(props.zipCodeData, {
      tolerance: 5,
      extent: 4096,
      buffer: 64,
      indexMaxZoom: 18,
      indexMaxPoints: 100000,
      interactive: true,
      zIndex: 1000,
      rendererFactory: L.svg.tile,
      getFeatureId: (feature) => Number(feature.properties.ZCTA5CE10),
      vectorTileLayerStyles: {
        sliced: {
          color: "#afa2c3",
          weight: 1,
          fill: true,
          fillOpacity: 0,
        },
      },
    }).on("click", props.onClick);

    return layer;
  }
}

export default withLeaflet(ZipCodeLayer);
