import { Field } from "redux-form";
import FormField from "../FormField";
import { requiredDropdown } from "utils/validators";

const RouteAssignPickup = ({ pickup, pickupRoutes, routeId }) => {
  const assignStopOptions = () => {
    const pickupStops = pickupRoutes.routesMap[routeId]
      ? [...pickupRoutes.routesMap[routeId].pickups]
      : [];

    if ((pickup && !pickup.routeId) || (pickup && pickup.routeId !== routeId)) {
      pickupStops.push(1);
    }

    if (pickupStops.length === 0) {
      pickupStops.push(1);
    }

    return pickupStops.map((pickupId, index) => ({
      id: index + 1,
      label: `Stop ${index + 1}`,
    }));
  };

  return (
    <div>
      <div className={"p-3"}>
        <h3>{pickup && pickup.user ? pickup.user.fullName : null}</h3>
        <h4 className="m-0">
          {pickup && pickup.location ? pickup.location.mapAddress : null}
        </h4>
      </div>
      <div>
        <Field
          component={FormField}
          type="select"
          label="Route"
          name="routeId"
          options={pickupRoutes.pickupRoutes.map((route) => ({
            id: route.id,
            label: `Route ${route.position + 1}`,
          }))}
          validate={requiredDropdown}
        />

        <Field
          component={FormField}
          type="select"
          label="Stop"
          name="stopNumber"
          options={assignStopOptions()}
          validate={requiredDropdown}
        />
      </div>
    </div>
  );
};

export default RouteAssignPickup;
