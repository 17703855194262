import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import {
  requestPickupBlocks,
  clearPickupBlocks,
} from "actions/pickup-blocks.actions";

import {
  createPickup,
  requestPickups,
  updatePickup,
  clearPickups,
} from "./pickups.actions";

import { requestCustomerActions } from "../donor-timeline/customer-timeline.actions";

import DonorPickups from "./donor-pickups.component";

function mapStateToProps(state) {
  // getLocationsIndedx() => {
  // 	let obj = {}
  // 	state.locations.forEach((location, index) => {
  // 		obj[location.id] = index;
  // 	})
  // 	return obj
  // },

  return {
    pickupForm: getFormValues("NewPickup")(state),
    pickups: [...state.pickups.pickups],
    isFetching: state.pickups.isFetching,
    isLoaded: state.pickups.loaded,
    pickupBlocks: {
      ...state.pickupBlocks,
    },
    regions: {
      ...state.regions,
    },
    locations: {
      ...state.locations,
    },
    // locationsIndex:
  };
}

export default connect(mapStateToProps, {
  updatePickup,
  createPickup,
  requestPickups,
  requestPickupBlocks,
  requestCustomerActions,
  clearPickupBlocks,
  clearPickups,
})(DonorPickups);
