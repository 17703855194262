import { connect } from "react-redux";
// import { getFormValues } from 'redux-form';
import {
  requestRoutes,
  receiveRoutes,
  updatePickup,
  updateRoute,
  updateRouteCompleted,
  updateRouteDriver,
  setDate,
  uploadCSV,
  rehydrateRoutes,
  deleteRoute,
  updatePickupOrder,
  updateRouteFurniture,
  sendReminderNotifications,
} from "actions/routes.actions";
import { getRegion } from "actions/regions.actions";

import { requestLocations } from "actions/locations.actions";

import UnassignedPickupList from "./route-unassigned-pickups-list.component";

const mapStateToProps = (state) => {
  return {
    routes: [...state.pickupRoutes.pickupRoutes],

    unassignedPickups: [...state.pickupRoutes.unnassignedPickups],
  };
};

const mapDispatchToProps = {
  requestRoutes,

  updatePickup,

  updateRoute,

  updateRouteCompleted,

  updateRouteDriver,

  updateRouteFurniture,

  uploadCSV,

  setDate,

  rehydrateRoutes,
  // requestRoutePickupBlocks,

  deleteRoute,

  updatePickupOrder,

  receiveRoutes,

  getRegion,

  sendReminderNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedPickupList);
