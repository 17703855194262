import { GeoJSON } from "leaflet";
import { MapLayer, withLeaflet } from "react-leaflet";

class ZipCodeZoneLayer extends MapLayer {
  createLeafletElement(props) {
    this.color = props.color;

    // Create the GeoJSON layer
    return new GeoJSON(
      {
        ...props.geoJSON,
        features: this.filterZips(props.geoJSON.features, props.activeZips),
      },
      {
        style: (feature) => ({
          color: this.color,
          fillColor: this.color,
          fillOpacity:
            props.highlightedZipCode === feature.properties.ZCTA5CE10
              ? 0.5
              : 0.2,
        }),
      }
    ).on("click", props.onClick);
  }

  // Used to filter out the zip codes in the GeoJSON from displaying in this zone
  filterZips(features, activeZips) {
    if (!activeZips) return features;

    return features.filter(
      (feature) => !activeZips.includes(feature.properties.ZCTA5CE10)
    );
  }

  updateLeafletElement(fromProps, toProps) {
    // Handle zip codes being added and removed from the zip code zone
    if (
      (fromProps.geoJSON !== toProps.geoJSON && toProps.geoJSON) ||
      !fromProps.activeZips ||
      fromProps.activeZips.length !== toProps.activeZips.length ||
      !fromProps.activeZips.every(
        (value, idx) => toProps.activeZips[idx] === value
      )
    ) {
      const fromFeatures = fromProps.geoJSON
        ? this.filterZips(fromProps.geoJSON.features, fromProps.activeZips)
        : [];
      const toFeatures =
        this.filterZips(toProps.geoJSON.features, toProps.activeZips) || [];

      const addedFeatures = toFeatures.filter(
        (el) => !fromFeatures.includes(el)
      );
      const removedFeatures = fromFeatures.filter(
        (el) => !toFeatures.includes(el)
      );

      // Add any added features to the map
      for (const feature of addedFeatures) {
        this.leafletElement.addData(feature);
      }

      // Remove any removed features from the map
      if (removedFeatures.length !== 0) {
        const removedZipCodes = removedFeatures.map(
          (feature) => feature.properties.ZCTA5CE10
        );
        this.leafletElement.eachLayer((layer) => {
          if (removedZipCodes.includes(layer.feature.properties.ZCTA5CE10))
            layer.remove();
        });
      }
    }

    // Handle zone color change
    if (fromProps.color !== toProps.color) {
      this.color = toProps.color;
      this.leafletElement.setStyle({
        color: toProps.color,
        fillColor: toProps.color,
      });
    }

    // Handle highlighted zip code change
    if (fromProps.highlightedZipCode !== toProps.highlightedZipCode) {
      this.leafletElement.eachLayer((layer) => {
        switch (layer.feature.properties.ZCTA5CE10) {
          // Highlight the newly selected zip code
          case toProps.highlightedZipCode:
            layer.setStyle({ fillOpacity: 0.5 });
            break;

          // Unhighlight the old zip code
          case fromProps.highlightedZipCode:
            layer.setStyle({ fillOpacity: 0.2 });
            break;
        }
      });
    }
  }
}

export default withLeaflet(ZipCodeZoneLayer);
