import { useCallback, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import NotesDisplay from "./notes-display.component";

const Notes = ({ entityId, notesAPI, setBadge }) => {
  const [notes, setNotes] = useState([]);
  const [noteCategories, setNoteCategories] = useState([]);
  const [note, setNote] = useState("");
  const [category, setCategory] = useState(null);

  useEffect(() => {
    notesAPI
      .getCategories()
      .then((categories) => (setNoteCategories(categories), categories))
      .then(([category]) => (category ? setCategory(category.id) : null));
  }, [notesAPI, setNoteCategories]);

  useEffect(() => {
    if (setBadge) setBadge(notes.length);
  }, [notes.length]);

  const updateNotes = useCallback(() => {
    notesAPI.get(entityId).then(setNotes);
  }, [entityId, notesAPI]);

  useEffect(updateNotes, [updateNotes]);

  const createNote = useCallback(() => {
    notesAPI.create(entityId, note, category).then(() => {
      setNote("");
      updateNotes();
    });
  }, [entityId, note, notesAPI, updateNotes, category]);

  return (
    <>
      <div className={"mb-3"}>
        <NotesDisplay
          notes={notes}
          updateNote={async (note) => {
            await notesAPI.update(entityId, note);
            await updateNotes();
          }}
          deleteNote={async (note) => {
            await notesAPI.delete(entityId, note);
            await updateNotes();
          }}
        />
      </div>
      <Form>
        <Form.Group>
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(parseInt(e.target.value))}
          >
            {noteCategories.map((category) => (
              <option value={category.id}>{category.label}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button onClick={createNote}>Add Note</Button>
      </Form>
    </>
  );
};

export default Notes;
