import { connect } from "react-redux";
import UsersComponent from "./users.component";

const mapStateToProps = (state) => ({
  regions: state.regions.regions,
  allRegions: [...state.regions.regions, ...state.regions.disabledRegions],
  activeRegions: state.regions.regions.filter((region) => !region.disabled),
});

// import injectReducer from 'utils/injectReducer'
// const withReducer = injectReducer({ key: 'user_managment', reducer: usersReducer});
export default connect(mapStateToProps)(UsersComponent);
