import { connect } from "react-redux";
import userActions from "actions/_user.actions";
import CreateUpdateRegion from "./create-update-region.component";

const mapStateToProps = (state) => ({
  users: state.user_db.byId,
  region: state.regions.activeRegion?.region,
});

const mapDispatchToProps = {
  updateUser: userActions.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateRegion);
