import request from "utils/request";
import createNotesAPI from "../utils/createNotesAPI";

export const loginUserv1 = (data) =>
  request({
    url: `/users/authenticate`,
    method: "post",
    data: data,
  });

export const getPhoneAvailable = (query) =>
  request({ url: `/v2/public/users/available`, params: query });

export const getUsers = (query) => request({ url: `/users`, params: query });

export const getUser = (id) => request({ url: `/users/${id}` });

export const getUsersV2 = (query) =>
  request({ url: `/v2/users`, params: query });

export const mergeUsers = (ids) =>
  request({ url: `/v2/users/merge`, method: "post", data: { ids: ids } });

export const createUser = (values) =>
  request({ url: `/v2/users`, method: "post", data: values });

export const updateUser = (id, values) =>
  request({ url: `/users/${id}`, method: "put", data: values });

export const registerUser = (values) =>
  request({ url: `/v2/public/users/register`, method: "post", data: values });

//addDonorV2 isn't used
export const addDonorV2 = (values) =>
  request({ url: `/v2/public/users/add_donor`, method: "post", data: values });

export const addDonor = (data) =>
  request({ url: `/users/add_donor`, method: "post", data });

export const loginUser = (values) =>
  request({
    url: `/v2/public/users/authenticate`,
    method: "post",
    data: values,
  });

export const loginUserCode = (values) =>
  request({
    url: `/v2/public/users/authenticate_code`,
    method: "post",
    data: values,
  });

export const authToken = (token) =>
  request({ url: `/v2/users/verify_token`, method: "post", data: token });

export const forgotPassword = (values) =>
  request({
    url: `/v2/public/users/forgot_password`,
    method: "post",
    data: values,
  });

export const getVerificationCode = ({ phoneNumber }) => {
  return request({
    url: `/v2/public/users/verification_code`,
    method: "post",
    data: {
      phoneNumber,
    },
  });
};

export const changePassword = (id, password) => {
  return request({
    url: `/v2/users/${id}/changePassword`,
    method: "put",
    data: { password },
  });
};

export const changeProfileImage = (id, image) => {
  const data = new FormData();
  data.append("image", image);

  return request({ url: `/users/${id}/image`, method: "post", data });
};

export const resetProfileImage = (id) =>
  request({ url: `/users/${id}/image`, method: "delete" });

export const getNotifications = (id) =>
  request({ url: `/users/${id}/notifications` });

export const getCustomerActions = (userId) =>
  request({ url: `/users/${userId}/actions` });

export const usersNotesAPI = createNotesAPI((id) => `/users/${id}/notes`);
