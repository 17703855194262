import { connect } from "react-redux";
import RegionDropdown from "./region-drop-down.component";
import { getRegion } from "actions/regions.actions";

const mapStateToProps = (state, ownProps) => ({
  regions: state.regions,
  label: ownProps.label,
});

const mapDispatchToProps = {
  getRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionDropdown);
