import React, { Component, useState, useEffect, useContext } from "react";
import ConfirmModal from "components/confirm-modal";
import CreateRouteForm from "forms/create-route";
import UnassignedPickups from "./route-unassigned-pickups-list";
import RouteMap from "./route-map";
import SingleRoute from "./route-single";
import { Row, Col, Form } from "react-bootstrap";
import Box from "components/box";
import { startOf } from "utils/dates";
import querystring from "query-string";
import history from "../../../history";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import RouteImportExport from "forms/route-import-export";
import { REACT_APP_MP_OLD_ROUTING } from "config";
import RouteAssignPickupButton from "./route-assign-pickup-button";
import Loader from "components/loader";
import { routeGetDriverPosition } from "api/routes";
import Tabs from "components/tabs";
import PageLayout from "../../../layouts/pages/page-default";
import { PickupTypesContext } from "contexts/pickupTypes";

const PickupRoutesComponent = ({
  requestUsers,
  activeRegion,
  getRegion,
  pickupRoutes,
  setDate,
  bulkDeleteRoutes,
  rehydrateRoutes,
  uploadCSV,
  auth,
  pickups,
  pickupsMin,
  sendReminderNotifications,
  requestRoutePickupsMinimal,
}) => {
  const { pickupTypes, getPickupTypes } = useContext(PickupTypesContext);
  const [routes, setRoutes] = useState({});
  const [driverPositions, setDriverPositions] = useState({});
  const [assigning, setAssigning] = useState(null);
  const [routeFilter, setRouteFilter] = useState(-1);
  const [mapRouteFilter, setMapRouteFilter] = useState({});
  const [showTab, setShowTab] = useState(0);
  const [showUnassignedOnMap, setShowUnassignedOnMap] = useState(true);
  const [activeRegionId, setActiveRegionId] = useState(activeRegion.region?.id);

  const pickupRoutesFirstId = pickupRoutes.pickupRoutes[0]
    ? pickupRoutes.pickupRoutes[0].id
    : null;

  // Pull drivers in this region
  useEffect(() => {
    getPickupTypes();
    requestUsers({
      type: 3,
      regionId: [activeRegion.region.id, 0],
    });
    let driverInterval;
    if (REACT_APP_MP_OLD_ROUTING !== "true") {
      getDriverPositions();
      driverInterval = setInterval(getDriverPositions, 60 * 1000);
    }
    return function cleanup() {
      clearInterval(driverInterval);
    };
  }, []);

  // Creates 56 color array
  // TODO: Remove this in favor of setting colors on the route in the API
  // this.routeColors = [
  //   "#ff6262",
  //   "#ff62d6",
  //   "#6c62ff",
  //   "#627dff",
  //   "#62ff75",
  //   "#fdff62",
  //   "#ffaa62",
  // ];
  // for (let i = 0; i < 3; i++) {
  //   this.routeColors = this.routeColors.concat(this.routeColors);
  // }
  // }
  useEffect(() => {
    if (activeRegionId && activeRegion.region?.id !== activeRegionId) {
      getRegion({ regionId: activeRegionId });
      setDate(pickupRoutes.pickupDate, activeRegion.region?.id, true);
      setActiveRegionId(activeRegion.region?.id);
    }
  }, [activeRegionId, activeRegion.region?.id]);

  useEffect(() => {
    if (pickupRoutesFirstId) {
      if (REACT_APP_MP_OLD_ROUTING !== "true") {
        getDriverPositions(pickupRoutes);
      }
      setMapRouteFilter({});
    }
  }, [pickupRoutesFirstId]);

  const setTab = (tab) => {
    setShowTab(tab);
  };

  const getDriverPositions = async () => {
    const { pickupRoutes: pickupRoutesValues } = pickupRoutes;

    const positions = {};

    // Filter down to a single route
    const filteredPickupRoutes =
      routeFilter === -1 // -1 means no filter applied
        ? pickupRoutesValues
        : pickupRoutesValues.filter((route) => route.id === routeFilter);

    // Apply a color to each route, eventually we store this in the database.
    // filteredPickupRoutes.map((route, index)=>{
    //   route.color = this.routeColors[index]
    // })

    await Promise.all(
      filteredPickupRoutes.map(async (route) => {
        const driverPosition = await routeGetDriverPosition(route.id);
        if (driverPosition) positions[route.id] = driverPosition;
      })
    );
    setDriverPositions(positions);
  };

  const deleteStops = () => {
    bulkDeleteRoutes({
      regionId: activeRegion.region.id,
      routeDate: pickupRoutes.pickupDate,
    }).then(() => {
      return rehydrateRoutes(pickupRoutes.pickupDate, activeRegion.region.id);
    });
  };

  const renderRouteForm = () => {
    const { pickupDate, unnassignedPickups } = pickupRoutes || {};

    return (
      <>
        {REACT_APP_MP_OLD_ROUTING === "true" && (
          <Tabs active={showTab} onChange={setShowTab}>
            <Tabs.Tab value={0}>Automatic</Tabs.Tab>
            <Tabs.Tab value={1}>CSV Upload</Tabs.Tab>
          </Tabs>
        )}
        <Box>
          <h3>
            Generate Route{" "}
            <small>
              ({unnassignedPickups ? unnassignedPickups.length : 0} Pickups )
            </small>
          </h3>

          <hr />

          {unnassignedPickups && unnassignedPickups.length > 0 ? (
            <>
              {showTab === 0 && (
                <CreateRouteForm
                  date={startOf(pickupDate, "day")}
                  region={activeRegion}
                />
              )}

              {REACT_APP_MP_OLD_ROUTING === "true" && showTab === 1 && (
                <RouteImportExport
                  date={pickupDate}
                  onFileSelect={uploadCSV}
                  onSuccess={() => {
                    rehydrateRoutes(pickupDate, activeRegion.region.id);
                  }}
                />
              )}
            </>
          ) : (
            <div>
              There are no pickups scheduled for today, you'll need to have some
              scheduled donations to generate a route.
            </div>
          )}
        </Box>
      </>
    );
  };

  const {
    isFetching,
    pickupsMinFetching,
    pickupsFetching,
    routesLoaded,
    pickupsLoaded,
    unnassignedPickups,
    pickupRoutes: pickupRoutesValues,
  } = pickupRoutes || {};
  const hasUnassignedPickups = unnassignedPickups.length > 0 ? true : false;
  const filteredPickupRoutes =
    routeFilter === -1
      ? pickupRoutesValues
      : pickupRoutesValues.filter((route) => route.id === routeFilter);

  const ifRoutesMoreThenOne = filteredPickupRoutes.length > 1;
  const showUnassignedPickups =
    auth.credentials.userTypeId <= 2 && hasUnassignedPickups && auth;
  const showClearRoutes =
    pickupRoutesValues.length && auth.credentials.userTypeId === 1
      ? true
      : false;
  return (
    <section className="content">
      <Row>
        <Col sm={ifRoutesMoreThenOne ? 8 : 12}>
          <Box>
            <DateDayNav
              date={startOf(pickupRoutes.pickupDate, "day")}
              onChange={(date) => {
                setRouteFilter(-1);
                setDate(date, activeRegion.region.id, true);
              }}
              containerClassName="routes-date-picker-container"
            />
          </Box>
        </Col>
        {ifRoutesMoreThenOne && (
          <Col sm={4}>
            <Box>
              <Form.Control
                as="select"
                value={routeFilter}
                onChange={(e) => {
                  setRouteFilter(parseInt(e.target.value));
                }}
              >
                <option value={-1}>All</option>
                {filteredPickupRoutes.map((route) => (
                  <option key={route.id} value={route.id}>
                    Route {route.position + 1}
                  </option>
                ))}
              </Form.Control>
            </Box>
          </Col>
        )}
        <Col sm={12}>
          <div>
            {isFetching || pickupsMinFetching ? (
              <Loader />
            ) : (
              <div>
                <div>
                  <RouteMap
                    showUnassignedOnMap={
                      showUnassignedOnMap && pickupRoutesValues.length
                    }
                    pickupRoutes={filteredPickupRoutes.filter(
                      (route) => !mapRouteFilter[route.id]
                    )}
                    driverPositions={Object.fromEntries(
                      Object.entries(driverPositions).filter(
                        ([routeId]) => !mapRouteFilter[routeId]
                      )
                    )}
                    onMarkerClick={(pickup) => {
                      setAssigning(pickup);
                    }}
                  />
                  {assigning && (
                    <RouteAssignPickupButton
                      modalOnly={true}
                      showModal={assigning}
                      closeModal={() => setAssigning(null)}
                      pickup={assigning}
                    />
                  )}
                </div>

                {!pickupRoutesValues.length ? (
                  <React.Fragment>{renderRouteForm()}</React.Fragment>
                ) : (
                  filteredPickupRoutes.map((pickupRoute, routeIndex) => (
                    <SingleRoute
                      pickupDate={pickupRoutes.pickupDate}
                      routeId={pickupRoute.id}
                      pickupTypes={pickupTypes}
                      showOnMap={!mapRouteFilter[pickupRoute.id]}
                      setShowOnMap={(show) => {
                        setMapRouteFilter({
                          ...mapRouteFilter,
                          [pickupRoute.id]: !show,
                        });
                      }}
                      pickups={pickups.filter(
                        (pickup) => pickup.routeId === pickupRoute.id
                      )}
                      pickupRoute={pickupRoute}
                      pickupsMin={pickupsMin.filter(
                        (pickup) => pickup.routeId === pickupRoute.id
                      )}
                      routeIndex={routeIndex}
                    />
                  ))
                )}
                {showUnassignedPickups ? (
                  <UnassignedPickups
                    setShowUnassignedOnMap={setShowUnassignedOnMap}
                    showUnassignedOnMap={showUnassignedOnMap}
                  />
                ) : null}

                {showClearRoutes ? (
                  <ConfirmModal
                    title={`Confirm Send Notifications`}
                    confirmText={`Are you sure you want to send reminder notifications?`}
                    confirmSubText={``}
                    onConfirm={() =>
                      sendReminderNotifications(
                        activeRegion.region.id,
                        pickupRoutes.pickupDate
                      )
                    }
                    buttonText="Send Reminder Notifications"
                  />
                ) : null}
                {"  "}
                {showClearRoutes ? (
                  <ConfirmModal
                    title={`Confirm Clear Routes`}
                    confirmText={`Are you sure you want to clear today's routes?`}
                    confirmSubText={``}
                    onConfirm={deleteStops}
                    buttonText="Clear Routes"
                  />
                ) : null}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default PickupRoutesComponent;
