import React, { useState } from "react";
import { Field } from "redux-form";
import FormField from "forms/FormField";
import { FormGroup, Row, Col, FormLabel, FormControl } from "react-bootstrap";
import TimePicker from "forms/time-picker";
import PlacesAutocompleteField from "forms/PlacesAutocompleteField";
import { required } from "../../utils/validators";

const RenderCombinePickupsForm = ({ pickupTypes }) => {
  const [selected, setSelected] = useState(0);
  const pickupType = pickupTypes[selected];

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>Select Pickup To Combine</FormLabel>
            <Field
              name="selected_combine"
              type="select"
              onChange={(e) => {
                const idx = pickupTypes.findIndex((item) => {
                  return item.id === Number.parseInt(e.target?.value);
                });
                idx >= 0 && setSelected(idx);
              }}
              component={FormField}
              options={pickupTypes.map((type) => ({
                label: type.label,
                id: type.id,
              }))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>{pickupType.label}: Stop Time</FormLabel>
            <Field
              name={`pickup_types.id${pickupType.id}.stop_time`}
              component={FormField}
              className="form-control"
              type="number"
              validate={required}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>{pickupType.label}: Number of Trucks</FormLabel>
            <Field
              name={`pickup_types.id${pickupType.id}.vehicle_count`}
              component={FormField}
              className="form-control"
              type="number"
              validate={required}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

let CreateRouteForm = ({
  handleSelectStart,
  handleSelectEnd,
  separateFurniture,
  pickupTypes,
}) => {
  const [combinePickups, setCombinePickups] = useState(false);
  return (
    <React.Fragment>
      <Row>
        <Col sm={"12"} md={6} lg={"6"}>
          <FormGroup>
            <FormLabel>Starting Location</FormLabel>
            <Field
              name={`start_location`}
              component={PlacesAutocompleteField}
              onPlaceSelected={handleSelectStart}
              placeholder={"Starting Address"}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <FormGroup>
            <FormLabel>Ending Location</FormLabel>
            <Field
              name={`end_location`}
              component={PlacesAutocompleteField}
              onPlaceSelected={handleSelectEnd}
              placeholder={"Ending Address"}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>Start Time</FormLabel>
            <Field
              name={`start_time`}
              component={TimePicker}
              step={15} // 15 min increments in dropdown
              // max={8am-8pm} TODO: Add Range of times that are actually used
              // label="Start"
              date={false}
              calendar={false}
              placeholder={"Start Time"}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>End Time</FormLabel>
            <Field
              name={`end_time`}
              component={TimePicker}
              step={15} // 15 min increments in dropdown
              // max={8am-8pm} TODO: Add Range of times that are actually used
              // label="Start"
              date={false}
              calendar={false}
              placeholder={"End Time"}
            />
          </FormGroup>
        </Col>
      </Row>
      {!combinePickups ? (
        pickupTypes.map((pickupType) => (
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <FormLabel>{pickupType.label}: Stop Time</FormLabel>
                <Field
                  name={`pickup_types.id${pickupType.id}.stop_time`}
                  component={FormField}
                  className="form-control"
                  type="number"
                  validate={required}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <FormLabel>{pickupType.label}: Number of Trucks</FormLabel>
                <Field
                  name={`pickup_types.id${pickupType.id}.vehicle_count`}
                  component={FormField}
                  className="form-control"
                  type="number"
                  validate={required}
                />
              </FormGroup>
            </Col>
          </Row>
        ))
      ) : (
        <RenderCombinePickupsForm pickupTypes={pickupTypes} />
      )}

      <Field
        name="start_coordinates"
        component="input"
        className="form-control"
        type="hidden"
      />
      <FormGroup>
        <Field
          name="end_coordinates"
          component="input"
          className="form-control"
          type="hidden"
        />
      </FormGroup>
      {pickupTypes.length > 1 && (
        <FormGroup>
          <FormLabel>Combine Pickups</FormLabel>
          <Field
            value={combinePickups}
            onChange={(e) => {
              setCombinePickups(e.target.checked);
            }}
            name="combine_pickups"
            component={FormField}
            type="checkbox"
          />
        </FormGroup>
      )}
      {process.env.REACT_APP_MP_AUTOMATIC_ROUTING === "true" && (
        <FormGroup>
          <FormLabel>Manual Routing</FormLabel>
          <Field
            name="routing"
            component={FormField}
            type="checkbox"
            invert={true}
          />
        </FormGroup>
      )}
    </React.Fragment>
  );
};

export default CreateRouteForm;
