import { connect } from "react-redux";
import pickupStatusesActions from "actions/pickup-statuses.actions";
import pickupSourcesActions from "actions/pickup-sources.actions";
import { requestRegions } from "actions/regions.actions";
import { loadUser } from "actions/dashboard.actions";

import WithDashboardData from "./with-dashboard-data.component";

const mapStateToProps = (state) => ({
  user: {
    data: { ...(state.auth.credentials || {}) },
    isLoaded: state.auth.loaded,
    isLoading: state.auth.isFetching,
  },
});

const mapDispatchToProps = {
  requestPickupStatuses: pickupStatusesActions.get,
  requestPickupSources: pickupSourcesActions.get,
  requestRegions,
  loadUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithDashboardData);
