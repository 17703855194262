import { Field, Form } from "redux-form";
import FormField from "forms/FormField";
import DatePicker from "forms/date-picker";
import { required } from "utils/validators";
import { Button, FormGroup } from "react-bootstrap";

import React from "react";

export default (props) => (
  <React.Fragment>
    <Field
      className="report-date-picker"
      component={DatePicker}
      label="Start Date"
      name="startDate"
      // handleChange={this.startDateChange}
      required={true}
      type="text"
      validate={required}
      // value={this.state.startDate}
    />
    <Field
      className="report-date-picker"
      component={DatePicker}
      label="End Date"
      name="endDate"
      // handleChange={this.endDateChange}
      required={true}
      type="text"
      validate={required}
      // value={this.state.endDate}
    />
  </React.Fragment>
);
