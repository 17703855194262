import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import {
  updateLocation,
  createLocation,
  requestLocations,
  clearLocations,
} from "actions/locations.actions";

import { requestCustomerActions } from "../donor-timeline/customer-timeline.actions";

import DonorLocations from "./donor-locations.component";

function mapStateToProps(state, props) {
  return {
    locationForm: getFormValues("NewLocation")(state),
    locations: state.locations.locations,
    isFetching: state.locations.isFetching,
    isLoaded: state.locations.loaded,
  };
}

export default connect(mapStateToProps, {
  updateLocation,
  createLocation,
  requestLocations,
  clearLocations,
  requestCustomerActions,
})(DonorLocations);
