import { useCallback, useState } from "react";

/**
 * A resource is a value in state that has an associated loading and
 * error state. A wrapper function is provided to link the loading
 * and error state with functions.
 *
 * @param initialValue
 * @param externalResource
 * @param externalSetResource
 * @returns {unknown[]}
 */
const useResource = (initialValue, externalResource, externalSetResource) => {
  let [resource, setResource] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  if (externalResource && externalSetResource) {
    resource = externalResource;
    setResource = externalSetResource;
  }

  const clearError = useCallback(() => setError(false), []);
  const wrapper = useCallback((f) => {
    return (...params) => {
      setLoading(true);

      let ret;
      try {
        ret = f(...params);
      } catch (e) {
        setError(e);
      }

      if (ret && typeof ret.then === "function") {
        ret
          .then(() => {
            setLoading(false);
            setLoaded(true);
          })
          .catch((e) => setError(e));
      } else {
        setLoading(false);
      }
      return ret;
    };
  }, []);

  return [resource, setResource, loading, error, clearError, wrapper, loaded];
};

export default useResource;
