import React, { useContext, useEffect, useState } from "react";
import { Button, Badge } from "react-bootstrap";

import { Table, RowActions, checkComponentOriginProp } from "components/table";
import Modal from "components/modal";
import Tabs from "components/tabs";
import ConfirmModal from "components/confirm-modal";
import PageLayout from "layouts/pages/page-default";
import { LeadSourcesContext, LeadSourcesProvider } from "contexts/lead-sources";
import CreateUpdateLeadSource from "forms/create-update-lead-source";
import { numberWithCommas } from "utils/helpers";

const LeadSourcesTable = () => {
  const {
    leadSources,
    leadSourcesLoading,
    getLeadSources,
    createLeadSource,
    updateLeadSource,
  } = useContext(LeadSourcesContext);

  const [showEnabled, setShowEnabled] = useState(true);

  useEffect(
    () => getLeadSources({ includePickupCount: true, disabled: !showEnabled }),
    [showEnabled]
  );

  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [archive, setArchive] = useState(false);
  const [tableData, setTableData] = useState([]);

  const hideModal = () => {
    setCreate(false);
    setEdit(false);
  };

  useEffect(() => {
    setTableData(
      leadSources.filter(
        (source) =>
          source.disabled !== showEnabled && source.pickupCount !== undefined
      )
    );
  }, [leadSources]);

  return (
    <PageLayout
      title="Lead Sources"
      actionComponents={[
        () => <Button onClick={() => setCreate(true)}>Add New</Button>,
      ]}
    >
      <Tabs active={showEnabled} onChange={setShowEnabled}>
        <Tabs.Tab value={true}>Enabled</Tabs.Tab>
        <Tabs.Tab value={false}>Disabled</Tabs.Tab>
      </Tabs>
      <Table
        columns={[
          {
            Header: "Name",
            accessor: "label",
          },
          {
            Header: "Total Donations",
            accessor: "pickupCount",
            Cell: (props) =>
              checkComponentOriginProp(props, (original) => (
                <Badge variant={"secondary"}>
                  {numberWithCommas(original.pickupCount)}
                </Badge>
              )),
          },
        ]}
        actions={[
          {
            Header: "Actions",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.id}
                  actions={[
                    {
                      text: "Edit",
                      icon: "edit",
                      onClick: () =>
                        setEdit({ id: original.id, label: original.label }),
                    },
                    {
                      text: "Archive",
                      icon: "archive",
                      onClick: () => setArchive(original),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={tableData}
        pageSize={tableData.length}
        loading={leadSourcesLoading}
        hidePagination
      />
      <Modal
        show={!!(create || edit)}
        onHide={hideModal}
        title={`${create ? "Create" : "Edit"} Lead Source`}
      >
        <CreateUpdateLeadSource
          initialValues={edit || {}}
          onSubmit={
            create
              ? async (source) => {
                  await createLeadSource(source);
                  await getLeadSources({
                    includePickupCount: true,
                    disabled: !showEnabled,
                  });
                }
              : updateLeadSource
          }
          onSubmitSuccess={hideModal}
          onCancel={hideModal}
        />
      </Modal>
      <ConfirmModal
        title="Archive Lead Source"
        confirmText="Archive"
        confirmSubText="Are you sure you'd like to archive this lead source?"
        onConfirm={() =>
          updateLeadSource({ ...archive, disabled: !archive.disabled })
        }
        close={() => setArchive(false)}
        show={archive}
        hideButton
      />
    </PageLayout>
  );
};

const LeadSources = () => {
  return (
    <LeadSourcesProvider>
      <LeadSourcesTable />
    </LeadSourcesProvider>
  );
};

export default LeadSources;
