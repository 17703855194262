import React, { useContext, useEffect } from "react";
import { reduxForm } from "redux-form";
import { Field, FieldArray } from "redux-form";
import FormField from "forms/FormField";
import FormFieldArray from "forms/FormFieldArray";
import { required, requiredDropdown } from "../../utils/validators";
import { PickupTypesContext } from "contexts/pickupTypes";

const CreateUpdateZone = (props) => {
  const { pickupTypes, getPickupTypes } = useContext(PickupTypesContext);

  useEffect(() => {
    getPickupTypes();
  }, []);

  return (
    <React.Fragment>
      <Field
        label="Name"
        name="name"
        type="text"
        component={FormField}
        required={true}
        validate={required}
      />
      <Field
        label={"Default Stops"}
        name="defaultBlockStops"
        type="number"
        component={FormField}
        required={true}
        validate={required}
      />
      <FieldArray
        name="zipCodes"
        fieldKey="zip"
        component={FormFieldArray}
        label="Zip Code"
        placeholder="90210"
        addButtonLabel="+ Add Another"
      />
      <Field
        label="Pickup Type"
        name="pickupTypeId"
        component={FormField}
        type="select"
        options={pickupTypes}
        required={true}
        validate={requiredDropdown}
      />
      {props.initialValues.id && (
        <Field
          label="Region"
          name="regionId"
          component={FormField}
          type="select"
          options={props.regions}
        />
      )}
    </React.Fragment>
  );
};

export default CreateUpdateZone;
