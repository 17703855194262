import { createContext } from "react";

export default createContext({
  locationTypes: [],
  locationTypesLoading: false,
  locationTypesError: false,
  locationTypesLoaded: false,
  getLocationTypes: () => {},
  createLocationType: () => {},
  updateLocationType: () => {},
  clearLocationTypesError: () => {},
});
