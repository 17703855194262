import React, { useCallback, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormLabel } from "react-bootstrap";
import PageLayout from "layouts/pages/page-default";
import DateTimePicker from "react-widgets/DatePicker";
import { lastWeek, formatQueryDate, getStartOrEndISOS } from "utils/dates";
import colors from "utils/generateColors";
import { PickupCount, NewUsersCount } from "pages/analytics/analytics";
import { PickupTypesContext } from "contexts/pickupTypes";

const Analytics = (props) => {
  Analytics.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    newUserAnalytics: PropTypes.shape({
      data: PropTypes.array,
    }).isRequired,
    pickupAnalytics: PropTypes.shape({
      data: PropTypes.object,
    }).isRequired,
    requestNewUserAnalytics: PropTypes.func.isRequired,
    requestPickupAnalytics: PropTypes.func.isRequired,
  };

  const [startDate, setStartDate] = useState(lastWeek);
  const [endDate, setEndDate] = useState(new Date());
  const [zipCodeFilter, setZipCodeFilter] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const [pickupTypeFilter, setPickupTypeFilter] = useState("");
  const [pickupSourceFilter, setPickupSourceFilter] = useState("");
  const [pickupLabels, setPickupLabels] = useState([]);
  const [pickupsDataset, setPickupsDataset] = useState([]);
  const [newUsersLabels, setNewUsersLabels] = useState([]);
  const [newUsersDataset, setNewUsersDataset] = useState([]);
  const [pickupsLoading, setPickupsLoading] = useState(true);
  const [newUsersLoading, setNewUsersLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const { pickupTypes, getPickupTypes } = useContext(PickupTypesContext);
  const {
    regions,
    isFetching,
    newUserAnalytics,
    pickupAnalytics,
    requestNewUserAnalytics,
    requestPickupAnalytics,
    requestPickupSources,
    pickupSources,
  } = props;

  useEffect(() => {
    fetchData();
    requestPickupSources();
    getPickupTypes();
  }, []);

  useEffect(() => {
    handlePickupLabels().then(createPickupsDataset());
  }, [pickupAnalytics]);

  useEffect(() => {
    handleNewUsersLabels().then(createNewUsersDataset());
  }, [newUserAnalytics]);

  const fetchPickups = useCallback(async () => {
    setPickupsLoading(true);
    await requestPickupAnalytics({
      startDate: getStartOrEndISOS(startDate),
      endDate: getStartOrEndISOS(endDate, false),
      zipCode: zipCodeFilter || undefined,
      pickupType: pickupTypeFilter || undefined,
      regionId: regionFilter ? Number(regionFilter) : undefined,
      pickupSource: pickupSourceFilter ? Number(pickupSourceFilter) : undefined,
    });
    setPickupsLoading(false);
  }, [
    endDate,
    pickupTypeFilter,
    regionFilter,
    requestPickupAnalytics,
    startDate,
    zipCodeFilter,
    pickupSourceFilter,
  ]);

  const fetchNewUsers = useCallback(async () => {
    await setNewUsersLoading(true);
    await requestNewUserAnalytics({
      startDate: getStartOrEndISOS(startDate),
      endDate: getStartOrEndISOS(endDate, false),
    });
    await setNewUsersLoading(false);
  }, [endDate, requestNewUserAnalytics, startDate]);

  const fetchData = useCallback(async () => {
    Promise.all([fetchPickups(), fetchNewUsers()]);
  }, [fetchPickups, fetchNewUsers]);

  useEffect(() => {
    if (!mounted) {
      fetchData();
      requestPickupSources();
      getPickupTypes();
      setMounted(true);
    }
  }, [mounted, fetchData, requestPickupSources]);

  const handlePickupLabels = useCallback(
    () =>
      new Promise((res) => {
        if (!isFetching && pickupAnalytics.data) {
          const {
            data: { results },
          } = pickupAnalytics;
          const pickupLabels = results.Total.pickupCounts.map(
            ({ date }) => date
          );
          return res(setPickupLabels(pickupLabels));
        }
        return res(setPickupLabels([]));
      }),
    [isFetching, pickupAnalytics, setPickupLabels]
  );

  const createPickupsDataset = () => {
    const { data } = pickupAnalytics;
    const datasets = [];

    const pickupsLoaded = pickupAnalytics && pickupAnalytics.data;

    if (pickupsLoaded) {
      const { results } = data;
      Object.keys(results).forEach((label, idx) => {
        const { pickupCounts, totalCount } = results[label];

        const counts = pickupCounts.map(({ count }) => count);
        const labelLegend = `${label} (${totalCount})`;

        datasets.push({
          backgroundColor: colors[idx],
          borderColor: colors[idx],
          data: counts,
          fill: false,
          label: labelLegend,
        });
      });
    }

    setPickupsDataset(datasets);
  };

  const handleNewUsersLabels = () =>
    new Promise((res) => {
      if (!isFetching && newUserAnalytics.data) {
        const {
          data: { results },
        } = newUserAnalytics;
        const newUsersLabels = results.newUserCounts.map(({ date }) => date);
        return res(setNewUsersLabels(newUsersLabels));
      }
      return res(setNewUsersLabels([]));
    });

  const createNewUsersDataset = () => {
    const { data } = newUserAnalytics;
    const dataset = [];

    const newUsersLoaded = newUserAnalytics && newUserAnalytics.data;

    if (newUsersLoaded) {
      const {
        results: { newUserCounts, totalCount },
      } = data;

      const newUsersData = [];
      newUserCounts.forEach(({ count }) => {
        newUsersData.push(count);
      });

      dataset.push({
        backgroundColor: colors[colors.length - 1],
        borderColor: colors[colors.length - 1],
        data: newUsersData,
        fill: false,
        label: `New Users (${totalCount})`,
      });
    }

    setNewUsersDataset(dataset);
  };

  const ZipFilter = useCallback(
    ({ setZipCodeFilter, zipCodeFilter }) => (
      <Form.Control
        type="text"
        placeholder="All Zips"
        onChange={(e) => setZipCodeFilter(e.target.value)}
        value={zipCodeFilter}
      />
    ),
    []
  );

  const RegionFilter = useCallback(
    ({ regions, setRegionFilter }) => (
      <Form.Control
        as="select"
        onChange={(e) => setRegionFilter(e.target.value)}
      >
        <option value="">All Regions</option>
        {regions?.map((region) => (
          <option value={region.id} key={region.id}>
            {region.label}
          </option>
        ))}
      </Form.Control>
    ),
    []
  );

  const PickupTypeFilter = useCallback(
    ({ setPickupTypeFilter }) => (
      <Form.Control
        as="select"
        onChange={(e) => setPickupTypeFilter(e.target.value)}
      >
        <option value="">All Types</option>
        {pickupTypes.map((type) => (
          <option value={type.id}>{type.label}</option>
        ))}
      </Form.Control>
    ),
    [pickupTypes]
  );

  const PickupSourceFilter = useCallback(
    ({ setPickupSourceFilter }) => (
      <Form.Control
        as="select"
        onChange={(e) => setPickupSourceFilter(e.target.value)}
      >
        <option value="">All Sources</option>
        {pickupSources.map((pickupSource) => (
          <option value={pickupSource.id}>{pickupSource.label}</option>
        ))}
      </Form.Control>
    ),
    [pickupSources]
  );

  return (
    <PageLayout title="Analytics">
      <div className={"mb-4"}>
        <Form.Row className={"inline-form-row"}>
          <Col xs={"auto"}>
            <FormLabel>Start Date</FormLabel>
            <DateTimePicker onChange={setStartDate} value={startDate} />
          </Col>
          <Col xs={"auto"}>
            <FormLabel>End Date</FormLabel>
            <DateTimePicker onChange={setEndDate} value={endDate} />
          </Col>
          <Col xs={"auto"}>
            <FormLabel>Region</FormLabel>
            <RegionFilter regions={regions} setRegionFilter={setRegionFilter} />
          </Col>
          <Col xs={"auto"}>
            <FormLabel>Pickup Type</FormLabel>
            <PickupTypeFilter setPickupTypeFilter={setPickupTypeFilter} />
          </Col>
          <Col xs={"auto"}>
            <FormLabel>Pickup Source</FormLabel>
            <PickupSourceFilter setPickupSourceFilter={setPickupSourceFilter} />
          </Col>

          <Col xs={"auto"}>
            Zipcode
            <ZipFilter
              setZipCodeFilter={setZipCodeFilter}
              zipCodeFilter={zipCodeFilter}
            />
          </Col>
          <Col xs={"auto"}>
            <Button onClick={fetchData}>Update</Button>
          </Col>
        </Form.Row>
      </div>
      <PickupCount
        loading={pickupsLoading}
        pickupLabels={pickupLabels}
        pickupsDataset={pickupsDataset}
      />

      <NewUsersCount
        loading={newUsersLoading}
        newUsersDataset={newUsersDataset}
        newUsersLabels={newUsersLabels}
      />
    </PageLayout>
  );
};

export default Analytics;
