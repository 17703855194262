import { Marker, InfoWindow } from "react-google-maps";
import React, { useState } from "react";

let generateIcon = (label, color) => {
  // let label = pickupIndex + 1,
  let stroke = 2;

  return btoa(`<svg width="30" height="40" viewBox="0 0 100 104" preserveAspectRatio="xMaxYMax meet" fill="none" xmlns="http://www.w3.org/2000/svg">
      
      <circle cx="50" cy="50" r="50" fill="${color}" stroke="white" stroke-width="2"/>
        <text font-size="60" font-family="Lato" x="30" y="70" fill="#ffffff">${label}</text>
    </svg>`);
};

let generateTruck = (label, color) => {
  return btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
			<circle cx="320" cy="256" r="256" fill="#fff" stroke="white" stroke-width="2"/>
			<path fill="${color}" stroke="white" stroke-width="5"  d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
			<text font-family="Lato" class="text" font-weight="bold" font-size="250" x="100" y="256" fill="#ffffff">${label}</text>
			</svg>`);
};

// Square black label
let generateLabel = (label, color) => {
  return btoa(`<svg width="800" height="400" viewBox="0 0 800 400" fill="none" preserveAspectRatio="xMaxYMax meet" xmlns="http://www.w3.org/2000/svg">
        <style>.small{font: italic 300px sans-serif}</style>
      <rect width="800" height="400" x="0" y="0" fill="${color}" stroke="black"/>
        <text  font-family="Lato" class="small"  x="100" y="300" fill="#ffffff">${label}</text>
    </svg>`);
};

export const IconMarker = ({
  position,
  label,
  color = "#000",
  onClick,
  InfoComponent,
}) => {
  // Round, pass color and label
  const [isInfoOpen, toggleInfoWindow] = useState(false);

  return (
    <Marker
      onClick={onClick}
      onMouseOver={(hover) => {
        toggleInfoWindow(true);
      }}
      onMouseOut={() => {
        toggleInfoWindow(false);
      }}
      position={position}
      options={{
        icon: {
          anchor: { x: 16, y: 16 },
          url:
            "data:image/svg+xml;charset=UTF-8;base64," +
            generateIcon(label, color),
          scaledSize: { width: 32, height: 32 },
        },
      }}
    >
      {isInfoOpen && (
        <InfoWindow>
          <InfoComponent />
        </InfoWindow>
      )}
    </Marker>
  );
};

export const TruckMarker = ({
  position,
  label,
  color = "#000",
  onClick,
  InfoComponent,
}) => {
  const [isInfoOpen, toggleInfoWindow] = useState(false);

  return (
    <Marker
      onClick={onClick}
      onMouseOver={(hover) => {
        toggleInfoWindow(true);
      }}
      onMouseOut={() => {
        toggleInfoWindow(false);
      }}
      position={position}
      options={{
        icon: {
          anchor: { x: 16, y: 16 },
          url:
            "data:image/svg+xml;charset=UTF-8;base64," +
            generateTruck(label, color),
          scaledSize: { width: 32, height: 32 },
        },
      }}
      zIndex={1000}
    >
      {isInfoOpen && (
        <InfoWindow onCloseClick={() => toggleInfoWindow(false)}>
          <InfoComponent />
        </InfoWindow>
      )}
    </Marker>
  );
};

export const LabelMarker = ({
  position,
  label,
  color = "#000",
  onClick,
  InfoComponent,
}) => {
  const [isInfoOpen, toggleInfoWindow] = useState(false);
  return (
    <Marker
      onClick={onClick}
      onMouseOver={(hover) => {
        toggleInfoWindow(true);
      }}
      onMouseOut={() => {
        toggleInfoWindow(false);
      }}
      position={position}
      options={{
        icon: {
          anchor: { x: 16, y: 16 },
          url:
            "data:image/svg+xml;charset=UTF-8;base64," +
            generateLabel(label, color),
          scaledSize: { width: 32, height: 32 },
        },
      }}
    >
      {isInfoOpen && InfoComponent && (
        <InfoWindow onCloseClick={() => toggleInfoWindow(false)}>
          <InfoComponent />
        </InfoWindow>
      )}
    </Marker>
  );
};
