/**
 * @file Entry file for Home Component
 * @author Mike Chacon
 */

import Users from "./users.container";
import { Redirect, Route, Switch } from "react-router";
import React from "react";

export default ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}`}
      component={() => <Redirect to={`${match.path}/driver`} />}
    />
    <Route exact path={`${match.path}/:userType`} component={Users} />
  </Switch>
);
