import Cookies from "js-cookie";
import { REACT_APP_MP_AUTH_COOKIE_KEY, REACT_APP_MP_COOKIE_DOMAIN } from "config";
import request from "utils/request";
import history from "../history";

export const getAuthToken = () => {
  // Temporary to convert local storage tokens to cookies for auth
  // if(!!localStorage['pickups.authToken']){
  //     Cookies.set(REACT_APP_MP_AUTH_COOKIE_KEY, localStorage['pickups.authToken'])
  //     delete localStorage['pickups.authToken']
  // }
};

export const logout = (userLoggingOut = false) => {
  // delete localStorage['pickups.authToken']
  delete localStorage["pickups.user"];
  request({
    url: "/v2/public/users/logout",
    method: "get",
  });

  if (window.redirectingToLogin) {
    return;
  }

  window.redirectingToLogin = true;
  setTimeout(() => {
    window.redirectingToLogin = false;
    if (userLoggingOut) {
      return history.push(`/login?loggedOut=true`);
    }
    return history.push(`/login?location=${window.location.pathname}`);
  }, 50);
};

export const loggedIn = () => {
  // if(!!localStorage['pickups.authToken']){
  //     Cookies.set(REACT_APP_MP_AUTH_COOKIE_KEY, localStorage['pickups.authToken'].split(' ')[1])
  //     delete localStorage['pickups.authToken']
  // }
  //
  return !!localStorage["pickups.user"];
  // return !!localStorage['pickups.authToken']
};

export const storeUser = (user = {}) => {
  localStorage["pickups.user"] = JSON.stringify(user);
};

export const getUser = () => {
  try {
    return JSON.parse(localStorage["pickups.user"]);
  } catch (e) {
    console.error(
      "Bad User Object in localStorage",
      localStorage["pickups.user"]
    );
    logout();
  }
};

// Require that a token be set before alowing on the page, otherwise redirect to login
export const requireAuth = (nextState, replace) => {
  if (!loggedIn()) {
    return replace({
      pathname: "/login",
    });
  }

  if (loggedIn() && nextState.location.pathname === "/") {
    return replace({
      pathname: "/dashboard",
    });
  }
};
