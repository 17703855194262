import React, { Component } from "react";
import RegionLayout from "./region-layout.container";
import { Route, withRouter } from "react-router";

import RegionZones from "./calendar";
import RegionPickupRoutes from "./routes";
import RegionPickupsBlocks from "./pickup-blocks";
import CreateUpdateRegion from "./create-update";

class SingleRegionRoutes extends Component {
  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <RegionLayout>
        <Route path={`${path}/zones`} component={RegionZones} />
        <Route path={`${path}/routes`} component={RegionPickupRoutes} />
        <Route path={`${path}/pickup-blocks`} component={RegionPickupsBlocks} />
        <Route path={`${path}/settings`} component={CreateUpdateRegion} />
      </RegionLayout>
    );
  }
}

export default withRouter(SingleRegionRoutes);
