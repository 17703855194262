import React, { useEffect, useState } from "react";
import PageLayout from "layouts/pages/page-default";
import Table, { RowActions, checkComponentOriginProp } from "components/table";
import Icon from "components/icon";
import Modal from "components/modal";
import UpdatePolicy from "forms/update-policy";
import RichTextEditor from "react-rte/lib/RichTextEditor";

const SMSPrivacyPolicy = ({ policies, requestSettings, updateSetting }) => {
  useEffect(requestSettings, [requestSettings]);

  const [edit, setEdit] = useState(false);

  return (
    <PageLayout title="Policies">
      <Table
        columns={[
          { Header: "Name", accessor: "name" },
          { Header: "Policy", accessor: "value", truncated: true },
        ]}
        actions={[
          {
            Header: "Edit",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.name}
                  actions={[
                    {
                      text: "Edit",
                      icon: "edit",
                      onClick: () =>
                        setEdit({
                          ...original,
                          value: RichTextEditor.createValueFromString(
                            original.value,
                            "html"
                          ),
                        }),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={policies}
      />
      {edit && (
        <Modal
          show={edit}
          onHide={() => setEdit(false)}
          title={`Edit ${edit.name}`}
        >
          <UpdatePolicy
            initialValues={edit}
            onSubmit={async (setting) => {
              await updateSetting({
                ...setting,
                setting: setting.value.toString("html"),
              });
              await requestSettings();
            }}
            onSubmitSuccess={() => setEdit(false)}
            onCancel={() => setEdit(false)}
          />
        </Modal>
      )}
    </PageLayout>
  );
};

export default SMSPrivacyPolicy;
