import React, { Component } from "react";
import { withRouter } from "react-router";
import RegionHeader from "./region-header.component";
import "./styles.scss";
import WithLoader from "hoc/with-loader";

class RegionZones extends Component {
  loadRegion() {
    const {
      match: {
        params: { regionId },
      },
      getRegion,
    } = this.props;

    if (regionId) {
      getRegion({ regionId });
    } else {
      getRegion({ regionId: 1 });
    }
  }

  constructor(props) {
    super(props);

    const { activeRegion } = this.props;

    if (!activeRegion) this.loadRegion();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { regionId },
      },
    } = this.props;

    if (
      prevProps.activeRegion &&
      prevProps.activeRegion.loaded &&
      prevProps.activeRegion.region.id !== parseInt(regionId || "1")
    ) {
      this.loadRegion();
    }
  }

  componentWillUnmount() {
    // Clear the active region when the user leaves the region
    this.props.clearActiveRegion();
  }

  render() {
    const { activeRegion, history } = this.props;
    const isFetchingRegion = !activeRegion || !activeRegion.loaded;

    return (
      <WithLoader isLoading={isFetchingRegion}>
        <RegionHeader
          id={activeRegion?.region?.id}
          label={activeRegion?.region?.label}
          history={history}
        />
        {this.props.children}
      </WithLoader>
    );
  }
}

export default withRouter(RegionZones);
