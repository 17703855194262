import { connect } from "react-redux";
import PickupBlocks from "./region-pickup-blocks.component";
import { getRegion } from "actions/regions.actions";

const mapStateToProps = (state) => ({
  activeRegion: state.regions.activeRegion,
});

const mapDispatchToProps = {
  getRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupBlocks);
