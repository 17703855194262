import React, { useContext, useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";

import ConfirmModal from "../../../components/confirm-modal";
import { PickupTypesContext } from "contexts/pickupTypes";

export default function (props) {
  const {
    zipCodeZones,
    setZipCodeZones,
    savedZipCodeZones,
    activeZipCodeZoneIdx,
    setActiveZipCodeZoneIdx,
    setHighlightedZipCode,
    modifiedZipCodeZones,
    setModifiedZipCodeZones,
    addModifiedZipCodeZone,
    removeModifiedZipCodeZone,
    newZipCodeZone,
    setNewZipCodeZone,
    removeZipCode,
    allZipCodes,
    geoJSONLayer,
    setPosition,
    initialNewZipCodeZone,
    regionId,
    saveZone,
    deleteZone,
  } = props;
  const { pickupTypes, getPickupTypes } = useContext(PickupTypesContext);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getPickupTypes();
  }, []);

  const stopsSetting = (props.stopsSetting && props.stopsSetting.value) || {};

  // Check if a zip code zone at the given index has been modified
  const zipCodeZoneIsModified = (idx) => modifiedZipCodeZones.has(idx);

  // Create the click handler for each zip code zone
  const zipCodeZoneOnClickFactory = (idx) => () => {
    setActiveZipCodeZoneIdx(idx);

    // If the newly selected zone has zip codes, focus the first one
    if (zipCodeZones[idx].zipCodes.length !== 0)
      focusZipCodeFactory(zipCodeZones[idx].zipCodes[0])();
  };

  // Handle creating new zip code zones
  const createNewZipCodeZone = (zone) => {
    setZipCodeZones([...zipCodeZones, zone]);
    setActiveZipCodeZoneIdx(zipCodeZones.length - 1);
  };

  // Handle clicking the edit button on a zip code zone
  const handleZipCodeZoneNameEditButtonFactory = (idx) => () => {
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].editing = true;
    setZipCodeZones(newZipCodeZones);
  };

  // Handle changing a zip code zone's name
  const handleZipCodeZoneNameChangeFactory = (idx) => (e) => {
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].name = e.target.value;
    setZipCodeZones(newZipCodeZones);
    addModifiedZipCodeZone(idx);
  };

  // Handle when the user adds a zip code to a zone using the tag input
  const handleZipCodeZoneZipCodesChangeFactory =
    (idx) => (zipCodes, newZipCodes) => {
      // Only change the selected zip codes if the zip codes are valid
      if (newZipCodes.every((zipCode) => allZipCodes.includes(zipCode))) {
        // Focus the last added zip code if zip codes were added
        if (zipCodes.length > zipCodeZones[idx].zipCodes.length) {
          focusZipCodeFactory(newZipCodes[newZipCodes.length - 1])();
        }

        // Remove new zip codes from other zones
        for (const zipCode of newZipCodes) removeZipCode(zipCode);

        const newZipCodeZones = [...zipCodeZones];
        // Set the zip codes for the current zone
        newZipCodeZones[idx].zipCodes = zipCodes;
        newZipCodeZones[idx].enteringZip = "";
        newZipCodeZones[idx].errors = { zipCode: "" };
        setZipCodeZones(newZipCodeZones);
        addModifiedZipCodeZone(idx);
      } else {
        const newZipCodeZones = [...zipCodeZones];
        newZipCodeZones[idx].errors = {
          zipCode: "We don't support this zip code",
        };
        setZipCodeZones(newZipCodeZones);
      }
    };

  // Handle the text input the TagsInput uses changing
  const handleZipCodeZoneZipCodesInputChangeFactory = (idx) => (e) => {
    addModifiedZipCodeZone(idx);
    setZipCodeZones(
      zipCodeZones.map((zone, map_idx) =>
        map_idx === idx
          ? { ...zone, enteringZip: e.target.value, errors: { zipCode: "" } }
          : zone
      )
    );
  };

  // Handle clicking the save button on a zip code zone
  const handleZipCodeZoneSave = () => {
    for (const zone of Array.from(modifiedZipCodeZones)
      .filter((idx) => idx < zipCodeZones.length)
      .map((idx) => zipCodeZones[idx])) {
      const enteringZipValid =
        zone.enteringZip && allZipCodes.includes(zone.enteringZip);
      if (enteringZipValid) removeZipCode(zone.enteringZip);
      // Call the passed in saveZone function
      saveZone({
        id: zone.id,
        name: zone.name,
        pickupTypeId: zone.pickupTypeId,
        // hasFurniture: zone.hasFurniture,
        regionId: zone.regionId,
        defaultBlockStops: zone.defaultBlockStops,
        zipCodes: [
          ...zone.zipCodes.map((zipCode) => ({
            zip: zipCode,
            zoneId: zone.id,
            disabled: false,
          })),
          ...(enteringZipValid && [
            { zip: zone.enteringZip, zoneId: zone.id, disabled: false },
          ]),
        ],
        color: zone.color.substring(1),
      });
    }

    setModifiedZipCodeZones(new Set());
  };

  // Handle clicking the cancel button on a zip code zone
  // This restores the zip code zone to the last saved state
  const handleZipCodeZoneCancelFactory = (idx) => () => {
    const newZipCodeZones = [...zipCodeZones];

    // If the zip code zone doesn't have an id (it was never saved) just delete it
    // otherwise replace it with the last saved version of itself
    if (zipCodeZones[idx].id === undefined) {
      newZipCodeZones.splice(1, 1);
    } else {
      newZipCodeZones[idx] = JSON.parse(JSON.stringify(savedZipCodeZones[idx])); // Make a deep copy
    }

    setZipCodeZones(newZipCodeZones);
    removeModifiedZipCodeZone(idx);
  };

  // Handle clicking the delete button on a zip code zone
  const handleZipCodeZoneDeleteFactory = (idx) => () => {
    // Remove the zip code from the modified zip codes array
    // and subtract one from each zip code zone greater than
    // the index of the zip code being deleted
    setModifiedZipCodeZones(
      new Set(
        Array.from(modifiedZipCodeZones)
          .filter((modIdx) => modIdx !== idx)
          .map((modIdx) => (modIdx > idx ? modIdx - 1 : modIdx))
      )
    );

    // Remove the zip code zone from the saved and unsaved zip code arrays
    const newZipCodeZones = [...zipCodeZones];
    const [deletedZipCodeZone] = newZipCodeZones.splice(idx, 1);
    setZipCodeZones(newZipCodeZones);

    // Call the callback passed in by the user if this zip code zone has an id
    deleteZone(deletedZipCodeZone.id, {
      ...deletedZipCodeZone,
      zipCodes: deletedZipCodeZone.zipCodes.map((zip) => ({ zip })),
      color: deletedZipCodeZone.color.substring(1),
    });
  };

  // Focus the zip code when a tag is clicked
  const focusZipCodeFactory = (zipCode) => (e) => {
    if (e) e.stopPropagation();

    geoJSONLayer.eachLayer((layer) => {
      if (zipCode === layer.feature.properties.ZCTA5CE10) {
        const lat =
          (layer._bounds._northEast.lat + layer._bounds._northEast.lat) / 2;
        const lng =
          (layer._bounds._northEast.lng + layer._bounds._northEast.lng) / 2;
        setPosition([lat, lng]);
      }
    });
  };

  // Handle changing the name of the new zip code zone
  const handleNewZipCodeZoneNameChange = (e) => {
    setNewZipCodeZone({ ...newZipCodeZone, name: e.target.value });
  };

  // Handle the zip code zone name field losing focus
  const handleZipCodeZoneNameBlurFactory = (idx) => () => {
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].editing = false;
    setZipCodeZones(newZipCodeZones);
  };

  // Handle the zip code zone default stops changing
  const handleZipCodeZoneDefaultStopsChangeFactory = (idx) => (e) => {
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].defaultBlockStops = e.target.value;
    setZipCodeZones(newZipCodeZones);
    addModifiedZipCodeZone(idx);
  };

  // Handle the zip code zone has furniture changing
  const handleZipCodeZoneHasFurnitureChangeFactory = (idx) => (e) => {
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].hasFurniture = e.target.checked;
    setZipCodeZones(newZipCodeZones);
    addModifiedZipCodeZone(idx);
  };

  const handleZonesPickupType = (idx) => (e) => {
    const value = e.target.value;
    const newZipCodeZones = [...zipCodeZones];
    newZipCodeZones[idx].pickupTypeId = value;
    setZipCodeZones(newZipCodeZones);
    addModifiedZipCodeZone(idx);
  };

  // Handle changing the zip codes in the new zip code zone
  const handleNewZipCodeZoneZipCodesChange = (zipCodes, newZipCodes) => {
    // Only change the selected zip codes if the zip codes are valid
    if (newZipCodes.every((zipCode) => allZipCodes.includes(zipCode))) {
      // Focus the last added zip code if zip codes were added
      if (zipCodes.length > newZipCodeZone.zipCodes.length) {
        focusZipCodeFactory(newZipCodes[newZipCodes.length - 1])();
      }

      // Remove new zip codes from other zones
      for (const zipCode of newZipCodes) removeZipCode(zipCode);

      setNewZipCodeZone({
        ...newZipCodeZone,
        zipCodes: zipCodes,
        enteringZip: "",
      });
      setErrors({ ...errors, zipCode: "" });
    } else {
      setErrors({ ...errors, zipCode: "we don't support this zip code" });
    }
  };

  // Handle the text input the TagsInput uses changing in the new zip code zone
  const handleNewZipCodeZoneZipCodesInputChange = (e) => {
    setNewZipCodeZone({ ...newZipCodeZone, enteringZip: e.target.value });
    setErrors({ ...errors, zipCode: "" });
  };

  // Handle the zip code zone default stops changing in the new zip code zone
  const handleNewZipCodeZoneDefaultStopsChangeFactory = (e) => {
    setNewZipCodeZone({ ...newZipCodeZone, defaultBlockStops: e.target.value });
  };

  // Handle the zip code zone has furniture changing in the new zip code zone
  // const handleNewZipCodeZoneHasFurnitureChangeFactory = (e) => {
  //   setNewZipCodeZone({
  //     ...newZipCodeZone,
  //     defaultBlockStops: e.target.checked
  //       ? stopsSetting.furniture
  //       : stopsSetting.stops,
  //     hasFurniture: e.target.checked,
  //   });
  // };

  // Handle saving the new zip code zone
  const handleNewZipCodeZoneSave = () => {
    // const newArr = newZipCodeZone.enteringZip.split(" ").map((code) => ({
    //   zip: code,
    // }));

    saveZone({
      name: newZipCodeZone.name,
      // hasFurniture: newZipCodeZone.hasFurniture,
      regionId: regionId,
      defaultBlockStops: newZipCodeZone.defaultBlockStops,
      zipCodes: newZipCodeZone.zipCodes.map((zipCode) => ({
        zip: zipCode,
      })),
      pickupTypeId: newZipCodeZone.pickupTypeId,
      color: newZipCodeZone.color.substring(1),
    }).then((res) => {
      createNewZipCodeZone(res.data);
      handleNewZipCodeZoneCancel();
    });
  };

  // Handle clicking the cancel button on the new zip code zone
  const handleNewZipCodeZoneCancel = () => {
    setNewZipCodeZone(initialNewZipCodeZone);
  };

  // Split a comma separated list of zip codes when it is pasted in the zip codes input
  const pasteSplit = (text) =>
    text
      .split(",")
      .map((zipCode) => zipCode.trim())
      .filter((zipCode) => allZipCodes.includes(zipCode));

  // Render zip codes in the tag input
  // Modified from the default in react-tagsinput
  const renderTag = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span
        key={key}
        onClick={focusZipCodeFactory(tag)}
        onMouseEnter={() => setHighlightedZipCode(tag)}
        onMouseLeave={() => setHighlightedZipCode(null)}
        style={{ cursor: "pointer" }}
        {...other}
      >
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a
            className={classNameRemove}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(key);
            }}
          />
        )}
      </span>
    );
  };

  const handleZonePickupType = (v) => {
    setNewZipCodeZone({ ...newZipCodeZone, pickupTypeId: v });
  };

  return (
    <div id="zip-code-zones">
      {zipCodeZones.map((zone, idx) => (
        <div
          className={`zip-code-zone ${
            activeZipCodeZoneIdx === idx ? "active" : ""
          }`}
          onClick={zipCodeZoneOnClickFactory(idx)}
          key={idx}
        >
          <div className="zip-code-zone-header">
            <div
              className="zip-code-zone-color-swatch"
              style={{ backgroundColor: zone.color }}
            ></div>
            <Form.Control
              type="text"
              value={zone.name}
              className="zip-code-zone-name"
              onChange={handleZipCodeZoneNameChangeFactory(idx)}
              onBlur={handleZipCodeZoneNameBlurFactory(idx)}
              readOnly={!zone.editing}
              plaintext={!zone.editing}
            />
            <div
              className="zip-code-zone-edit-name-button"
              onClick={handleZipCodeZoneNameEditButtonFactory(idx)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </div>
          </div>

          <div className="zip-code-zone-accordian-body">
            <TagsInput
              value={zone.zipCodes}
              onChange={handleZipCodeZoneZipCodesChangeFactory(idx)}
              addOnPaste={true}
              pasteSplit={pasteSplit}
              renderTag={renderTag}
              inputProps={{
                placeholder: "Add a zipcode",
                value: zone.enteringZip,
                onChange: handleZipCodeZoneZipCodesInputChangeFactory(idx),
              }}
            />
            <div>{zone.errors.zipCode}</div>
            <Form.Group style={{ marginBottom: "10px" }}>
              <Form.Label style={{ fontWeight: "initial" }}>
                Default Stops
              </Form.Label>
              <Form.Control
                type="number"
                value={zone.defaultBlockStops}
                className="zip-code-zone-stops"
                onChange={handleZipCodeZoneDefaultStopsChangeFactory(idx)}
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "10px" }}>
              <Form.Label style={{ fontWeight: "initial" }}>
                Pickup type
              </Form.Label>
              <Form.Control
                as="select"
                value={zone.pickupTypeId}
                onChange={handleZonesPickupType(idx)}
                custom
              >
                <option value={0} key={0}>
                  Select
                </option>

                {pickupTypes.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* 
            <div style={{ marginBottom: "10px" }}>
              <p>Has Furniture</p>
              <div className="checkbox-input-group">
                <label className="switch" htmlFor={`has-furniture-${idx}`}>
                  <input
                    type="checkbox"
                    id={`has-furniture-${idx}`}
                    checked={zone.hasFurniture}
                    onChange={handleZipCodeZoneHasFurnitureChangeFactory(idx)}
                  />
                  <div className="slider round" />
                </label>
              </div>
            </div> */}

            <div className="zip-code-zone-buttons">
              <Button
                onClick={handleZipCodeZoneSave}
                disabled={!zipCodeZoneIsModified(idx) || zone.name === ""}
              >
                Save
              </Button>
              <Button
                onClick={handleZipCodeZoneCancelFactory(idx)}
                disabled={!zipCodeZoneIsModified(idx)}
              >
                Cancel
              </Button>
              <ConfirmModal
                onConfirm={handleZipCodeZoneDeleteFactory(idx)}
                confirmText="Are you sure you want to permanently delete this zone?"
                buttonText="Delete"
              />
            </div>
          </div>
        </div>
      ))}

      <div
        className={`zip-code-zone ${
          activeZipCodeZoneIdx === zipCodeZones.length ? "active" : ""
        }`}
        onClick={() => {
          if (newZipCodeZone.zipCodes.length > 0)
            focusZipCodeFactory(newZipCodeZone.zipCodes[0])();
          setActiveZipCodeZoneIdx(zipCodeZones.length);
        }}
      >
        <div className="zip-code-zone-header">
          <div
            className="zip-code-zone-color-swatch"
            style={{ backgroundColor: newZipCodeZone.color }}
          ></div>
          <Form.Control
            type="text"
            placeholder="New Zone"
            value={newZipCodeZone.name}
            className="zip-code-zone-name"
            onChange={handleNewZipCodeZoneNameChange}
          />
        </div>

        <div className="zip-code-zone-accordian-body">
          <TagsInput
            value={newZipCodeZone.zipCodes}
            onChange={handleNewZipCodeZoneZipCodesChange}
            addOnPaste={true}
            pasteSplit={pasteSplit}
            renderTag={renderTag}
            inputProps={{
              placeholder: "Add a zipcode",
              value: newZipCodeZone.enteringZip,
              onChange: handleNewZipCodeZoneZipCodesInputChange,
            }}
          />
          <div>{errors.zipCode}</div>

          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Label style={{ fontWeight: "initial" }}>
              Default Stops
            </Form.Label>
            <Form.Control
              type="number"
              value={newZipCodeZone.defaultBlockStops}
              className="zip-code-zone-stops"
              onChange={handleNewZipCodeZoneDefaultStopsChangeFactory}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Label style={{ fontWeight: "initial" }}>
              Pickup type
            </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => handleZonePickupType(e.target.value)}
              custom
            >
              <option value={0} key={0}>
                Select
              </option>

              {pickupTypes.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* <div style={{ marginBottom: "10px" }}>
            <p>Has Furniture</p>
            <div className="checkbox-input-group">
              <label className="switch" htmlFor={`has-furniture-new`}>
                <input
                  type="checkbox"
                  id={`has-furniture-new`}
                  checked={newZipCodeZone.hasFurniture}
                  onChange={handleNewZipCodeZoneHasFurnitureChangeFactory}
                />
                <div className="slider round" />
              </label>
            </div>
          </div> */}
          <div className="zip-code-zone-buttons">
            <Button onClick={handleNewZipCodeZoneSave}>Save</Button>
            <Button onClick={handleNewZipCodeZoneCancel}>Cancel</Button>
            <Button disabled>Delete</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
