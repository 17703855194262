import { REQUEST_USERS, RECEIVE_USERS } from "../actionsConstants";

const defaultState = {
  isFetching: false,
  loaded: false,
  users: [],
};

const usersReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_USERS:
      return {
        ...state,
        users: action.data,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
