import { useCallback, useState } from "react";
import { getUsersV2 as APIGetUsers } from "api/users";
import UserListContext from "./context";
import { UserProvider as SingleUserProvider } from "../user";
import useResource from "../useResource";

const Provider = ({ children }) => {
  const [users, setUsers, usersLoading, usersError, clearUsersError, wrapper] =
    useResource([]);
  const [usersPagination, setPagination] = useState({});

  const getUsers = wrapper(
    useCallback(
      async (query) => {
        const data = (await APIGetUsers(query)).data;

        setUsers(data.data);
        setPagination(data.pagination);
      },
      [setUsers]
    )
  );

  const UserProvider = useCallback(
    ({ children, id }) => (
      <SingleUserProvider
        externalUser={users.find((user) => user.id === id)}
        externalSetUser={(updated) => {
          setUsers(users.map((user) => (user.id === id ? updated : user)));
        }}
      >
        {children}
      </SingleUserProvider>
    ),
    [setUsers, users]
  );

  return (
    <UserListContext.Provider
      value={{
        users,
        usersPagination,
        usersLoading,
        usersError,
        UserProvider,
        setUsers,
        clearUsersError,
        getUsers,
      }}
    >
      {children}
    </UserListContext.Provider>
  );
};

export default Provider;
