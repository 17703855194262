import { Field } from "redux-form";

import FormField from "../FormField";
import { required } from "../../utils/validators";

const EditNotificationTemplate = () => (
  <>
    <Field
      name="emailSubject"
      type="text"
      component={FormField}
      label="Email Subject"
      required={true}
      validate={[required]}
    />
    <Field
      name="sms"
      type="text"
      component={FormField}
      label="SMS"
      required={true}
      validate={[required]}
    />
  </>
);

export default EditNotificationTemplate;
