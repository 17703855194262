import React, { Component } from "react";
import {
  REACT_APP_MP_LOGIN_BACKGROUND_URL,
  REACT_APP_MP_LOGIN_BACKGROUND_COLOR,
  REACT_APP_MP_COMPANY_NAME,
  REACT_APP_MP_LOGIN_LOGO_URL,
} from "config";

class LoginComponent extends Component {
  render() {
    return (
      <div
        id="auth-container"
        className="login"
        style={{
          backgroundColor: REACT_APP_MP_LOGIN_BACKGROUND_COLOR,
          backgroundImage: `url(${REACT_APP_MP_LOGIN_BACKGROUND_URL})`,
        }}
      >
        <div className="login-box box">
          <div className="login-logo">
            <img
              alt={`${REACT_APP_MP_COMPANY_NAME}`}
              src={`${REACT_APP_MP_LOGIN_LOGO_URL}`}
            />
          </div>
          <div className="login-box-body">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default LoginComponent;
