import { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import ConfirmModal from "components/confirm-modal";

const RouteAssignDriver = ({
  route,
  users: { users: drivers },
  auth,
  updateRoute,
}) => {
  const [changeDriverTo, setChangeDriverTo] = useState(undefined);

  // Drivers can't change the route driver
  if (auth.credentials.userTypeId > 2) return null;

  return (
    <Form>
      <FormGroup>
        <FormControl
          as="select"
          value={route.driverId || -1}
          onChange={(e) =>
            setChangeDriverTo(
              drivers.find(
                (driver) => driver.id === parseInt(e.target.value)
              ) || null
            )
          }
        >
          <option value="-1">Select Driver</option>
          {drivers.map((driver) => {
            const isDriverDisabled = driver.disabled;
            const isDriverCurrentDriver = driver.id === route.driverId;
            if (!isDriverDisabled || isDriverCurrentDriver) {
              return <option value={driver.id}>{driver.fullName}</option>;
            }
          })}
        </FormControl>
      </FormGroup>
      <ConfirmModal
        show={changeDriverTo !== undefined}
        close={() => setChangeDriverTo(undefined)}
        title="Confirm change route driver"
        confirmText={
          changeDriverTo
            ? `Confirm ${changeDriverTo.fullName} as driver for Route ${route.id}`
            : `Confirm unnassigning a driver to Route ${route.id}`
        }
        onConfirm={() =>
          updateRoute({ ...route, driverId: changeDriverTo?.id || null })
        }
        hideButton={true}
      />
    </Form>
  );
};

export default RouteAssignDriver;
