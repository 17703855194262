import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import moment from "moment";
import { PickupTypesContext } from "contexts/pickupTypes";
import Loader from "components/loader";

import { toMeridiemTime, csvFileDate } from "utils/dates";
import { downloadFile } from "utils/helpers";
// Moment Dependency

import RenderDatePicker from "forms/date-picker";
// Rubix Components
import { FormGroup, FormLabel, Row, Col, Button } from "react-bootstrap";

// Form field wrapper for select input
import FormField from "forms/FormField";

import { required, requiredDropdown } from "utils/validators";

import { REACT_APP_MP_API_URL, REACT_APP_MP_SINGLE_REGION } from "config";

// const textarea = DOM.textarea;
// configure.setDateLocalizer(momentLocalizer(moment));

const selectPickupStatus = (
  nextStopNotificationSent,
  arrivedAt,
  pickupStatusLabel
) => {
  switch (true) {
    case !!arrivedAt:
      return "Arrived";
    case !!nextStopNotificationSent:
      return "Started";

    default:
      return pickupStatusLabel;
  }
};

const renderTextArea = ({
  input,
  meta: { touched, error, warning },
  label,
  renderCondition = true,
}) => {
  if (!renderCondition) {
    return null;
  }

  return (
    <FormGroup className={`textarea-form-group`}>
      <FormLabel>{label}</FormLabel>
      <textarea className="form-control" {...input} />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </FormGroup>
  );
};

/*
 *
 * Pickup Form render
 *
 * */
const PickupForm = ({
  locations = [],
  pickupStatuses = {},
  pickupStatusDescriptions = {},
  pickupSources = [],
  requestPickupSources,
  pickupBlocks = [],
  pickupBlockId,
  onCancel,
  handleSubmit,
  error,
  initialValues = {},
  pickupStatus,
  pickupDate,
  pristine,
  submitting,
  regions,
  regionsFiltered,
  formValues = {},
  pickupBlocksLoaded,
  pickupBlocksLoading,
  largeItems,
  requestPickupBlocks,
  requestRegionsFiltered,
  clearPickupBlocks,
  auth,
  pickupTypeId,
  selectRegion,
  setPickupTypeId,
}) => {
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [showPickuType, setShowPickuType] = useState(false);
  let pickupBlocksPlaceholder;
  const isPickupBlockIdFalse = formValues.pickupBlockId === "false";

  function pickupBlockPlaceholder(pickupBlockId, pickupBlocksLoading, isEdit) {
    if (pickupBlocksLoading) {
      pickupBlocksPlaceholder =
        "Loading the pickup blocks for this location...";
    } else {
      pickupBlocksPlaceholder = "Choose a pickup block";
    }

    if (isEdit && (pickupBlockId === "false" || !!pickupBlockId)) {
      pickupBlocksPlaceholder = "Other (no pickup block)";
    }

    return pickupBlocksPlaceholder;
  }

  const filterPickupStatusReasons = pickupStatusDescriptions.filter(
    (description) => {
      return description.statusId === +pickupStatus;
    }
  );

  const { pickupTypes, getPickupTypes } = useContext(PickupTypesContext);

  useEffect(() => {
    getPickupTypes();
    requestPickupSources();
  }, []);

  useEffect(() => {
    if (pickupTypes.length > 1) {
      setShowPickuType(true);
    } else {
      setShowPickuType(false);
    }

    // set  pickup type by default
    if (pickupTypes.length === 1) {
      setPickupTypeId(pickupTypes[0].id);
    }
  }, [pickupTypes]);

  /* Any statuses with the flag "disabled" in the db shouldn't be shown as valid selections, but if the record
	  we're looking at is already set to this status, we should see it so we need to filter in the form field rather than earlier.
	*  */

  const customPickup =
    pickupBlockId === "false" || initialValues.pickupBlockId === "false";
  const pickupBlockSelected = !!(formValues && formValues.pickupBlockId);
  const locationSelected = !!(
    formValues &&
    formValues.locationId &&
    formValues.locationId !== "-1"
  );
  const renderConditionGroundLevel = !!(
    formValues &&
    formValues.pickupTypeId &&
    pickupTypes.find((type) => type.id === parseInt(formValues.pickupTypeId))
      ?.requireItemsGroundFloor
  );

  const isFurnitureSelected =
    pickupTypes.find((item) => {
      return item.id === Number.parseInt(formValues?.pickupTypeId);
    })?.label === "Furniture";

  // Get only the pickup blocks that include the currently selected region
  const filteredPickupBlocks =
    formValues && locationSelected
      ? pickupBlocks.filter(
          (block) =>
            !block.zone ||
            block.zone.zipCodes.some(
              ({ zip }) =>
                zip ===
                locations.find(
                  (location) => location.id === Number(formValues.locationId)
                )?.zip
            )
        )
      : [];
  const regionSelected = !!(formValues && formValues.regionId);
  const pickupStatusId = formValues && formValues.statusId;
  // const pickupBlockId = formValues?.pickupBlockId;
  const hasLargeItems = largeItems;
  const isEdit = !!initialValues.id;
  const isComplete = initialValues.pickupStatus?.label === "Complete";

  useEffect(() => {
    if (!isEdit)
      requestPickupBlocks({
        availableStops: true,
        futureEvents: true,
        enabled: true,
      });

    return function cleanup() {
      clearPickupBlocks();
    };
  }, []);

  const onOneRegionSelect = (regions) => {
    if (regions.length === 1) {
      selectRegion(regions[0].id);
    }
  };

  useEffect(() => {
    if (isPickupBlockIdFalse && formValues.locationId) {
      const currentLocation = locations.find((loc) => {
        return parseInt(loc.id) === parseInt(formValues.locationId);
      });
      if (currentLocation) {
        requestRegionsFiltered(
          {
            zipCode: currentLocation.zip,
            pickupTypeId: formValues.pickupTypeId,
          },
          onOneRegionSelect
        );
      }
    }
  }, [formValues.pickupBlockId, formValues.pickupTypeId]);

  const selectEstimatedTime = () => {
    const pickupStatusId = initialValues.pickupTypeId;
    const estimatedArrivalTime = initialValues.estimatedArrivalTime;
    const timeWindow = initialValues.timeWindow;
    if (pickupStatusId === 1) {
      if (estimatedArrivalTime && estimatedArrivalTime !== "Invalid date") {
        return toMeridiemTime(estimatedArrivalTime);
      }
      if (timeWindow && timeWindow !== "Invalid date") {
        return timeWindow;
      }
    }
    return undefined;
  };

  const donwloadReceiptHandler = async () => {
    try {
      setReceiptLoading(true);
      await downloadFile(
        `${REACT_APP_MP_API_URL}/pickups/${initialValues.id}/receipt/${initialValues.receiptToken}`,
        `${csvFileDate(
          initialValues.pickupDate
        )}-${initialValues.user.lname.replace(/\W/g, "")}-${
          initialValues.id
        }.pdf`
      );
    } catch (error) {
    } finally {
      setReceiptLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <Field
            name="statusId"
            label="Pickup Status"
            options={pickupStatuses}
            component={FormField}
            type="select"
            className="form-control"
            required={true}
            validate={requiredDropdown}
          />
        </Col>
        <Col sm={6}>
          <Field
            label="Pickup Source"
            name="scheduleSourceId"
            options={pickupSources}
            component={FormField}
            type="select"
            className="form-control"
            required={true}
            validate={requiredDropdown}
          />
        </Col>
      </Row>
      <Field
        label="Pickup Status Description"
        name="statusReasonId"
        renderCondition={filterPickupStatusReasons.length > 0}
        options={filterPickupStatusReasons}
        component={FormField}
        type="select"
        optionField="description"
        className="form-control"
      />
      <Field
        name="locationId"
        label="Location"
        options={locations}
        optionField="addressLabel"
        component={FormField}
        type="select"
        className="form-control"
        required={true}
        disabled={isEdit}
        validate={requiredDropdown}
      />
      {auth.credentials.userType.label !== "driver" && (
        <>
          <Field
            renderCondition={showPickuType}
            label="Pickup Type"
            name="pickupTypeId"
            options={pickupTypes}
            component={FormField}
            type="select"
            required={true}
            validate={requiredDropdown}
            disabled={isEdit}
            className="form-control"
          />
          <Field
            label="Are your large items located on the ground level?"
            renderCondition={renderConditionGroundLevel}
            isDisabled={isEdit}
            name="largeItemsGroundFloor"
            component={FormField}
            className="form-control"
            type="checkbox"
          />
        </>
      )}
      <Field
        label="Pickup Block"
        name="pickupBlockId"
        options={filteredPickupBlocks}
        renderCondition={locationSelected && pickupTypeId !== "-1"}
        component={FormField}
        type="select"
        className="form-control"
        placeholder={pickupBlockPlaceholder(
          pickupBlockId,
          pickupBlocksLoading,
          isEdit
        )}
        disabled={isEdit}
        defaultErrorMessage={null}
        required={true}
        validate={requiredDropdown}
      />
      {regionsFiltered.loading ? (
        <div className=" d-flex align-items-center ">
          <Loader width={"50px"} />
          <div> Loading regions...</div>
        </div>
      ) : (
        <FormGroup>
          <Field
            name="regionId"
            label="Region"
            options={regionsFiltered.regionsFiltered}
            renderCondition={
              customPickup && REACT_APP_MP_SINGLE_REGION !== "true"
            }
            component={FormField}
            type="select"
            className="form-control"
            required={true}
            disabled={isEdit}
            validate={requiredDropdown}
          />
        </FormGroup>
      )}
      <Field
        label="Pickup Date"
        name="pickupDate"
        component={customPickup ? RenderDatePicker : FormField}
        type={customPickup ? null : "hidden"}
        renderCondition={
          (customPickup || pickupBlockSelected) &&
          (REACT_APP_MP_SINGLE_REGION !== "true" || regionSelected)
        }
        future={true}
        required={true}
        validate={required}
        onBlur={(e) => {
          e.preventDefault();
        }}
        disabled={!!initialValues.pickupDate}
      />
      <Field
        label={"Estimated Pickup Duration (minutes)"}
        renderCondition={locationSelected}
        name="estimatedPickupDuration"
        type="number"
        component={FormField}
      />
      <Field
        label={"Notes"}
        name="note"
        type="textarea"
        component={FormField}
        renderCondition={!isEdit}
      />
      <Field
        label={"Large Item(s) Description"}
        name="largeItemNote"
        type="textarea"
        component={FormField}
        renderCondition={isFurnitureSelected && !isEdit}
      />
      <Field
        label="Estimated Number of Bags/Boxes"
        renderCondition={locationSelected}
        name="estimatedBagsOrBoxes"
        default={undefined}
        component={FormField}
        className="form-control"
        type="number"
      />
      <Field
        label="Actual Number of Bags/Boxes"
        renderCondition={locationSelected}
        name="bagsOrBoxes"
        default={undefined}
        component={FormField}
        className="form-control"
        type="number"
      />
      <Field
        label="Mark as Verified"
        renderCondition={locationSelected}
        name="verified"
        component={FormField}
        type="checkbox"
      />
      {isEdit ? (
        <FormGroup>
          {initialValues.route ? (
            <div>
              <label style={{ display: "inline-block" }}>Route: </label>
              &nbsp;
              <span>
                {initialValues.route
                  ? initialValues.route.position + 1
                  : "Unassigned"}
              </span>
              <br />
              <label style={{ display: "inline-block" }}>Pickup Status: </label>
              &nbsp;
              <span>
                {selectPickupStatus(
                  initialValues.nextStopNotificationSent,
                  initialValues.arrivedAt,
                  initialValues.pickupStatus.label
                )}
              </span>
              <br />
              <label style={{ display: "inline-block" }}>Stop Number: </label>
              &nbsp;<span>{initialValues.stopNumber || "Unassigned"}</span>
              <br />
              <label style={{ display: "inline-block" }}>Driver: </label>
              &nbsp;
              <span>
                {initialValues.route && initialValues.route.driver
                  ? initialValues.route.driver.fullName
                  : "NA"}
              </span>
              <br />
              <label style={{ display: "inline-block" }}>
                Map Address:&nbsp;
              </label>
              <span>
                {initialValues.location && initialValues.location.mapAddress
                  ? initialValues.location.mapAddress
                  : "NA"}
              </span>
              <br />
              <label style={{ display: "inline-block" }}>
                Truck Type:&nbsp;
              </label>
              {initialValues.route && initialValues.route.hasFurniture
                ? "Furniture"
                : "Standard"}
              <br />
              {!!selectEstimatedTime() ? (
                <div>
                  <label style={{ display: "inline-block" }}>
                    Estimated Pickup: &nbsp;
                  </label>
                  {selectEstimatedTime()}
                  {/* {toMeridiemTime(initialValues.estimatedArrivalTime)} */}
                </div>
              ) : null}
            </div>
          ) : (
            <label>No Route Assigned</label>
          )}

          {isComplete && (
            <div>
              <Button
                className="px-0 m-sm-0 border-0 "
                variant="link"
                disabled={receiptLoading}
                onClick={donwloadReceiptHandler}
              >
                {receiptLoading ? "Loading Receipt..." : "Download Receipt"}
              </Button>
            </div>
          )}
        </FormGroup>
      ) : null}
    </>
  );
};

PickupForm.propTypes = {
  locations: PropTypes.array,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

export default PickupForm;
