import LoginForm from "forms/login";
import LoginLayout from "layouts/login";

const Login = () => (
  <LoginLayout>
    <LoginForm />
  </LoginLayout>
);

export default Login;
