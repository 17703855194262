import { connect } from "react-redux";
import { requestPickupBlocks, updatePickupBlock } from "actions/home.actions";

//TODO: Don't share reducer with this other component
import { requestPickups } from "pages/donors/donor-single/donor-pickups/pickups.actions";

import PickupsBlocks from "./pickup-blocks.component";
import { PickupTypesProvider } from "contexts/pickupTypes";

import { requestStats } from "actions/reports.actions";

const mapStateToProps = (state) => {
  return {
    pickupBlocks: {
      ...state.pickupBlocks,
    },
    unnassignedPickups: {
      ...state.pickups,
      pickups: state.pickups.pickups.filter((p) => {
        if (p.pickupBlockId === "false") {
          return true;
        } else {
          return !p.pickupBlockId;
        }
      }),
    },
    stats: {
      ...state.stats,
    },
  };
};

const mapDispatchToProps = {
  requestPickupBlocks,
  updatePickupBlock,
  requestPickups,
  requestStats,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <PickupTypesProvider>
    <PickupsBlocks {...props} />
  </PickupTypesProvider>
));
