import React, { Component } from "react";
import queryString from "query-string";

import { loggedIn } from "utils/auth";

class LoginComponent extends Component {
  parseQueryParams() {
    this.queryParams = queryString.parse(this.props.location.search);
  }

  componentWillReceiveProps(props) {
    if (loggedIn()) {
      console.log("login was authenticated");
      //TODO: Extract from here
      let location = "/dashboard";

      // redirect if we have /login?location=/dashboard/routes
      if (this.props.location.query && this.queryParams.location) {
        location = this.queryParams.location;
      }

      // This handles pushing drivers to their own routes
      if (props.auth.credentials.userTypeId === 3) {
        this.props.history.push("/dashboard/route");
      } else this.props.history.push(location);
    }
  }

  componentDidMount() {
    this.parseQueryParams();

    // If logged in forward to dashboard
    if (loggedIn()) {
      this.props.history.push(this.queryParams.location || "/dashboard");
    }
  }

  componentDidUpdate() {
    this.parseQueryParams();
  }

  render() {
    // The page to forward to once a successful login occurs
    // this.queryParams = queryString.parse(this.props.location.search);

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default LoginComponent;
