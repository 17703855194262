import React from "react";
import { detailedDiff } from "deep-object-diff";
import { formatTime } from "utils/helpers";

import { Container, Col, Row } from "react-bootstrap";

const dataStyles = {
  padding: 10,
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: 5,
};

function CompareActions(props) {
  const { before, after, createdAt } = props;

  let actionCreatedAt = formatTime(createdAt);

  before.createdAt = formatTime(before.createdAt);
  before.updatedAt = formatTime(before.updatedAt);

  after.updatedAt = formatTime(after.updatedAt);
  after.createdAt = formatTime(after.createdAt);

  const diffValues = detailedDiff(before, after);

  return (
    <div style={{ padding: 30, backgroundColor: "#eee" }}>
      <Container fluid>
        <h4 style={{ textAlign: "center" }}>{actionCreatedAt}</h4>
      </Container>
      <Container fluid>
        <Row>
          <Col sm={6}>
            <h4>Before The Action</h4>
            <pre style={dataStyles}>{JSON.stringify(before, null, 2)}</pre>
          </Col>
          <Col sm={6}>
            <h4>After The Action</h4>
            <pre style={dataStyles}>{JSON.stringify(diffValues, null, 2)}</pre>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CompareActions;
