import mixpanel from "mixpanel-browser";

const env = process.env.NODE_ENV || "development";

let Tracking = {};

// If we aren't in development we will track using mixpanel
if (env !== "development") {
  if (env === "staging") {
    mixpanel.init("5ee6ecaf3722eae5ac871d8d09dcbc9c");
  }

  if (env === "production") {
    mixpanel.init("d1762e6d7c84d2fba002b26f55ba2c40");
  }

  Tracking = {
    track: (event, props, cb) => {
      if (props) {
        mixpanel.track(event, props, cb);
      } else {
        mixpanel.track(event);
      }
    },
  };
} else {
  mixpanel.init("5ee6ecaf3722eae5ac871d8d09dcbc9c");

  Tracking = {
    track: (event, props, cb) => {
      if (props) {
        mixpanel.track(event, props, cb);
      } else {
        mixpanel.track(event);
      }
    },
  };

  // Fake "Tracking", just outputs events to console
  // Tracking = {
  //     track : (event, props = {}) => console.debug(`%c🛰  ${JSON.stringify(event) + ' : ' + JSON.stringify(props)}`, 'background: #a0e9ff; color: #333; padding: 10px;')
  // }
}

export default Tracking;
