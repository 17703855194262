import { connect } from "react-redux";
import RegionLayout from "./region-layout.component";
import { getRegion, clearActiveRegion } from "actions/regions.actions";

const mapStateToProps = (state, ownProps) => ({
  activeRegion: state.regions.activeRegion,
});

const mapDispatchToProps = {
  getRegion,
  clearActiveRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionLayout);
