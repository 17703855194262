// Just a big array of colors

let colors = [
  "#ff6262",
  "#ff62d6",
  "#6c62ff",
  "#627dff",
  "#62ff75",
  "#fdff62",
  "#ffaa62",
  "#18ae4a",
  "#974953",
];
for (let i = 0; i < 3; i++) {
  colors = colors.concat(colors);
}

export default colors;
