import { normalize, schema } from "normalizr";
import React from "react";

import store from "../store";

// Define a users schema
const user = new schema.Entity("users", {});
const normalizedData = (originalData) =>
  normalize(originalData, { data: [user] });

//<Component onSubmit={this.props.userCreate}/>

/*
class DataComponent extends React.Component {
    constructor(props) {
        this.state = {
            ids: [],
            byPage: {
                0: {
                    isLoading: false,
                    isLoaded: false,
                    items: []
                }
            },
            totalPages: 1,
            activePage: 1,
            count: 20
        }
    }

    getData(){
        this.props.
    }

    componentDidMount(){

       }

    handleSubmit() {
        // Set loading state
        this.setState({loading: true})
        this.props.onSubmit().then(() => {
            // What if this was search results.
            // Remove loading state
            this.submitSuccess()
            // Needs the results of the successful submit?
            //     Yes, this is it's subset of the records this component requires. So the component needs to handle the response also?
            //
            // From the response we can get 'pagination,
            // The response returns an object with...
            // Array of IDs in the order that is going to be displayed
        }).catch((err) => {
            // Remove loading state
            this.handleError(err)
            // Needs the error to come in
        })
    }

    submitSuccess() {
        // If this is an update, this component should refresh automatically
        this.setState({loading: false})

    }

}
*/
// get('users', {type: 3, page: 2}'

// Get function would allow a component to make a specific request for it's current state.
// They make the request, then return the result of the promise.

// Default Abstraction for data from the API. This will let us interact with the API via functions, then
// trigger actions when data has been updated.
export class apiModel {
  constructor(settings) {
    // Pass in an api object with
    //settings.api =  get(query), update(id, data), merge([ids])
    this.api = settings.api;
    this.name = settings.name;
    this.key = settings.key;
  }

  /* Returns the data to whatever component used this function */
  componentResponse(res) {
    let ids = res.data.map((r) => r.id);
    let componentData = { ids: ids };
    // let componentData = { ids: ids, count: res.count, pages: {} };
    // componentData.pages[`${res.pagination.page}`] = ids;
    // componentData.currentPage = res.pagination.page;
    return componentData;
  }

  createUpdate = (data) => {
    return (dispatch) => {
      const { name, api } = this;

      if (data.id) {
        dispatch({ type: `${name}_UPDATE`, data: data });
      } else {
        dispatch({ type: `${name}_CREATE`, data: data });
      }

      return api
        .createUpdate(data)
        .then((res) => {
          dispatch({ type: `${name}_UPDATED`, data: res.data.data[0] });
        })
        .catch((err) => {
          // Say that we received the existing data so that it isn't stuck loading
          dispatch({
            type: `${name}_RECEIVED`,
            data: Object.values(store.getState()[this.key].byId),
          });

          return err;
        });
    };
  };

  update = (id, data) => {
    return (dispatch) => {
      const { name, api } = this;
      dispatch({ type: `${name}_UPDATE`, data: data });

      return api
        .update(id, data)
        .then((res) => {
          dispatch({ type: `${name}_RECEIVED`, data: res.data });
        })
        .catch((err) => {
          return err;
        });
    };
  };

  get = (query = {}) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        const { api, name } = this;
        dispatch({ type: `${name}_REQUEST` });

        api
          .get(query)
          .then((res) => {
            // Dispatch the data to the reducer
            dispatch({ type: `${name}_RECEIVED`, data: res.data });
            resolve(this.componentResponse(res.data));
          })
          .catch((err) => {
            // Return the err to whatever component used this function
            reject(err);
          });
      });
  };

  merge = (ids = []) => {
    return (dispatch) => {
      const { api, name } = this;

      return api
        .merge(ids)
        .then((res) => {
          // Merge returns the winning Id. All the other IDs should be cleared
          // What if these records were paginated? How would they be removed from their individual pages?
          // Main store item should be marked for deletion
          // 1. Mark for deletion in the main store. If a component uses a deleted value, we should remove
          // 2. Clear from all components? Needs to be mark the record in the store as deleted.
          // [1...20] - Merge on 2 of these items, changes all responses after the merge

          // Every time we use the records, we need to check if any of them have been removed.
          // Whenever a component is instantiated, we should initiate it by pulling its data. In this way it doesn't
          // matter if a record was removed.

          // If the current record is open
          let winningRecord = res.data[0].id;
          let losingArray = ids.filter((id) => winningRecord !== id);

          // Dispatch the data to the reducer (the winning record)
          dispatch({ type: `${name}_RECEIVED`, data: res.data[0].id });

          dispatch({ type: `${name}_REMOVE`, data: losingArray });
          // Updates the winning record to include all of it's new associations

          // Remove old records, make sure that components can handle undefined key.
          const mergedIds = losingArray;

          // The component knows it's active page. We need to set the state of that page to isLoaded: false so it rehydrates.
          // We are sending back Id's of removed items.

          // Returns the data to whatever component used this function
          return mergedIds;
        })
        .catch((err) => {
          // Return the err to whatever component used this function
          return err;
        });
    };
  };
}

// Component Loads
export function reducerFactory(name) {
  return (
    state = {
      byId: {},
      pagination: {},
      isLoading: false,
      isLoaded: false,
      data: [],
    },
    action
  ) => {
    const { type, data } = action;

    switch (type) {
      case `${name}_RECEIVED`:
        return {
          ...state,
          // Could be any number of items, if they exist already, update them and change any loading states.
          byId: {
            ...normalizedData(action.data).entities.users,
          },
          data: action.data.data,
          pagination: action.data.pagination,
          isLoading: false,
          isLoaded: true,

          //foreach action.data, byId remove updating, add to allIds
        };

      // Mark the single data object as isFetching, this will provide loading state on the single object level.
      case `${name}_UPDATING`: {
        let newState = { ...state };
        newState.byId[data.id].isLoading = true;
        newState.data.find((item) => item.id === data.id).isLoading = true;

        return {
          ...newState,
          //foreach action.data, byId set them to updating...
        };
      }

      case `${name}_UPDATED`: {
        let newState = { ...state, isLoading: false, isLoaded: true };
        newState.byId[data.id] = data;
        newState.data = state.data.map((item) =>
          item.id === data.id ? data : item
        );

        return newState;
      }

      case `${name}_CLEAR`:
        return {
          ...state,
          // Clear a specific item or items, by ID from the reducer.
        };

      case `${name}_UPDATE`:
      case `${name}_CREATE`:
      case `${name}_REQUEST`:
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };

      default:
        return state;
    }
  };
}

// Each new data object
// export const createNamedWrapperReducer = (reducerName, reducerFunction = ReducerFactory) => {
//     return (state, action) => {
//
//         const {name} = action;
//
//         const isInitializationCall = state === undefined;
//
//         if(name !== reducerName && !isInitializationCall) return state
// ​
//         return reducerFunction(state, action)
//     }
// }
