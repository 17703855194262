import { Field } from "redux-form";
import FormField from "../FormField";
import { required, passwordValidation } from "../../utils/validators";
import React from "react";

const ForgotPasswordChangePassword = () => (
  <>
    <Field
      name="verificationCode"
      type="text"
      component={FormField}
      label="Code"
      required={true}
      validate={[required]}
    />
    <Field
      name="password"
      type="password"
      component={FormField}
      label="New Password"
      required={true}
      validate={[passwordValidation, required]}
    />
    <Field
      name="confirmPassword"
      type="password"
      component={FormField}
      label="Confirm New Password"
      required={true}
      validate={[passwordValidation, required]}
    />
  </>
);

export default ForgotPasswordChangePassword;
