import { useCallback, useEffect, useState } from "react";
import { Button, Form, ProgressBar } from "react-bootstrap";
import imageCompressor from "utils/imageCompressor";
import {
  deletePickupImage,
  getPickupImageCategories,
  getPickupImages,
  uploadPickupImage,
} from "api/pickups";
import PickupImagesDisplay from "./pickup-images-display";

const PickupImageUploadForm = ({ pickupId, setBadge }) => {
  const [uploading, setUploading] = useState(false);
  const [imageCategories, setImageCategories] = useState([]);
  const [images, setImages] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    if (setBadge) setBadge(images.length);
  }, [images.length]);

  useEffect(() => {
    getPickupImageCategories().then(setImageCategories);
  }, []);

  const getImages = useCallback(async () => {
    setImages(await getPickupImages({ id: pickupId }));
  }, [pickupId]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  const uploadImage = useCallback(async () => {
    setUploading(true);

    await uploadPickupImage({ id: pickupId }, selectedImage, selectedCategory);
    setSelectedImage(null);
    await getImages();
    setUploading(false);
  }, [getImages, pickupId, selectedCategory, selectedImage]);

  const deleteImage = useCallback(
    async (image) => {
      await deletePickupImage({ id: pickupId }, image);
      await getImages();
    },
    [getImages, pickupId]
  );

  return (
    <>
      <PickupImagesDisplay images={images} deleteImage={deleteImage} />
      {uploading ? (
        <ProgressBar now={100} animated />
      ) : (
        <Form>
          <Form.File
            label={selectedImage ? selectedImage.name : "Select an Image"}
            accept="image/png, image/jpeg"
            onChange={async (e) => {
              const images = await Promise.all(
                Array.from([e.target.files[0]]).map(async (file) => {
                  return await imageCompressor(file, {
                    toastErrorOptions: {
                      message:
                        "Sorry, we couldn't compress the image, it will take more time to upload",
                      timeToClose: 8000,
                    },
                  });
                })
              );
              setSelectedImage(images[0]);
            }}
            custom
          />

          <Form.Group>
            <Form.Label>Select a Category</Form.Label>
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              custom
            >
              <option value={0}>No Category</option>
              {imageCategories.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" onClick={uploadImage}>
            Upload
          </Button>
        </Form>
      )}
    </>
  );
};

export default PickupImageUploadForm;
