import request from "utils/request";

export const getNotificationTemplates = async () =>
  (await request({ method: "get", url: "/notificationTemplates" })).data.data;

export const updateNotificationTemplate = async (slug, data) =>
  (
    await request({
      method: "put",
      url: `/notificationTemplates/${slug}`,
      data,
    })
  ).data.data[0];

export const getNotificationTemplatePreview = async (template, type) =>
  (
    await request({
      method: "get",
      url: `/notificationTemplates/${template.slug}/render/${type}`,
    })
  ).data;
