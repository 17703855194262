import { apiModel } from "reducers/_reducer-factory";
import request from "utils/request";

export const get = (query = {}) =>
  request({ url: `/pickupStatuses`, params: query });
export const update = (values) =>
  request({ url: `/pickupStatuses/${values.id}`, method: "put", data: values });
export const createUpdate = (values) =>
  values.id
    ? request({
        url: `/pickupStatuses/${values.id}`,
        method: "put",
        data: values,
      })
    : request({ url: `/pickupStatuses`, method: "post", data: values });

const userActions = new apiModel({
  name: "PICKUP_STATUSES",
  key: "pickupStatuses",
  api: { get, update, createUpdate },
});

export default userActions;
