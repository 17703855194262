import { Field } from "redux-form";
import FormField from "forms/FormField";
import { required } from "utils/validators";

const CreateUpdateLeadSource = () => (
  <Field
    label="Label"
    name="label"
    type="text"
    component={FormField}
    required={true}
    validate={required}
  />
);

export default CreateUpdateLeadSource;
