import { connect } from "react-redux";
import { reduxForm, change, formValueSelector, initialize } from "redux-form";
import RegionFormComponent from "./create-update-region.component";

// Connect Redux Form
let RegionFormConnected = reduxForm({ form: "NewRegion" })(RegionFormComponent);

const mapDispatchToProps = (dispatch) => {
  const handleSelectStart = (address) => {
    dispatch(change("NewRegion", "routeStartCoordinates", address.coordinates));
    dispatch(change("NewRegion", "routeStartLocation", address.mapAddress));
  };

  const handleSelectEnd = (address) => {
    dispatch(change("NewRegion", "routeEndCoordinates", address.coordinates));
    dispatch(change("NewRegion", "routeEndLocation", address.mapAddress));
  };

  return {
    handleSelectStart,
    handleSelectEnd,
  };
};

const formSelector = formValueSelector("NewRegion");
const mapStateToProps = (state, ownProps) => {
  let initialValues;
  const { region } = ownProps;

  if (region) {
    initialValues = {
      ...region,
      pickupTypeDefaults: Object.fromEntries(
        region.pickupTypeDefaults.map((pickupTypeDefault) => [
          `id${pickupTypeDefault.pickupTypeId}`,
          pickupTypeDefault,
        ])
      ),
      routeStartCoordinates: JSON.stringify(region.routeStartCoordinates),
      routeEndCoordinates: JSON.stringify(region.routeEndCoordinates),
    };
  }

  return {
    initialValues: initialValues,
    separateFurniture: formSelector(state, "separateFurniture"),
    onSubmit: (data) => {
      ownProps.onSubmit({
        ...data,
        pickupTypeDefaults: Object.entries(data.pickupTypeDefaults).map(
          ([pickupTypeIdStr, defaults]) => ({
            regionId: data.id,
            pickupTypeId: parseInt(pickupTypeIdStr.substring(2)),
            ...defaults,
          })
        ),
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionFormConnected);
