import React, { useContext } from "react";

import Form from "../context-form";
import { passwordValidation } from "utils/validators";
import { UserContext } from "contexts/user";

const ChangePassword = ({ onCancel, onSubmitSuccess }) => {
  const { userLoading, changePassword } = useContext(UserContext);

  return (
    <Form
      onSubmit={({ password }) => changePassword(password)}
      submitting={userLoading}
      onSubmitSuccess={onSubmitSuccess}
      onCancel={onCancel}
    >
      <Form.Field
        name="password"
        type="password"
        label="Password"
        options={{
          required: "Password is required",
          validate: passwordValidation,
        }}
      />

      <Form.Watch name="password">
        {(password) => (
          <Form.Field
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            options={{
              required: "Confirm password is required",
              validate: (confirmPassword) =>
                confirmPassword === password ||
                "Confirm password must match password",
            }}
          />
        )}
      </Form.Watch>
    </Form>
  );
};

export default ChangePassword;
