import RegionDropdown from "./region-drop-down.container";
import React from "react";
import Icons from "../../components/icon";
import { LinkContainer } from "react-router-bootstrap";
import { REACT_APP_MP_PRIMARY_COLOR } from "config";

const NavLink = ({ to, icon, children }) => (
  <LinkContainer to={to} exact={true} className="nav-item tab-nav-item">
    <a>
      <div className="icon-text-wrapper">
        <span
          style={{ marginRight: ".5rem", color: REACT_APP_MP_PRIMARY_COLOR }}
        >
          <Icons type={icon} />
        </span>
        <span className="text">{children}</span>
      </div>
    </a>
  </LinkContainer>
);

const RegionHeader = ({ id, label, history }) => {
  // Get the currently active tab
  let activeTab = history.location.pathname.split("/").pop();
  if (
    !["routes", "map", "zones", "pickup-blocks", "settings"].includes(activeTab)
  )
    activeTab = "";

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <RegionDropdown
          label={label}
          linkTo={"/dashboard/regions/${id}/" + activeTab}
        />
      </div>

      <span
        className={
          " tab-navigation-region d-flex align-items-center justify-content-center mb-4"
        }
      >
        <NavLink
          to={{
            pathname: `/dashboard/regions/${id}/routes`,
            search: history.location.search,
          }}
          icon={"truck"}
        >
          Routes
        </NavLink>
        <NavLink
          to={{
            pathname: `/dashboard/regions/${id}/map`,
            search: history.location.search,
          }}
          icon={"map"}
        >
          Map
        </NavLink>
        <NavLink
          to={{
            pathname: `/dashboard/regions/${id}/zones`,
            search: history.location.search,
          }}
          icon={"calendar"}
        >
          Calendar
        </NavLink>
        <NavLink
          to={{
            pathname: `/dashboard/regions/${id}/pickup-blocks`,
            search: history.location.search,
          }}
          icon={"mapSigns"}
        >
          Blocks
        </NavLink>
        <NavLink
          to={{
            pathname: `/dashboard/regions/${id}/settings`,
            search: history.location.search,
          }}
          icon={"cog"}
        >
          Settings
        </NavLink>
      </span>
    </div>
  );
};

export default RegionHeader;
