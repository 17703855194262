import React, { useState } from "react";
import Notes from "forms/notes";
import Tabs from "../tabs";
import { locationsNotesAPI } from "api/locations";
import CreateUpdateLocation from "forms/create-update-location";

const LocationModal = (props) => {
  const [currentTab, setCurrentTab] = useState("details");
  const [badges, setBadges] = useState({});

  if (!props?.initialValues?.id) return <CreateUpdateLocation {...props} />;

  const tabs = {
    details: <CreateUpdateLocation {...props} />,
    notes: (
      <Notes
        entityId={props.initialValues.id}
        notesAPI={locationsNotesAPI}
        setBadge={(badge) => setBadges({ ...badges, notes: badge })}
      />
    ),
  };

  return (
    <>
      <Tabs active={currentTab} onChange={setCurrentTab}>
        <Tabs.Tab value="details">Details</Tabs.Tab>
        <Tabs.Tab value="notes" badge={badges["notes"]}>
          Notes
        </Tabs.Tab>
      </Tabs>
      {Object.entries(tabs).map(([tab, component]) => (
        <div
          style={{ backgroundColor: "white", padding: 20 }}
          className={currentTab !== tab ? "d-none" : ""}
          key={tab}
        >
          {component}
        </div>
      ))}
    </>
  );
};

export default LocationModal;
