import request from "utils/request";
import { showToast } from "./toast.actions";
export const REQUEST_REGIONS_FILTERED = "REQUEST_REGIONS_FILTERED";
export const RECEIVE_REGIONS_FILTERED = "RECEIVE_REGIONS_FILTERED";
export const REQUEST_REGIONS = "REQUEST_REGIONS";
export const RECEIVE_REGIONS = "RECEIVE_REGIONS";
export const DELETE_REGION = "DELETE_REGION";
export const REGIONS_FETCH_FAILED = "REGIONS_FETCH_FAILED";
export const REQUEST_REGION = "REQUEST_REGION";
export const FETCH_REGION = "FETCH_REGION";
export const RECEIVE_REGION = "RECEIVE_REGION";
export const CLEAR_ACTIVE_REGION = "CLEAR_ACTIVE_REGION";

export const receiveRegionsFiltered = ({ regions }) => {
  return {
    type: RECEIVE_REGIONS_FILTERED,
    regions,
    receivedAt: new Date(),
  };
};

export const requestRegionsFiltered = (data = {}, onSucces) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_REGIONS_FILTERED });
    return request({ url: `/regions`, params: data })
      .then((res) => {
        const { regions } = res.data;
        onSucces && onSucces(regions);
        dispatch(receiveRegionsFiltered({ regions }));
      })
      .catch(() => {
        dispatch(handleFailedRegionsFetch());
      });
  };
};

export const requestRegions = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_REGIONS });
    return request({ url: `/regions`, params: { getAll: true } })
      .then((res) => {
        const { regions } = res.data;
        dispatch(receiveRegions({ regions }));
      })
      .catch(() => {
        dispatch(handleFailedRegionsFetch());
      });
  };
};

export const getRegion = ({ regionId }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_REGION });
    return request(`/regions/${regionId}`)
      .then((res) => {
        const region = res.data;
        dispatch(receiveRegion({ region }));
      })
      .catch((err) => console.log("Error", err));
  };
};

export const updateRegion = ({ payload }) => {
  return (dispatch) => {
    return request({
      url: `/regions/${payload.id}`,
      method: "put",
      data: payload,
    })
      .then((res) => {
        const region = res.data;
        dispatch(receiveRegion({ region }));
        dispatch(requestRegions());
        // showToast({ message: 'Saved', type: 'success' })
      })
      .catch((err) => {
        // showToast({ message: err.message, type: 'error' });
      });
  };
};

export const createRegion = ({ payload }) => {
  return (dispatch) => {
    return request({
      url: `/regions`,
      method: "post",
      data: payload,
    })
      .then(() => {
        dispatch(requestRegions());
      })
      .catch((err) => {
        showToast({ message: err.message, type: "error" });
      });
  };
};

export const receiveRegions = ({ regions }) => {
  return {
    type: RECEIVE_REGIONS,
    regions,
    receivedAt: new Date(),
  };
};

export const receiveRegion = ({ region }) => {
  return {
    type: RECEIVE_REGION,
    activeRegion: region,
    receivedAt: new Date(),
  };
};

export const handleFailedRegionsFetch = () => {
  return {
    type: REGIONS_FETCH_FAILED,
  };
};

export const clearActiveRegion = () => {
  return {
    type: CLEAR_ACTIVE_REGION,
  };
};
