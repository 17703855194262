import {
  REQUEST_CUSTOMER,
  RECEIVE_CUSTOMER,
  CLEAR_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_COMPLETED,
} from "./customer.actions";

const defaultCustomerState = { isFetching: false, loaded: false, customer: {} };

function customer(state = defaultCustomerState, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_CUSTOMER:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        customer: action.customer,
        lastUpdated: action.receivedAt,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case UPDATE_CUSTOMER_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        customer: action.customer,
        lastUpdated: action.receivedAt,
      };
    case CLEAR_CUSTOMER:
      return {
        ...defaultCustomerState,
      };
    default:
      return state;
  }
}

//
// function pickupStatuses(
//   state = { isFetching: false, loaded: false, pickupStatuses: [] },
//   action
// ) {
//   switch (action.type) {
//     case REQUEST_PICKUP_STATUSES:
//       return {
//         ...state,
//         loaded: false,
//         isFetching: true,
//       };
//     case RECEIVE_PICKUP_STATUSES:
//       return {
//         ...state,
//         loaded: true,
//         isFetching: false,
//         pickupStatuses: action.pickupStatuses,
//         lastUpdated: action.receivedAt,
//       };
//     default:
//       return state;
//   }
// }
//

// function pickup(
//   state = { isFetching: false, loaded: false, pickup: {} },
//   action
// ) {
//   switch (action.type) {
//     case CREATE_PICKUP:
//     case UPDATE_PICKUP:
//       return {
//         ...state,
//         pickup: {},
//         loaded: false,
//         isFetching: true,
//       };
//     case CREATE_PICKUP_COMPLETED:
//     case UPDATE_PICKUP_COMPLETED:
//       return {
//         ...state,
//         loaded: true,
//         isFetching: true,
//         pickup: action.pickup,
//         lastUpdated: action.receivedAt,
//       };
//     default:
//       return state;
//   }
// }
//

export default customer;
