import { SubmissionError } from "redux-form";

import Tracking from "utils/tracking";

export const submissionErrorHandler = (res) => {
  const error = res.response.data.error || res.response.data.errors || {};
  const details = { _error: "There were errors submitting the form" };

  if (error.isJoi) {
    error.details.forEach((err) => {
      details[err.path] = err.message;
    });
  }

  Tracking.track("Submission Error", res);

  console.error("Submission error", res);
  throw new SubmissionError(details);
};

export const publicSubmissionErrorHandler = (res) => {
  const error = res.response.data.error || res.response.data.errors || {};
  const details = { _error: "There were errors submitting the form" };

  if (error.isJoi) {
    error.details.forEach((err) => {
      details[err.path] = err.message;
    });
  }

  Tracking.track("Submission Error", res);

  console.error("Submission error", res);
  throw new SubmissionError(details);
};

export default null;
