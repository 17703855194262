import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import Footer from "./footer";
import Header from "./header";
import SidebarNavigation from "./sidebar";
import {
  REACT_APP_MP_SINGLE_REGION,
  REACT_APP_MP_OLD_ROUTING,
  REACT_APP_MP_LOGO_URL,
} from "config";
import Home from "pages/home";
import DriverRoute from "pages/driver-route";
import Customers from "pages/donors";
import Users from "pages/user-manager";
import ExportData from "pages/export-data-new";
import MarketingReports from "pages/campaigns";
import TimeWindows from "pages/time-windows";
import Analytics from "pages/analytics";
import NotificationTemplates from "pages/notification-templates";
import Regions from "pages/regions";
import SMSPrivacyPolicy from "pages/sms-privacy-policy";
import SingleRegionRoutes from "pages/regions/single-region-routes.component";
import LeadSources from "pages/lead-sources";
import LocationTypes from "pages/location-types";
import Icon from "components/icon";

const DashboardRoutes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={Home} />
    <Route path={`${match.path}/route`} component={DriverRoute} />
    <Route path={`${match.path}/donors`} component={Customers} />
    <Route path={`${match.path}/users`} component={Users} />
    <Route path={`${match.path}/exportData`} component={ExportData} />
    <Route path={`${match.path}/campaigns`} component={MarketingReports} />
    <Route path={`${match.path}/policy`} component={SMSPrivacyPolicy} />
    <Route path={`${match.path}/lead-sources`} component={LeadSources} />
    <Route path={`${match.path}/location-types`} component={LocationTypes} />
    <Route
      path={`${match.path}/notification-templates`}
      component={NotificationTemplates}
    />
    {REACT_APP_MP_OLD_ROUTING === "true" && (
      <Route path={`${match.path}/time-windows`} component={TimeWindows} />
    )}
    <Route path={`${match.path}/analytics`} component={Analytics} />
    {REACT_APP_MP_SINGLE_REGION !== "true" ? (
      <Route path={`${match.path}/regions`} component={Regions} />
    ) : (
      <SingleRegionRoutes />
    )}
  </Switch>
);

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    // props.loadUser();
  }

  render() {
    return (
      <div id="dashboard">
        <div id={"main-container"}>
          <div id={"row-container"}>
            <div id={"sidebar-container"}>
              <div className={"sidebar-branding"}>
                <img src={REACT_APP_MP_LOGO_URL} />
              </div>
              <SidebarNavigation />
            </div>
            <div id={"page-container"}>
              <Header />
              <div id={"page-body"}>
                <DashboardRoutes {...this.props} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardComponent);
