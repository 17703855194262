import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default (props) => (
  <div className="content-wrapper">
    <div className="title-with-actions">
      {props.title ? <h2 className={"title-text"}>{props.title}</h2> : null}
      {props.actionComponents && props.actionComponents.length > 0 ? (
        <div className={"title-action"}>
          {props.actionComponents.map((ActionComponent, idx) => (
            <ActionComponent key={idx} {...props.actionComponentProps?.[idx]} />
          ))}
        </div>
      ) : null}
    </div>
    {props.children}
  </div>
);
