import request from "../utils/request";

export const getLocationTypes = async (params) =>
  (await request({ method: "get", url: "/locationTypes", params })).data.data;

export const createLocationType = async (source) =>
  (await request({ method: "post", url: "/locationTypes", data: source })).data
    .data[0];

export const updateLocationType = async (source) =>
  (
    await request({
      method: "put",
      url: `/locationTypes/${source.id}`,
      data: source,
    })
  ).data.data[0];
