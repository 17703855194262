import request from "utils/request";
// import { browserHistory } from 'react-router'
import { loggedIn, getUser } from "utils/auth";

import { AUTHENTICATE_USER_COMPLETE } from "actions/login.actions";
import { showToast } from "actions/toast.actions";

export function loadUser() {
  if (!loggedIn()) {
    return { type: AUTHENTICATE_USER_COMPLETE, auth: {} };
  }

  const user = getUser();

  // this does an async check to make sure
  // the user is still logged
  request(`/users/${user.id}`).catch((err) => {
    showToast({ message: "Unable to retrieve user data", type: "error" });
    console.warn("User not logged in, redirecting to /login");
  });

  return {
    type: AUTHENTICATE_USER_COMPLETE,
    auth: user,
    recievedAt: new Date(),
  };
}
