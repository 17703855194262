import axios from "axios";
import { logout } from "./auth";
import { REACT_APP_MP_API_URL, REACT_APP_MP_REQUEST_TIMEOUT } from "config";
import { showToast } from "actions/toast.actions";
import history from "../history";

const transformResponse = [
  (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      if (data === "Unauthorized") {
        logout();
      } else if (data === "OK") {
        // This is to deal with express returning plaintext by default for DELETE routes
        return {};
      } else {
        console.error(e);
      }
    }
  },
];

export default (...args) => {
  const request = axios.create({
    baseURL: REACT_APP_MP_API_URL,
    transformResponse: transformResponse,
    withCredentials: true,
    timeout: args[0].noTimeout ? 0 : REACT_APP_MP_REQUEST_TIMEOUT || 10000,
  });

  // Add a response interceptor
  request.interceptors.response.use(
    function (response) {
      if (
        ["post", "put", "delete"].includes(response.config.method) &&
        response.status >= 200 &&
        response.status < 300 &&
        response.config.url !== "/users/authenticate" &&
        response.config.url !== "/v2/users/verify_token" &&
        !response.config.url.match(/^\/routes\/\d+\/locations$/)
      ) {
        showToast({ message: "Success", type: "success" });
      }
      return response;
    },
    function (error) {
      if (error.code === "ECONNABORTED") {
        showToast({
          message: "Request timed out",
          type: "error",
        });
        history.push("/timeout");
      }

      const errorMessage =
        error.response.data.message ||
        (error.response.data.errors &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].general) ||
        error.response;

      if (typeof errorMessage === "string")
        showToast({
          message: errorMessage,
          type: "error",
        });

      return Promise.reject(error);
    }
  );

  return request(...args);
};
