import React from "react";
import { dateOnly } from "utils/dates";
import ListGroupWithEdit from "components/list-group-with-edit";
import WidgetHeader from "components/widget-header";
import queryString from "query-string";
import LocationModal from "components/location-modal";
import Loader from "components/loader";
import { withRouter } from "react-router";

class DonorLocationsList extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData();

    this.state = {
      initialOpenNewLocation: false,
    };
  }

  fetchData() {
    const { isLoaded, isFetching, donorId, requestLocations } = this.props;
    if (!isLoaded && !isFetching) {
      requestLocations({ customerId: donorId });
    }
  }

  getHistoryAndSearch() {
    const { history } = this.props;
    const searchParams = queryString.parse(
      history.location.search.substring(1)
    );
    return { history, searchParams };
  }

  readLocationQueryParam() {
    const { searchParams } = this.getHistoryAndSearch();
    if (searchParams.location) {
      this.setState({
        initialOpenNewLocation: searchParams.location,
      });
    }
  }

  cutLocationQueryParam() {
    const { history, searchParams } = this.getHistoryAndSearch();
    if (searchParams.location) {
      const search = history.location.search
        .substring(1)
        .replace("location=1", "");
      history.replace(`${history.location.pathname}?${search}`);
    }
  }

  breakInitialOpenNewLocation() {
    this.cutLocationQueryParam();
    this.setState({
      initialOpenNewLocation: false,
    });
  }

  componentDidMount() {
    this.readLocationQueryParam();
  }

  componentDidUpdate(prevProps) {
    this.fetchData();

    if (
      this.props.history.location.search !== prevProps.history.location.search
    )
      this.readLocationQueryParam();
  }

  componentWillUnmount() {
    const { clearLocations } = this.props;
    clearLocations();
  }

  render() {
    const { locations, donorId, isFetching, isLoaded } = this.props;

    if (isFetching || !isLoaded) {
      return <Loader />;
    }

    const renderLocation = (location) =>
      location.disabled
        ? {
            title: location.address,
            subtitle: `${location.city}, ${location.state}, ${location.zip}`,
            badge: "Archived",
            muted: true,
          }
        : {
            title: location.label ? location.label : location.address,
            subtitle:
              (location.label ? location.address + ", " : "") +
              `${location.city}, ${location.state}, ${location.zip}`,
            badge: dateOnly(location.createdAt),
            tags: {
              ...(location.unit ? { Unit: location.unit } : {}),
              ...(location.gated
                ? {
                    "Gated Community": "",
                    ...(location.gateCode
                      ? { "Gate Code": location.gateCode }
                      : {}),
                  }
                : {}),
              ...(location.subdivision
                ? { Subdivision: location.subdivision }
                : {}),
              ...(location.development
                ? { Development: location.development }
                : {}),
            },
          };

    return (
      <React.Fragment>
        <WidgetHeader title={"Locations"} count={locations.length} />
        <ListGroupWithEdit
          name={"Location"}
          objectArray={locations.map((location) => ({
            ...location,
            locationTypeId: location.locationTypeId || -1,
          }))}
          EditComponent={LocationModal}
          EditSubmit={async (data) => {
            if (parseInt(data.locationTypeId) === -1)
              data.locationTypeId = null;
            const locationRequest = await this.props.updateLocation(data);
            await this.props.requestCustomerActions(this.props.donorId);

            return locationRequest;
          }}
          NewComponent={LocationModal}
          NewSubmit={async (data) => {
            this.breakInitialOpenNewLocation();
            if (data.locationTypeId === "-1") data.locationTypeId = null;
            const locationRequest = await this.props.createLocation(data);
            await this.props.requestCustomerActions(this.props.donorId);
            return locationRequest;
          }}
          displayFunction={renderLocation}
          ownerId={donorId}
          formProps={this.props.formProps}
          initialOpenNew={this.state.initialOpenNewLocation}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(DonorLocationsList);
