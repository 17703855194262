import { Badge } from "react-bootstrap";
import React from "react";
export default function WidgetHeader(props) {
  return (
    <div className={"widget-header"}>
      <div className={"widget-title"}>
        <h3>{props.title}</h3>
      </div>
      {props.count ? (
        <div className={"widget-meta"}>
          <Badge variant={"primary"}>{props.count}</Badge>
        </div>
      ) : null}
    </div>
  );
}
