import { createContext } from "react";

export default createContext({
  user: {},
  userLoading: false,
  userError: false,

  setUser: () => {},
  clearUserError: () => {},
  updateUser: () => {},
  changeProfileImage: () => {},
  resetProfileImage: () => {},
  changePassword: () => {},
});
