import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import querystring from "query-string";

import NewDonorForm from "forms/create-update-donor";
import ZipPickupDateSearch from "components/zip-pickup-date-search";

import { Col, Row } from "react-bootstrap";
import Box from "components/box";
import PageLayout from "layouts/pages/page-default";

//TODO: Remove all reference to old createLocation page in this component
const NewCustomerComponent = ({
  requestLeadSources,
  history,
  createCustomer,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    requestLeadSources();
    let initialValues = querystring.parse(history.location.search.substr(1));
    if (initialValues.phone) {
      initialValues.phoneNumbers = [{ number: initialValues.phone }];
      delete initialValues.phone;
    } else {
      initialValues.phoneNumbers = [{ number: "" }];
    }
    initialValues.languageId = 1;
    setInitialValues(initialValues);
  }, []);

  return (
    <PageLayout title={"Add New Donor"}>
      <Row>
        <Col sm={7}>
          <Box>
            <NewDonorForm
              onSubmit={createCustomer}
              onSubmitSuccess={(result) => {
                history.push(
                  `/dashboard/donors/${result.customer.id}?location=1`
                );
              }}
              initialValues={initialValues}
              // onCancel={this.handleCancel}
            />
          </Box>
        </Col>
        <Col sm={5}>
          <Box title={"Pickup Date Search"}>
            <ZipPickupDateSearch />
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default withRouter(NewCustomerComponent);
