import React, { Component, useState } from "react";
import moment from "moment";
import {
  getDate,
  getStartOrEndISOS,
  getDateUtcOffset,
  getDateUtcOffsetEndOfDay,
} from "utils/dates";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import { Col, Row, Badge } from "react-bootstrap";
import WithLoader from "hoc/with-loader";
import { Bar, Doughnut } from "react-chartjs-2";
import Box, { BoxTitle } from "components/box";
import DisplayPickupStatsByDay from "./pickup-stats-by-day.component";
import PickupCountDonutGraph from "./pickup-count-donught-graph.component";
import PickupStatusDonutGraph from "./pickups-status-donught-graph.component";
import PickupCountsBarGraph from "./pickup-count-bar-graph.component";
import PageLayout from "layouts/pages/page-default";
import { useWindowDimensions } from "utils/helpers";

const HomeComponent = ({
  stats,
  pickupsScheduledOnStats,
  pickupStatuses,
  requestStats,
  requestPickupsScheduledOnStats,
}) => {
  const [date, setDate] = useState(moment(Date.now()));
  const { width } = useWindowDimensions();
  const columnFormat = width < 1000 ? 12 : 6;
  // TODO: Take this out of here.
  const onDateChange = (newDate) => {
    // TODO: This doesn't belong here, the date should come in ready to use in the component.
    const formatedDateTimeZoneZero = getDate(newDate);

    const formatedDateStartDay = getStartOrEndISOS(newDate);

    const formatedDateEndDay = getStartOrEndISOS(newDate, false);

    requestPickupsScheduledOnStats(formatedDateStartDay, formatedDateEndDay);
    requestStats(formatedDateTimeZoneZero, formatedDateTimeZoneZero);

    setDate(moment(newDate));
  };

  return (
    <PageLayout title={"Pickups Dashboard"}>
      <Box>
        <DateDayNav date={date} onChange={onDateChange} />
      </Box>
      <Row>
        <Col sm={columnFormat}>
          <Box>
            <BoxTitle count={stats.count}>Pickups</BoxTitle>
            <WithLoader isLoading={stats.isFetching || !stats.loaded}>
              <PickupStatusDonutGraph sources={stats.pickupTypes} />
            </WithLoader>
          </Box>
          <Box>
            <BoxTitle>Sources</BoxTitle>
            <WithLoader isLoading={stats.isFetching || !stats.loaded}>
              <PickupStatusDonutGraph sources={stats.sources} />
            </WithLoader>
          </Box>
          <Box>
            <BoxTitle>Statuses</BoxTitle>
            <WithLoader isLoading={stats.isFetching || !stats.loaded}>
              <PickupCountsBarGraph
                statuses={stats.statuses}
                allStatuses={pickupStatuses}
              />
            </WithLoader>
          </Box>
        </Col>
        <Col sm={columnFormat}>
          <Box>
            <BoxTitle count={pickupsScheduledOnStats.count}>Incoming</BoxTitle>
            <WithLoader
              isLoading={
                pickupsScheduledOnStats.isFetching ||
                !pickupsScheduledOnStats.loaded
              }
            >
              <PickupStatusDonutGraph
                sources={pickupsScheduledOnStats.pickupTypes}
              />
            </WithLoader>
          </Box>

          <Box>
            <BoxTitle>Sources</BoxTitle>
            <WithLoader
              isLoading={
                pickupsScheduledOnStats.isFetching ||
                !pickupsScheduledOnStats.loaded
              }
            >
              <PickupStatusDonutGraph
                sources={pickupsScheduledOnStats.sources}
              />
            </WithLoader>
          </Box>

          <Box>
            <BoxTitle>Statuses</BoxTitle>
            <WithLoader
              isLoading={
                pickupsScheduledOnStats.isFetching ||
                !pickupsScheduledOnStats.loaded
              }
            >
              <PickupCountsBarGraph
                statuses={pickupsScheduledOnStats.statuses}
                allStatuses={pickupStatuses}
              />
            </WithLoader>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default HomeComponent;
