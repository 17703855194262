import request from "utils/request";

export const REQUEST_MARKETING_REPORTS = "REQUEST_MARKETING_REPORTS";
export const RECEIVE_MARKETING_REPORTS_SUCCESS =
  "RECEIVE_MARKETING_REPORTS_SUCCESS";
export const RECEIVE_MARKETING_REPORTS_ERROR =
  "RECEIVE_MARKETING_REPORTS_ERROR";

export const requestMarketingReports = () => (dispatch) => {
  dispatch({ type: REQUEST_MARKETING_REPORTS });

  return request({ url: "/v2/reports/marketing-reports" })
    .then(
      (res) =>
        new Promise((resolve) =>
          resolve(dispatch(receiveMarketingReportsSuccess(res.data)))
        )
    )
    .catch(
      (err) =>
        new Promise((resolve) =>
          resolve(dispatch(receiveMarketingReportsError(err.response)))
        )
    );
};

const receiveMarketingReportsSuccess = (data) => ({
  type: RECEIVE_MARKETING_REPORTS_SUCCESS,
  marketingReports: data,
  receivedAt: new Date(),
});

const receiveMarketingReportsError = (err) => ({
  type: RECEIVE_MARKETING_REPORTS_SUCCESS,
  error: err,
  receivedAt: new Date(),
});

export const CREATE_MARKETING_REPORT = "CREATE_MARKETING_REPORT";
export const CREATE_MARKETING_REPORT_COMPLETED =
  "CREATE_MARKETING_REPORT_COMPLETED";
export const CREATE_MARKETING_REPORT_ERROR = "CREATE_MARKETING_REPORT_ERROR";

export const createMarketingReports = (data) => {
  return (dispatch) => {
    dispatch({ type: CREATE_MARKETING_REPORT });

    if (data.zipCodes[0] === undefined) {
      data.zipCodes = [];
    }

    return request({
      url: "/v2/reports/marketing-reports",
      method: "post",
      data: data,
    })
      .then(
        (res) =>
          new Promise((resolve) =>
            resolve(dispatch(createMarketingReportsCompleted(res.data)))
          )
      )
      .catch(
        (err) =>
          new Promise((resolve) =>
            resolve(dispatch(createMarketingReportsError(err.response)))
          )
      );
  };
};

const createMarketingReportsCompleted = (data) => {
  return {
    type: CREATE_MARKETING_REPORT_COMPLETED,
    marketingReport: data,
    receivedAt: new Date(),
  };
};

const createMarketingReportsError = (err) => ({
  type: CREATE_MARKETING_REPORT_ERROR,
  error: err,
  receivedAt: new Date(),
});

export const UPDATE_MARKETING_REPORT = "UPDATE_MARKETING_REPORT";
export const UPDATE_MARKETING_REPORT_COMPLETED =
  "UPDATE_MARKETING_REPORT_COMPLETED";
export const UPDATE_MARKETING_REPORT_ERROR = "UPDATE_MARKETING_REPORT_ERROR";

export const updateMarketingReports = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MARKETING_REPORT });

    if (data.zipCodes[0] === undefined) {
      data.zipCodes = [];
    }

    return request({
      url: `/v2/reports/marketing-reports/${data.id}`,
      method: "put",
      data: data,
    })
      .then(
        (res) =>
          new Promise((resolve) =>
            resolve(dispatch(updateMarketingReportsCompleted(res.data)))
          )
      )
      .catch(
        (err) =>
          new Promise((resolve) =>
            resolve(dispatch(updateMarketingReportsError(err.response)))
          )
      );
  };
};

const updateMarketingReportsCompleted = (data) => {
  return {
    type: UPDATE_MARKETING_REPORT_COMPLETED,
    marketingReport: data,
    receivedAt: new Date(),
  };
};

const updateMarketingReportsError = (err) => ({
  type: UPDATE_MARKETING_REPORT_ERROR,
  error: err,
  receivedAt: new Date(),
});

export const DELETE_MARKETING_REPORT = "DELETE_MARKETING_REPORT";
export const DELETE_MARKETING_REPORT_COMPLETED =
  "DELETE_MARKETING_REPORT_COMPLETED";
export const DELETE_MARKETING_REPORT_ERROR = "DELETE_MARKETING_REPORT_ERROR";

export const deleteMarketingReports = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_MARKETING_REPORT });

    return request({
      url: `/v2/reports/marketing-reports/${id}`,
      method: "delete",
    })
      .then(
        () =>
          new Promise((resolve) =>
            resolve(dispatch(deleteMarketingReportsCompleted(id)))
          )
      )
      .catch(
        (err) =>
          new Promise((resolve) =>
            resolve(dispatch(deleteMarketingReportsError(err.response)))
          )
      );
  };
};

const deleteMarketingReportsCompleted = (id) => {
  return {
    type: DELETE_MARKETING_REPORT_COMPLETED,
    marketingReportId: id,
    receivedAt: new Date(),
  };
};

const deleteMarketingReportsError = (err) => ({
  type: DELETE_MARKETING_REPORT_ERROR,
  error: err,
  receivedAt: new Date(),
});
