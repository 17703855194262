import { showToast } from "actions/toast.actions";
import imageCompression from "browser-image-compression";

const imageCompressor = async (
  file,
  { compressionOptions = {}, onFail, onSuccess, toastErrorOptions = {} } = {}
) => {
  compressionOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
    ...compressionOptions,
  };
  toastErrorOptions = {
    show: true,
    message: `Sorry, we couldn't compress the image`,
    type: "error",
    ...toastErrorOptions,
  };
  try {
    const compressedFile = await imageCompression(file, compressionOptions);
    if (onSuccess) {
      return onSuccess(compressedFile);
    }
    return compressedFile;
  } catch (error) {
    if (onFail) {
      return onFail({ error, file });
    }
    toastErrorOptions.show && showToast(toastErrorOptions);
    return file;
  }
};
export default imageCompressor;
