import React, { Component } from "react";
import querystring from "query-string";
import DateTimePicker from "react-widgets/DatePicker";
import Localization from "react-widgets/Localization";
import { DateLocalizer } from "react-widgets/IntlLocalizer";
import {
  getTomorrow,
  getYesterday,
  formatDateHyphenatedYearFirst,
  toDate,
  checkForValidDate,
  customFormat,
  getDateWithoutTimeZone,
} from "utils/dates";

import history from "../../history";

class DateByDayDateLocalizer extends DateLocalizer {
  constructor() {
    super("en-US", 0);

    this.date = (date, format) => customFormat(date, format);
  }
}

class DateDayNav extends Component {
  state = {
    error: false,
  };

  componentDidMount = () => {
    // Check if a date is present in the URL
    // If a date is present, call onChange and specify the date
    const dateFromURL = querystring.parse(history.location.search).date;
    if (dateFromURL) {
      this.props.onChange(toDate(dateFromURL));
    } else {
      let defaultDate = formatDateHyphenatedYearFirst(new Date());
      this.addDateToURL(defaultDate, true);
      this.props.onChange(defaultDate);
    }
  };

  addDateToURL = (date, replace = false) => {
    // Add the passed in date to the URL as a parameter
    history[replace ? "replace" : "push"](
      history.location.pathname + "?" + querystring.stringify({ date })
    );
  };

  handleNavigation = (newDate) => {
    this.addDateToURL(formatDateHyphenatedYearFirst(newDate));
    this.props.onChange(newDate);
  };

  _clickForward = () => {
    let newDate = getTomorrow(this.props.date);
    // this.setState({'date': newDate});
    this.handleNavigation(newDate);
  };

  _clickBack = () => {
    let newDate = getYesterday(this.props.date);
    // this.setState({'date': newDate});
    this.handleNavigation(newDate);
  };

  handleEnterPress = (e) => {
    const newDate = e.target.value;
    const isDateValid = checkForValidDate({ date: newDate });
    const isEnterButton = e.key === "Enter";
    if (isEnterButton) {
      if (isDateValid) {
        this.handleNavigation(newDate);
        this.setState({ error: false });
      } else {
        this.setState({ error: true });
      }
    }
  };

  handleChange = (date) => {
    const isDateValid = checkForValidDate({ date });
    if (isDateValid) {
      this.handleNavigation(date);
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    // let containerStyles = {
    //   lineHeight: "54px",
    //   fontSize: "24px",
    //   display: "flex",
    // };

    return (
      <div className="date-by-day-container">
        <div className="date-picker-arrow-container pull-left">
          <button
            type="button"
            onClick={this._clickBack}
            className="fc-prev-button fc-button fc-state-default fc-corner-left fc-corner-right"
          >
            <span className="fc-icon fc-icon-left-single-arrow"></span>
          </button>
          <button
            type="button"
            onClick={this._clickForward}
            className="fc-next-button fc-button fc-state-default fc-corner-left fc-corner-right"
          >
            <span className="fc-icon fc-icon-right-single-arrow"></span>
          </button>
        </div>
        <div className="pull-left" style={{ flex: 1 }}>
          <Localization date={new DateByDayDateLocalizer()}>
            <DateTimePicker
              value={toDate(getDateWithoutTimeZone(this.props.date), true)}
              valueDisplayFormat="MM/DD/YYYY - dddd"
              onChange={this.handleChange}
              // returnValue={"range"}
              valueEditFormat="MM/DD/YYYY"
              onKeyPress={this.handleEnterPress}
            />
          </Localization>
        </div>
      </div>
    );
  }
}

export default DateDayNav;
