import "react-vertical-timeline-component/style.min.css";
import { formatLongDateParenWeekday } from "utils/dates";
import Box from "components/box";
import Modal from "components/modal";
import Loader from "components/loader";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import CompareActions from "pages/donors/donor-single/compare-actions";
import Icon from "components/icon";
import React from "react";

const TimelineDay = ({
  date,
  actions,
  handleSeeChangesClick,
  collapsed,
  setCollapsed,
}) => (
  <div className={"user-actions-timeline"}>
    <div
      className={"user-actions-timeline-date-row"}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div className={"user-actions-timeline-date-icon"}>
        <Icon type={"calendar"} />
      </div>
      <div
        className={"user-actions-timeline-date"}
        style={{ cursor: "pointer" }}
      >
        {date}
        {collapsed && (
          <Badge variant="primary" style={{ marginLeft: ".5rem" }}>
            {actions.length}
          </Badge>
        )}
      </div>
    </div>

    {collapsed ||
      actions.map((action, i) => {
        const user = action.user;
        const userName = `${user.fname} ${user.lname}`;
        const actionType =
          action.action === "destroy" ? "destroyed" : `${action.action}d`;

        return (
          <div className={"user-actions-timeline-single-action"} key={i}>
            <strong>{userName}</strong>&nbsp;
            {actionType} a {action.entityType.slice(0, -1)}
            &nbsp;
            <Button
              variant={"link"}
              onClick={handleSeeChangesClick.bind(null, action)}
            >
              (View Changes)
            </Button>
          </div>
        );
      })}
  </div>
);

class DonorTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changesModalData: null,
      showChangesModal: false,
      collapsed: {},
    };
  }

  _handleSeeChangesClick = (action) =>
    this.setState({
      changesModalData: action,
      showChangesModal: true,
    });

  _closeModal = () => {
    this.setState({
      showChangesModal: false,
    });
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { requestCustomerActions, isFetching, isLoaded, donorId } =
      this.props;

    if (!isFetching && !isLoaded) {
      requestCustomerActions(donorId);
    }
  }

  componentWillUnmount() {
    this.props.clearCustomerActions();
  }

  render() {
    const { customerActions, isFetching, isLoaded } = this.props;
    const { actions } = customerActions;
    const isFetchingCustomerActions = customerActions.isFetching;

    if (isFetching || !isLoaded || isFetchingCustomerActions) {
      return (
        <Box>
          <Loader />
        </Box>
      );
    }

    // Need to group changes by date so we can create a Timeline.
    // Will group in an object like { '<date>': [action, action] }
    // Which we can iterate through with Object.keys() later
    const actionsByDate = {};
    actions.forEach((action, i) => {
      const actionDate = formatLongDateParenWeekday(action.createdAt);

      if (actionsByDate[actionDate]) {
        actionsByDate[actionDate].push(action);
      } else {
        actionsByDate[actionDate] = [action];
      }
    });

    return (
      <React.Fragment>
        <Box>
          <>
            <ButtonGroup>
              <Button
                variant={"link"}
                onClick={() =>
                  this.setState({
                    collapsed: Object.fromEntries(
                      Object.keys(actionsByDate).map((date) => [date, true])
                    ),
                  })
                }
              >
                Collapse All
              </Button>
              <Button
                variant={"link"}
                onClick={() =>
                  this.setState({
                    collapsed: Object.fromEntries(
                      Object.keys(actionsByDate).map((date) => [date, false])
                    ),
                  })
                }
              >
                Expand All
              </Button>
            </ButtonGroup>
            {Object.keys(actionsByDate).map((date) => {
              const actions = actionsByDate[date];

              return (
                <TimelineDay
                  date={date}
                  actions={actions}
                  handleSeeChangesClick={this._handleSeeChangesClick}
                  collapsed={!!this.state.collapsed[date]}
                  setCollapsed={() =>
                    this.setState({
                      collapsed: {
                        ...this.state.collapsed,
                        [date]: !this.state.collapsed[date],
                      },
                    })
                  }
                />
              );
            })}
          </>
        </Box>
        {this.state.showChangesModal ? (
          <Modal
            show={this.state.showChangesModal}
            onHide={this._closeModal}
            title={this.state.changesModalData.action}
          >
            <CompareActions
              createdAt={this.state.changesModalData.createdAt}
              before={this.state.changesModalData.beforeAction}
              after={this.state.changesModalData.afterAction}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DonorTimeline;
