import { useCallback, useEffect, useState } from "react";

import LanguagesContext from "./context";
import { getLanguages as APIGetLanguages } from "api/languages";

const LanguagesProvider = ({ children }) => {
  const [languages, setLanguages] = useState([]);

  const getLanguages = useCallback(
    (opts) => APIGetLanguages(opts).then(setLanguages),
    []
  );

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  return (
    <LanguagesContext.Provider
      value={{
        languages,
        getLanguages,
      }}
    >
      {children}
    </LanguagesContext.Provider>
  );
};

export default LanguagesProvider;
