import { connect } from "react-redux";
import Regions from "./regions-list.component";
import { requestRegions, updateRegion } from "actions/regions.actions";

const mapStateToProps = (state, ownProps) => ({
  ...state.regions,
});

const mapDispatchToProps = {
  requestRegions,
  updateRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
