import React from "react";
import admin from "./admin";
import agent from "./agent";
import driver from "./driver";
import { NavHeader, NavLink } from "./sidebar.component";

const MobileNav = ({ active, close, userTypeId }) => (
  <div id="mobile-nav" className={active ? "active" : ""}>
    {userTypeId
      ? Object.entries({ 1: admin, 2: agent, 3: driver }[userTypeId]).map(
          ([heading, items]) => (
            <>
              <NavHeader>{heading}</NavHeader>
              {items.map((item) => (
                <NavLink to={item.to} icon={item.icon} onClick={close}>
                  {item.text}
                </NavLink>
              ))}
            </>
          )
        )
      : null}
  </div>
);

export default MobileNav;
