import React from "react";
import PropTypes from "prop-types";
import Box, { BoxTitle } from "components/box";
import { Row } from "react-bootstrap";

import Loader from "components/loader";
import { DateRangeChart } from "components/charts";

const PickupCount = (props) => {
  PickupCount.propTypes = {
    loading: PropTypes.bool.isRequired,
    pickupLabels: PropTypes.array.isRequired,
    pickupsDataset: PropTypes.array.isRequired,
  };

  const { loading, pickupLabels, pickupsDataset } = props;

  return (
    <Box>
      <BoxTitle>Incoming Pickups</BoxTitle>
      {loading ? (
        <div className="analytics-loader-container">
          <Loader />
        </div>
      ) : (
        <Row>
          <DateRangeChart dataset={pickupsDataset} labels={pickupLabels} />
        </Row>
      )}
    </Box>
  );
};

export default PickupCount;
