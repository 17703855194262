import React from "react";
import { Dropdown } from "react-bootstrap";
import Icons from "components/icon";
import { connect } from "react-redux";
import { logout } from "actions/login.actions";
import Modal from "components/modal";
import UpdateDonorProfile from "forms/update-donor-profile";
import { UserContext } from "contexts/user";
import InternalNotifications from "components/internal-notifications";
import Icon from "../../components/icon";
import MobileNav from "./sidebar/mobile-nav.container";

const CustomToggle = React.forwardRef(({ user, userType, onClick }, ref) => (
  <div
    className={"user-dropdown"}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className={"user-dropdown-avatar"}>
      <div className={"user-dropdown-avatar-icon"}>
        <img style={{ width: 48, height: 48 }} src={user.profileImage} alt="" />
      </div>
      {/*<Image className={"user-dropdown-avatar-img"} src={"http://placehold.it/50"} roundedCircle/>*/}
    </div>
    <div className={"user-dropdown-info"}>
      <div className={"user-dropdown-name"}>
        {user.fname} {user.lname}
      </div>
      <div className={"user-dropdown-type"}>{userType}</div>
    </div>
  </div>
));

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfile: false,
      mobileNavOpen: false,
    };
  }

  render() {
    const { logout, user, userType } = this.props;

    return (
      <>
        <div className={"top-nav"}>
          {/*<div className={"page-title"}>Pickups Dashboard</div>*/}

          {/*Re-enable when there are actual notifications*/}
          {/*<InternalNotifications />*/}
          <div
            id="mobile-nav-toggle"
            onClick={() =>
              this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
            }
          >
            <Icon type="bars" />
          </div>

          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              className="user-dropdown"
              user={user}
              userType={userType}
            />

            <Dropdown.Menu>
              {/*<Dropdown.Item eventKey="1">Settings</Dropdown.Item>*/}
              <Dropdown.Item
                onClick={() => this.setState({ editProfile: true })}
              >
                Profile
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={logout}>
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Modal
            show={this.state.editProfile}
            title="Edit Profile"
            onHide={() => this.setState({ editProfile: false })}
          >
            <UpdateDonorProfile
              onCancel={() => this.setState({ editProfile: false })}
              onSubmitSuccess={() => this.setState({ editProfile: false })}
            />
          </Modal>
        </div>
        <MobileNav
          active={this.state.mobileNavOpen}
          close={() => this.setState({ mobileNavOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
  userType: state.auth.credentials.userType.label,
});

const mapDispatchToProps = {
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <UserContext.Consumer>
    {({ user }) => <Header {...props} user={user} />}
  </UserContext.Consumer>
));
