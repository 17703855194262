import React, { Component } from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router";
import "./styles.scss";
import CreateUpdateRegion from "./create-update";

import RegionLayout from "./region-layout.container";
import RegionPickupRoutes from "./routes";
import RegionList from "./list";
import RegionZones from "./calendar";
import RegionMap from "./map";
import RegionPickupsBlocks from "./pickup-blocks";

class RegionRoutes extends Component {
  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <RegionLayout>
        <Route exact path={`${path}`} component={RegionZones} />
        <Route path={`${path}/zones`} component={RegionZones} />
        <Route path={`${path}/map`} component={RegionMap} />
        <Route path={`${path}/routes`} component={RegionPickupRoutes} />
        <Route path={`${path}/pickup-blocks`} component={RegionPickupsBlocks} />
        <Route path={`${path}/settings`} component={CreateUpdateRegion} />
      </RegionLayout>
    );
  }
}

RegionRoutes = withRouter(RegionRoutes);

class Regions extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}`} component={RegionList} />
        <Route path={`${this.props.match.path}/new`}>
          <CreateUpdateRegion newRegion={true} />
        </Route>
        <Route
          path={`${this.props.match.path}/:regionId`}
          component={RegionRoutes}
        />
      </Switch>
    );
  }
}

export default withRouter(Regions);
