import {
  REQUEST_ROUTE_PICKUPS,
  RECEIVE_ROUTE_PICKUPS,
  REQUEST_ROUTE_PICKUPS_BY_ROUTEID,
  RECEIVE_ROUTE_PICKUPS_BY_ROUTEID,
  RECEIVE_ROUTE_PICKUPS_MINIMAL,
  REQUEST_ROUTE_PICKUPS_MINIMAL,
  UPDATE_ROUTE_PICKUP,
  UPDATE_ROUTE_PICKUP_COMPLETED,
  UPDATE_ROUTE,
  UPDATE_ROUTE_COMPLETED,
  UPDATE_ROUTE_DRIVER,
  UPDATE_ROUTE_FURNITURE,
  UPDATE_PICKUP_DATE,
  REQUEST_ROUTES,
  RECEIVE_ROUTES,
  REQUEST_ROUTE_PICKUP_BLOCKS,
  RECEIVE_ROUTE_PICKUP_BLOCKS,
  UPLOAD_ROUTE_CSV,
  UPLOAD_ROUTE_CSV_COMPLETED,
  BULK_DELETE_ROUTE,
  BULK_DELETE_ROUTE_COMPLETED,
} from "../actionsConstants";

export default { pickupRoutes, routePickupBlocks };

const defaultState = {
  isFetching: false,
  pickupsFetching: false,
  pickupsLoaded: false,
  pickupsMinFetching: false,
  pickupsMinLoaded: false,
  loaded: false,
  pickupsMin: [],
  pickups: [], // pickupsIDs in array to keep order
  pickupsMap: {}, // pickups from date key/value
  routesMap: {}, // all routes from date key/value
  pickupStatuses: [],
  pickupStatusMap: {}, // pickupStatuses
  unnassignedPickups: [], // any pickups retrieved that have no routeId
  pickupRoutes: [], // raw routes retrieved from server
};

// Map indicating it's a keyed object instead of array. e.g: routeLocationsMap
function pickupRoutes(state = defaultState, action) {
  let currentRouteId,
    allRoutes,
    indexRoute,
    pickupsMap,
    routesMap,
    unnassignedPickups,
    pickupRoutes,
    newPickups;

  switch (action.type) {
    case REQUEST_ROUTES:
      return {
        ...state,
        routesLoaded: false,
        isFetching: true,
        pickupDate: action.query.routeDate,
      };
    case RECEIVE_ROUTES: // This exists in case there are routes with no pickups.
      const receiveRoutesMap = { ...state.routesMap } || {}; // RECEIVE_ROUTES || RECEIVE_ROUTE_PICKUPS can run first

      action.data.forEach((route) => {
        receiveRoutesMap[route.id] = {
          ...route,
          pickups: [],
          pickupsPending: 0,
          pickupsCompleted: 0,
          loadingStatus: false,
        };
      });

      return {
        ...state,
        routesLoaded: true,
        isFetching: false,
        pickupRoutes: action.data.sort(function (route1, route2) {
          return route1.position - route2.position; // Sort by route position from API
        }),
        routesMap: receiveRoutesMap,
        // pickupsLoaded: false,
        // pickups: [], // pickupsIDs in array to keep order
        // pickupsMap: {}, // pickups from date key/value
        // unnassignedPickups: [], // any pickups retrieved that have no routeId
      };
    case REQUEST_ROUTE_PICKUPS_BY_ROUTEID:
      currentRouteId = action.query.routeId;
      allRoutes = state.pickupRoutes;
      indexRoute = allRoutes.findIndex((route) => route.id === currentRouteId);
      allRoutes[indexRoute] = {
        ...allRoutes[indexRoute],
        pickupsFetching: true,
        pickupsLoaded: false,
      };
      return {
        ...state,
        pickupRoutes: allRoutes,
      };
    case REQUEST_ROUTE_PICKUPS:
      return {
        ...state,
        pickupsLoaded: false,
        pickupsFetching: true,
      };
    case RECEIVE_ROUTE_PICKUPS_BY_ROUTEID:
      currentRouteId = action.data[0].routeId;
      newPickups = [...state.pickups] || [];
      allRoutes = state.pickupRoutes;
      indexRoute = allRoutes.findIndex((route) => {
        return route.id === currentRouteId;
      });
      allRoutes[indexRoute] = {
        ...allRoutes[indexRoute],
        pickupsFetching: false,
        pickupsLoaded: true,
      };

      // adds received pickup to newPickups and updates if already exist same pickup
      if (state.pickups.length) {
        action.data.map((actionPickup) => {
          let currentPickupIdx;
          const currentPickup = state.pickups.find((originalPickup, idx) => {
            if (actionPickup.id === originalPickup.id) {
              currentPickupIdx = idx;
              return true;
            }
            return false;
          });
          if (currentPickup) {
            newPickups[currentPickupIdx] = {
              ...currentPickup[currentPickupIdx],
              ...actionPickup,
            };
          } else {
            newPickups.push(actionPickup);
          }
        });
      } else {
        newPickups = action.data;
      }

      return {
        ...state,
        pickupRoutes: allRoutes,
        pickups: newPickups,
        lastUpdated: action.receivedAt,
        pickupStatuses: action.pickupStatuses,
        pickupStatusDescriptions: action.pickupStatusDescriptions,
        pickupSources: action.pickupSources,
      };

    case RECEIVE_ROUTE_PICKUPS:
      pickupsMap = {};
      routesMap = state.routesMap || {};

      // Loop through all pickups retrieved
      action.data.forEach((pickup) => {
        // This pickup
        pickupsMap[pickup.id] = pickup;

        // If the pickup is already assigned to a route
        if (pickup.routeId) {
          // If the routesMap doesn't have an entry for this route
          if (!routesMap[pickup.routeId]) {
            // Add this route to the routesMap ( contains all the routes with an array of pickups)
            routesMap[pickup.routeId] = {
              ...routesMap[pickup.routeId],
              pickups: [],
              pickupsPending: 0,
              pickupsCompleted: 0,
            };
          }

          // If the routeMap has an entry for this route push this pickup in to it (always true)
          routesMap[pickup.routeId].pickups.push(pickup.id);

          // Store count of pickups with each status
          if (pickup.statusId === 1) routesMap[pickup.routeId].pickupsPending++;
          if (pickup.statusId === 2)
            routesMap[pickup.routeId].pickupsCompleted++;
        }
      });
      return {
        ...state,
        pickupsLoaded: true,
        pickupsFetching: false,
        routesMap: { ...state.routesMap, ...routesMap },
        pickups: action.data,
        pickupsMap: pickupsMap,
        lastUpdated: action.receivedAt,
        pickupStatuses: action.pickupStatuses,
        pickupStatusDescriptions: action.pickupStatusDescriptions,
        pickupSources: action.pickupSources,
      };
    // REQUEST_ROUTE_PICKUPS_MINIMAL:
    case REQUEST_ROUTE_PICKUPS_MINIMAL:
      return {
        ...state,
        pickupsMinFetching: true,
        pickupsMinLoaded: false,
      };
    case RECEIVE_ROUTE_PICKUPS_MINIMAL:
      pickupsMap = {};
      routesMap = state.routesMap || {};
      unnassignedPickups = [];

      // Loop through all pickups retrieved
      action.data.forEach((pickup) => {
        // This pickup
        pickupsMap[pickup.id] = pickup;

        // If the pickup is already assigned to a route
        if (pickup.routeId) {
          // If the routesMap doesn't have an entry for this route
          if (!routesMap[pickup.routeId]) {
            // Add this route to the routesMap ( contains all the routes with an array of pickups)
            routesMap[pickup.routeId] = {
              ...routesMap[pickup.routeId],
              pickups: [],
              pickupsPending: 0,
              pickupsCompleted: 0,
            };
          }

          // If the routeMap has an entry for this route push this pickup in to it (always true)
          routesMap[pickup.routeId].pickups.push(pickup.id);

          // Store count of pickups with each status
          if (pickup.statusId === 1) routesMap[pickup.routeId].pickupsPending++;
          if (pickup.statusId === 2)
            routesMap[pickup.routeId].pickupsCompleted++;
        } else {
          // Instead push this pickup in to the unassigned if it has no routeId attached, only important for pending pickups
          if (pickup.statusId === 1) {
            unnassignedPickups.push(pickup);
          }
        }
      });

      return {
        ...state,
        pickupsMinFetching: false,
        pickupsMinLoaded: true,
        unnassignedPickups: unnassignedPickups,
        routesMap: { ...state.routesMap, ...routesMap },
        pickupsMin: action.data,
        pickupsMap: pickupsMap,
        lastUpdated: action.receivedAt,
        pickupStatuses: action.pickupStatuses,
        pickupStatusDescriptions: action.pickupStatusDescriptions,
        pickupSources: action.pickupSources,
      }; //
    case UPDATE_ROUTE_PICKUP_COMPLETED:
      // let newPickupRoutes = [...state.pickupRoutes] || [];
      let newPickupsMap = { ...state.pickupsMap } || {};
      let newPickupsArray = [...state.pickups] || [];
      let updatedPickup = action.data;
      unnassignedPickups = [...state.unnassignedPickups] || [];

      // newPickupsArray = newPickupsArray.find(pickup => pickup.id === updatedPickup.id)
      // Replace the old pickup with the updated one

      // const newUnnassignedPickups = state.unnassignedPickups;

      // Updated the pickup in the new pickupsMap
      newPickupsMap[updatedPickup.id] = updatedPickup;

      // Update the pickup in the pickups array and get the original stored
      let originalPickup;
      originalPickup = state.pickups.find((p, index) => {
        if (p.id === updatedPickup.id) {
          newPickupsArray[index] = updatedPickup;
          return true;
        } else {
          return false;
        }
      });
      if (!originalPickup) {
        originalPickup = state.unnassignedPickups.find((p, index) => {
          if (p.id === updatedPickup.id) {
            unnassignedPickups[index] = {
              ...unnassignedPickups[index],
              ...updatedPickup,
            };
            return true;
          } else {
            return false;
          }
        });
      }
      // html leaves makes this a string, convert to int
      // originalPickup.statusId = +action.data.statusId;
      // originalPickup.route = action.data.route;
      // originalPickup.stopNumber = action.data.stopNumber;

      // newPickupsMap[originalPickup.id] = updatedPickup;

      // Handle reordering and route changing
      if (
        updatedPickup.stopNumber ||
        originalPickup.routeId !== updatedPickup.stopNumber
      ) {
        let newRoutesMap = { ...state.routesMap };

        if (originalPickup.routeId) {
          newPickupsArray = newPickupsArray.map((pickup) => {
            if (
              pickup.routeId === originalPickup.routeId &&
              pickup.stopNumber > originalPickup.stopNumber
            ) {
              pickup = { ...pickup, stopNumber: pickup.stopNumber - 1 };
              newPickupsMap[pickup.id] = pickup;
            }

            return pickup;
          });
          newRoutesMap[originalPickup.routeId].pickups = newRoutesMap[
            originalPickup.routeId
          ].pickups.filter((id) => id !== originalPickup.id);
        }

        if (updatedPickup.routeId) {
          newPickupsArray = newPickupsArray.map((pickup) => {
            if (
              pickup.routeId === updatedPickup.routeId &&
              pickup.stopNumber >= updatedPickup.stopNumber
            ) {
              pickup = { ...pickup, stopNumber: pickup.stopNumber + 1 };
              newPickupsMap[pickup.id] = pickup;
            }

            return pickup;
          });
          newRoutesMap[updatedPickup.routeId].pickups.push(updatedPickup.id);
        }

        newPickupsArray = newPickupsArray.map((pickup) => {
          if (pickup.id === updatedPickup.id) {
            pickup = updatedPickup;
            newPickupsMap[pickup.id] = pickup;
          }

          return pickup;
        });

        return {
          ...state,
          pickups: newPickupsArray,
          routesMap: newRoutesMap,
          pickupsMap: newPickupsMap,
        };
      }

      return {
        ...state,
        unnassignedPickups: unnassignedPickups,
        pickups: newPickupsArray,
        pickupsMap: newPickupsMap,
      };

    // case UPDATE_ROUTE_PICKUP:
    //   return {
    //     ...state,
    //     // routesLoaded: true,
    //     // isFetching: false,
    //     // pickupsLoaded: true,
    //     // pickupsFetching: false,
    //   };

    case UPDATE_ROUTE:
      routesMap = { ...state.routesMap };
      currentRouteId = action.data.id;
      routesMap[currentRouteId] = {
        ...routesMap[currentRouteId],
        loadingStatus: true,
      };
      return {
        ...state,
        routesMap: routesMap,
      };
    case UPDATE_ROUTE_COMPLETED:
      routesMap = { ...state.routesMap } || {};
      pickupRoutes = [...state.pickupRoutes] || [];
      currentRouteId = action.route.id;

      routesMap[currentRouteId] = {
        ...routesMap[currentRouteId],
        loadingStatus: false,
      };
      pickupRoutes.find((route, idx) => {
        if (route.id === currentRouteId) {
          pickupRoutes[idx] = { ...pickupRoutes[idx], ...action.route };
          return true;
        }
        return false;
      });
      return {
        ...state,
        pickupRoutes,
        routesMap,
      };
    case UPDATE_ROUTE_DRIVER:
      const routeId = action.route.id;
      const driver = action.driver;

      const updatedDriverPickupsMap = {};

      // Loop throught all pickups in store
      newPickups = state.pickups.map((pickup) => {
        // if pickup matches this route Id
        if (pickup.routeId === routeId) {
          // Add the route information to the pickup
          const newPickup = {
            ...pickup,
            route: {
              ...pickup.route,
              driver: driver,
              driverId: driver.id,
            },
          };
          updatedDriverPickupsMap[pickup.id] = newPickup;
          return newPickup;
        }

        updatedDriverPickupsMap[pickup.id] = pickup;
        return pickup;
      });

      return {
        ...state,
        pickups: newPickups,
        pickupsMap: updatedDriverPickupsMap,
        loaded: true,
        isFetching: false,
      };
    case UPDATE_ROUTE_FURNITURE:
      const updatedRouteWithFurniture = { ...state.routesMap };

      const pickupsArrayWithFurniture = state.pickupRoutes.map((route) => {
        if (route.id === action.route.id) {
          updatedRouteWithFurniture[route.id] = action.route;
          return action.route;
        } else {
          return route;
        }
      });

      return {
        ...state,
        routesMap: updatedRouteWithFurniture,
        pickupRoutes: pickupsArrayWithFurniture,
      };
    case UPDATE_PICKUP_DATE:
      const pickupDate = action.pickupDate || new Date();
      return {
        ...state,
        pickups: [],
        pickupsMap: {},
        routesMap: {},
        unnassignedPickups: [],
        pickupDate: pickupDate,
      };
    case UPLOAD_ROUTE_CSV:
      return {
        ...state,
        routesLoaded: false,
        isFetching: true,
      };
    case UPLOAD_ROUTE_CSV_COMPLETED:
      return {
        ...state,
        routesLoaded: true,
        isFetching: false,
      };

    case BULK_DELETE_ROUTE:
      return {
        ...state,
        routesLoaded: false,
        isFetching: true,
      };
    case BULK_DELETE_ROUTE_COMPLETED:
      return {
        ...state,
        routesLoaded: true,
        isFetching: false,
      };
    default:
      return state;
  }
}

// function getRoutePickupStatuses(pickups) {
//   let pickupsMap, routesMap;
//
//   pickups.forEach((pickup) => {
//     // This pickup
//     pickupsMap[pickup.id] = pickup;
//
//     // If the pickup is already assigned to a route
//     if (pickup.routeId) {
//       // If the routesMap doesn't have an entry for this route
//       if (!routesMap[pickup.routeId]) {
//         // Add this route to the routesMap ( contains all the routes with an array of pickups)
//         routesMap[pickup.routeId] = {
//           ...pickup.route,
//           pickups: [],
//           pickupsPending: 0,
//           pickupsCompleted: 0,
//         };
//       }
//
//       // If the routeMap has an entry for this route push this pickup in to it (always true)
//       routesMap[pickup.routeId].pickups.push(pickup.id);
//
//       // Store count of pickups with each status
//       if (pickup.statusId === 1) routesMap[pickup.routeId].pickupsPending++;
//       if (pickup.statusId === 2) routesMap[pickup.routeId].pickupsCompleted++;
//     } else {
//       // Instead push this pickup in to the unassigned if it has no routeId attached, only important for pending pickups
//       if (pickup.statusId === 1) {
//         unnassignedPickups.push(pickup);
//       }
//     }
//   });
// }

function routePickupBlocks(
  state = { isFetching: false, loaded: false, routePickupBlocks: [] },
  action
) {
  switch (action.type) {
    case REQUEST_ROUTE_PICKUP_BLOCKS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_ROUTE_PICKUP_BLOCKS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        routePickupBlocks: action.routePickupBlocks,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}
