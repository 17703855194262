import React, { memo, useEffect, useMemo, useState } from "react";
import { ListGroupItem } from "react-bootstrap";
import {
  useTable,
  useFilters,
  useRowSelect,
  usePagination,
  useSortBy,
} from "react-table";
import { Button } from "react-bootstrap";

// import "react-table/react-table.css";
import Loader from "components/loader";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="rt-filter"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const Table = ({
  data: tableData = [],
  columns: tableColumns = [],
  actions = [],
  loading = false,
  getSelected,
  getSelectedFullRow,
  onSelect,
  onSelectAll,
  selected,
  pageSize: pageSizeToSet,
  hidePagination,
  onClickRow,
  ...rest
}) => {
  //must be memo
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      ...tableColumns,
      ...actions.map(({ Header, Component }) => ({
        accessor: Header.toLowerCase(),
        id: Header.toLowerCase(),
        Header,
        Cell: (row) => (
          <div>
            <Component {...row} />
          </div>
        ),
      })),
    ],
    [tableColumns, actions]
  );

  //default otions for columns
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      disableFilters: true,
      // disableSortBy: true,
    }),
    []
  );

  const selectedItems = useMemo(() => selected, [selected]);
  // const pageSizeToSet = useMemo(() => pageSize, [pageSize]);
  const defaultPageSize = 10;

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetHiddenColumns: false, // are required, without it in some tables selecting check box will make a lot of rerenderes
      autoResetSortBy: false, // are required, without it in some tables selecting check box will make a lot of rerenderes
      initialState: { pageSize: defaultPageSize },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const allColumns = [...columns];

        if (getSelected || getSelectedFullRow) {
          allColumns.unshift({
            id: "selection",
            width: "100px",
            Header: ({ getToggleAllRowsSelectedProps, selectedFlatRows }) => {
              const onChange = (e) => {
                getToggleAllRowsSelectedProps().onChange(e);
                onSelectAll && onSelectAll(selectedFlatRows);
              };
              const updatedToggleSelectedProps = {
                ...getToggleAllRowsSelectedProps(),
                onChange,
              };
              return (
                <div>
                  <IndeterminateCheckbox {...updatedToggleSelectedProps} />
                </div>
              );
            },
            Cell: ({ row }) => {
              const onChange = (e) => {
                row.getToggleRowSelectedProps().onChange(e);
                onSelect && onSelect(row);
              };
              const updatedToggleRowSelectedProps = {
                ...row.getToggleRowSelectedProps(),
                onChange,
              };
              return (
                <div>
                  <IndeterminateCheckbox {...updatedToggleRowSelectedProps} />
                </div>
              );
            },
          });
        }

        return allColumns;
      });
    }
  );

  const {
    //main table
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //selecting checkbox
    selectedFlatRows,
    toggleRowSelected,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  //return selected rows
  useEffect(() => {
    if (getSelected) {
      getSelected(selectedFlatRows.map((row) => row.original));
    }
    if (getSelectedFullRow) {
      getSelectedFullRow(selectedFlatRows.map((row) => row));
    }
  }, [selectedFlatRows]);

  //setPageSize
  useEffect(() => {
    if (pageSizeToSet > 0) {
      setPageSize(pageSizeToSet);
    }
  }, [pageSizeToSet]);

  useEffect(() => {
    selectedItems?.length > 0 &&
      selectedItems.map((item) => {
        toggleRowSelected(item.rowId, true);
      });
  }, [selectedItems]);
  const onChangePagination = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(page);
  };

  const handleClickRow = (row) => {
    if (onClickRow) {
      onClickRow(row);
    }
  };

  const Cell = ({ cell, amountTruncatedColumns }) => {
    const [truncate, setTruncate] = useState(null);

    useEffect(() => {
      setTruncate(!!cell.column.truncated);
    }, []);

    const onClickTruncated = () => {
      if (!!cell.column.truncated) {
        setTruncate((prev) => !prev);
      }
    };
    if (!!cell.column.truncated) {
      const widthCell = 30 / (amountTruncatedColumns || 1);
      return (
        <td className="rt-td" {...cell.getCellProps()}>
          <div className="truncated-wrapper">
            <div
              onClick={onClickTruncated}
              style={{ width: `${widthCell}vw` }}
              className={`truncated-box ${truncate ? "active" : "unactive"}`}
            >
              <span className={"text"}>{cell.render("Cell")}</span>
            </div>
          </div>
        </td>
      );
    }

    return (
      <td className="rt-td" {...cell.getCellProps()}>
        <div className={`rt-td-div `}>{cell.render("Cell")}</div>
      </td>
    );
  };

  return (
    //pc table
    <>
      <div className="table-wrapper">
        <table className="ReactTable" {...getTableProps()}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <thead className="rt-thead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="rt-th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className="rt-th-div">
                          <div className="rt-th-text">
                            {column.render("Header")}{" "}
                            <span className={"rt-th-sort"}>
                              {
                                //TODO: update with new icons
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""
                              }
                            </span>
                          </div>
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="rt-tbody" {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row);
                  const rowClassName =
                    idx % 2 != 0 ? "rt-tr rt-tr-odd" : "rt-tr";
                  const amountTruncatedColumns = row.cells.filter((cell) => {
                    if (cell.column.truncated) {
                      return true;
                    }
                    return false;
                  }).length;
                  return (
                    <tr
                      onClick={() => handleClickRow(row)}
                      className={`${rowClassName} ${onClickRow && "clickable"}`}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <Cell
                          amountTruncatedColumns={amountTruncatedColumns}
                          cell={cell}
                        />
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      </div>
      {!loading && !hidePagination && pageOptions.length > 1 && (
        //pagination
        <div className="pagination">
          <div className="left">
            <Button
              disabled={!canPreviousPage}
              size="lg"
              onClick={() => {
                previousPage();
              }}
              variant="light"
            >
              Previous
            </Button>
          </div>
          <div className="center">
            <div className="pages">
              <span>
                Page{" "}
                <span>
                  <input
                    className="page-input"
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageIndex + 1}
                    onChange={onChangePagination}
                  />{" "}
                  of {pageOptions.length}
                </span>
              </span>
            </div>
            <div className="page-size">
              <select
                className="select-page-size"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="right">
            <Button
              size="lg"
              onClick={() => {
                nextPage();
              }}
              variant="light"
              disabled={!canNextPage}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      <div className="mobile-table">
        {data ? (
          data.map((row, idx) => (
            <ListGroupItem key={idx}>
              {columns.map(({ Header, accessor, Cell }, idx) => (
                <div key={idx}>
                  <strong>{Header}: </strong>
                  <span>
                    {Cell ? (
                      <Cell value={row[accessor]} original={row} />
                    ) : (
                      row[accessor]
                    )}
                  </span>
                </div>
              ))}
            </ListGroupItem>
          ))
        ) : (
          <span>No Results</span>
        )}
      </div>
    </>
  );
};

export default memo(Table);
