import {
  SEARCH_CUSTOMERS,
  CLEAR_SEARCH_CUSTOMERS,
  RECEIVE_CUSTOMERS,
  SEARCH_CUSTOMERS_ERROR,
  ADD_SEARCH_TAB,
  REMOVE_SEARCH_TAB,
  SET_ACTIVE_SEARCH_TAB,
  SELECT_USER,
  SET_SEARCH_QUERY,
  HANDLE_TOGGLE_ALL,
  UPDATE_SEARCH_TAB,
  UPDATE_SELECTED_TAB,
} from "actions/donor-search.actions";

import { determineAccountSearchType } from "utils/helpers";

const defaultState = {
  isFetching: false,
  loaded: false,
  isError: false,
  error: undefined,
  searchTabs: [],
  selectedUsers: [],
  searchQuery: {
    searchType: "",
    value: "",
    type: [4, 5],
  },
};

const searchCustomers = (state = defaultState, action) => {
  const { results, loading, tab, selected, query } = action;
  switch (action.type) {
    case SEARCH_CUSTOMERS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_CUSTOMERS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        customers: action.customers,
      };

    case SEARCH_CUSTOMERS_ERROR:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        isError: true,
        error: action.error,
      };
    case UPDATE_SEARCH_TAB:
      const combinedSelected = [];

      const updatedTabs = state.searchTabs.reduce((acc, existingTab, index) => {
        const isExistingTab = existingTab.query === action.query;
        if (isExistingTab) {
          const formattedTab = {
            ...existingTab,
            selected,
          };
          combinedSelected.push(...selected);
          acc.push(formattedTab);
        } else {
          combinedSelected.push(...existingTab.selected);
          acc.push(existingTab);
        }
        return acc;
      }, []);

      return {
        ...state,
        searchTabs: [...updatedTabs],
        selectedUsers: combinedSelected,
      };
    case UPDATE_SELECTED_TAB:
      const { userToDelete, deleteAll } = action;
      if (userToDelete) {
        const filteredSelectedUsers = state.selectedUsers.filter(
          (item) => item.id !== userToDelete.id
        );
        const filteredsearchTabs = state.searchTabs.map((tab) => {
          const filteredSelectedTab = tab.selected.filter(
            (item) => item.id !== userToDelete.id
          );
          return { ...tab, selected: filteredSelectedTab };
        });
        return {
          ...state,
          searchTabs: [...filteredsearchTabs],
          selectedUsers: filteredSelectedUsers,
        };
      }

      if (deleteAll) {
        const updatedSearchTabs = state.searchTabs.map((tab) => {
          return { ...tab, selected: [] };
        });
        return {
          ...state,
          searchTabs: [...updatedSearchTabs],
          selectedUsers: [],
        };
      }

      return {
        ...state,
      };
    case ADD_SEARCH_TAB:
      // resetting all other tabs to inactive except the new one
      if (loading) {
        const formattedTab = {
          ...tab,
          loading: loading,
          results: null,
        };
        return {
          ...state,
          searchTabs: [...state.searchTabs, formattedTab],
        };
      } else {
        const updatedTabs = state.searchTabs.reduce(
          (acc, existingTab, index) => {
            const isExistingTab = existingTab.query === tab.query;
            if (isExistingTab) {
              const formattedTab = {
                ...existingTab,
                active: true,
                loading: loading,
                results: results.data,
                selected,
              };
              acc.push(formattedTab);
            } else {
              existingTab.active = false;
              acc.push(existingTab);
            }
            return acc;
          },
          []
        );
        return {
          ...state,
          loaded: true,
          isFetching: false,
          searchTabs: [...updatedTabs],
        };
      }
    case SET_ACTIVE_SEARCH_TAB:
      const activeTab = action.tab.name;
      // resetting all other tabs to inactive except the new one
      const updatedActiveTabs = state.searchTabs.reduce((acc, tab) => {
        if (tab.name === activeTab) {
          tab.active = true;
          acc.push(tab);
        } else {
          tab.active = false;
          acc.push(tab);
        }
        return acc;
      }, []);
      return {
        ...state,
        searchTabs: updatedActiveTabs,
      };
    case SELECT_USER:
      const { user } = action;
      const existingUser = state.selectedUsers.find(
        (existingUser) => existingUser.id === user.id
      );
      if (existingUser) {
        const updatedSelection = state.selectedUsers.filter(
          (existingUser) => existingUser.id !== user.id
        );
        return {
          ...state,
          selectedUsers: updatedSelection,
        };
      } else {
        return {
          ...state,
          selectedUsers: [...state.selectedUsers, user],
        };
      }
    case REMOVE_SEARCH_TAB:
      const tabToRemove = action.tab.query;
      const existingTabs = state.searchTabs.filter(
        (tab) => tab.query !== tabToRemove
      );
      return {
        ...state,
        searchTabs: existingTabs,
      };
    case SET_SEARCH_QUERY:
      const { query } = action;
      const getQueryType = ({ query }) => {
        if (typeof query !== "string") return "address";

        return determineAccountSearchType(query);
      };
      const queryType = getQueryType({ query });
      return {
        ...state,
        searchQuery: {
          searchType: queryType,
          value: query,
          type: [4, 5],
        },
      };
    case HANDLE_TOGGLE_ALL:
      const { users: usersBeingToggled, isBeingToggledOn } = action;
      const { selectedUsers } = state;
      if (isBeingToggledOn) {
        return {
          ...state,
          selectedUsers: [...new Set([...selectedUsers, ...usersBeingToggled])],
        };
      } else {
        const filteredUsersFromToggle = selectedUsers.reduce((acc, user) => {
          const foundUser = usersBeingToggled.find(
            (toggledUser) => toggledUser.id === user.id
          );
          if (!foundUser) {
            acc.push(user);
          }
          return acc;
        }, []);
        return {
          ...state,
          selectedUsers: filteredUsersFromToggle,
        };
      }
    case CLEAR_SEARCH_CUSTOMERS:
      return defaultState;

    default:
      return state;
  }
};

export default searchCustomers;
