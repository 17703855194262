import { useCallback, useEffect } from "react";
import UserContext from "./context";
import {
  updateUser as APIUpdateUser,
  changeProfileImage as APIChangeProfileImage,
  resetProfileImage as APIResetProfileImage,
  changePassword as APIChangePassword,
  authToken,
} from "api/users";
import useResource from "../useResource";

const Provider = ({
  children,
  user: initialUser,
  initialRequest = false,
  externalUser,
  externalSetUser,
}) => {
  const [user, setUser, userLoading, userError, clearUserError, wrapper] =
    useResource(initialUser, externalUser, externalSetUser);

  useEffect(() => {
    if (initialRequest)
      authToken().then(({ data }) => {
        setUser(data.data[0]);
      });
  }, [initialRequest, setUser]);

  const updateUser = wrapper(
    useCallback(
      async (values) => {
        if (Number.parseInt(values.regionId) === 0) {
          values = { ...values, regionId: null };
        }
        setUser((await APIUpdateUser(user.id, values)).data.data[0]);
      },
      [setUser, user.id]
    )
  );

  const changeProfileImage = wrapper(
    useCallback(
      async (image) => {
        setUser((await APIChangeProfileImage(user.id, image)).data.data[0]);
      },
      [setUser, user.id]
    )
  );

  const resetProfileImage = wrapper(
    useCallback(async () => {
      setUser((await APIResetProfileImage(user.id)).data.data[0]);
    }, [setUser, user.id])
  );

  const changePassword = wrapper(
    useCallback(
      async (password) => {
        setUser((await APIChangePassword(user.id, password)).data.data[0]);
      },
      [setUser, user.id]
    )
  );

  return (
    <UserContext.Provider
      value={{
        user,
        userLoading,
        userError,
        setUser,
        clearUserError,
        updateUser,
        changeProfileImage,
        resetProfileImage,
        changePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default Provider;
