import moment from "moment";

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const passwordValidation = (value) =>
  value &&
  !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(value)
    ? "Password must be minimum six characters, at least one letter, one number and one special character"
    : null;

export const required = (value) => (value ? undefined : "Required");

export const requiredDropdown = (value) =>
  value && value !== "-1" ? undefined : "Required";

export const requiredTime = (value) =>
  value && moment(value, "hh:mm").isValid() ? undefined : "Invalid time";
