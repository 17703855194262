import {
  REQUEST_CUSTOMER_ACTIONS,
  RECEIVE_CUSTOMER_ACTIONS,
  CLEAR_CUSTOMER_ACTIONS,
} from "./customer-timeline.actions";

const defaultCustomerActionState = {
  isFetching: false,
  loaded: false,
  actions: [],
};
function customerActions(state = defaultCustomerActionState, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_ACTIONS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_CUSTOMER_ACTIONS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        actions: action.actions,
        lastUpdated: action.receivedAt,
      };

    case CLEAR_CUSTOMER_ACTIONS:
      return { ...defaultCustomerActionState };

    default:
      return state;
  }
}

export default customerActions;
