import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router";
import { Router, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { REACT_APP_MP_HTML_TITLE, REACT_APP_MP_FAVICON_URL } from "config";
import { Helmet } from "react-helmet";
import WithDashboardData from "hoc/with-dashboard-data";
import WithAuth from "hoc/with-auth";

import history from "./history";

// Routes
import Dashboard from "layouts/dashboard";
import Login from "pages/login";
import ForgotPassword from "pages/forgot-password";

import "styles/main.scss";
import "styles/fonts/lato.scss";

import { loggedIn, requireAuth } from "utils/auth";
import GlobalSettings from "hoc/global-settings";
import ToastWrapper from "components/toasts";
import store from "./store";

const App = () => {
  return (
    <Provider store={store}>
      <Helmet>
        <title>{REACT_APP_MP_HTML_TITLE}</title>
        <link
          rel="shortcut icon"
          href={REACT_APP_MP_FAVICON_URL}
          type="image/x-icon"
        />
      </Helmet>
      <ToastWrapper />
      <GlobalSettings>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/timeout">Your Request Timed Out</Route>
            <Route
              path="/login"
              render={(routeProps) => (
                <WithAuth {...routeProps}>
                  <Login />
                </WithAuth>
              )}
            />
            <Route
              path="/forgotPassword"
              render={(routeProps) => (
                <WithAuth {...routeProps}>
                  <ForgotPassword />
                </WithAuth>
              )}
            />
            <Route
              path="/"
              render={() => {
                // Redirect unauthenticated users to login
                if (!loggedIn()) {
                  return <Redirect to="/login" />;
                }
                return (
                  <Switch>
                    <Route path="/dashboard">
                      <WithDashboardData>
                        <Dashboard />
                      </WithDashboardData>
                    </Route>

                    {/*Redirect unknown paths to '/dashboard'*/}
                    <Route
                      path="*"
                      render={() => {
                        history.push("/dashboard");
                        return null;
                      }}
                    />
                  </Switch>
                );
              }}
            />
          </Switch>
        </ConnectedRouter>
      </GlobalSettings>
    </Provider>
  );
};

export default App;
