import request from "utils/request";
import querystring from "query-string";

export const REQUEST_PICKUP_BLOCKS = "REQUEST_PICKUP_BLOCKS";
export const RECEIVE_PICKUP_BLOCKS = "RECEIVE_PICKUP_BLOCKS";
export const UPDATE_PICKUP_BLOCK = "UPDATE_PICKUP_BLOCK";
export const UPDATE_PICKUP_BLOCK_COMPLETED = "UPDATE_PICKUP_BLOCK_COMPLETED";

// -- pickup blocks -- //
export function requestPickupBlocks(query) {
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUP_BLOCKS });

    return request(
      `/pickupBlocks?${querystring.stringify(query)}`
    ).then((res) => dispatch(receivePickupBlocks(res.data)));
  };
}

export function receivePickupBlocks(data) {
  return {
    type: RECEIVE_PICKUP_BLOCKS,
    pickupBlocks: data.data,
    receivedAt: new Date(),
  };
}

export function updatePickupBlock(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PICKUP_BLOCK, pickupBlock: data });

    return request({
      url: `/pickupBlocks/${data.id}`,
      method: "put",
      data: {
        zoneId: data.zoneId,
        date: data.date,
        stops: data.stops,
        enabled: data.enabled,
      },
    }).then((res) => dispatch(updatePickupBlockCompleted(res.data)));
  };
}

function updatePickupBlockCompleted(data) {
  return {
    type: UPDATE_PICKUP_BLOCK_COMPLETED,
    pickupBlock: data.data[0],
    receivedAt: new Date(),
  };
}
