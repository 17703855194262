import React, { Component } from "react";
import querystring from "query-string";
import history from "../../../history";
import DonorForm from "./donor-search-form.component";
import DonorSearchTabs from "./donor-search-tabs.component";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageLayout from "layouts/pages/page-default";

class DonorSearch extends Component {
  componentDidMount() {
    // Load tabs from the query parameter when
    // the back and forward buttons are used to navigate
    this.historyUnlisten = history.listen(() => {
      this.loadTabsFromQuery();
    });

    this.loadTabsFromQuery();
  }

  loadTabsFromQuery() {
    const { searchCustomers, searchCustomerResults } = this.props;
    const {
      searchQuery: { type },
    } = searchCustomerResults;

    const tabQueries = this.props.searchCustomerResults.searchTabs.map(
      (tab) => tab.query
    );
    const query = !history.location.search
      ? []
      : querystring
          .parse(history.location.search, {
            arrayFormat: "bracket",
          })
          .query.map((tab) => ({
            query: tab,
            ...JSON.parse(tab),
          }));
    const queryTexts = query.map(({ query }) => query);

    // Remove tabs that are not in the query parameter but are in state
    for (const tabQuery of tabQueries.filter(
      (tabQuery) => !queryTexts.includes(tabQuery)
    )) {
      this.props.removeSearchTab({
        tab: this.props.searchCustomerResults.searchTabs.find(
          ({ query }) => query === tabQuery
        ),
      });
    }

    // Get the tabs we don't already have in state so we don't recreate existing tabs
    const newQuery = query.filter(
      (search) => !tabQueries.includes(search.query)
    );

    for (const search of newQuery) {
      if (search.address) {
        searchCustomers(search, type);
      } else {
        searchCustomers(
          { ...search, name: this.normalizeQuery(search.name) },
          type
        );
      }
    }
  }

  normalizeQuery(query) {
    // matches any type of phone number input
    const phoneNumberRegex = RegExp(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
    const isPhone = phoneNumberRegex.test(query);

    // if the query is a phone number, then remove all special characters from the input
    return isPhone ? query.replace(/\D/g, "") : query;
  }

  render() {
    const AddNewButton = (props) => (
      <Link to={"/dashboard/donors/new"}>
        <Button>Add New</Button>
      </Link>
    );

    return (
      <PageLayout title={"Donor Search"} actionComponents={[AddNewButton]}>
        <div className="donor-search">
          <div className={"mb-4"}>
            <DonorForm {...this.props} normalizeQuery={this.normalizeQuery} />
          </div>
          <DonorSearchTabs {...this.props} />
        </div>
      </PageLayout>
    );
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }
}

export default DonorSearch;
