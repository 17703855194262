import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducerFactory } from "reducers/_reducer-factory";
import { connectRouter } from "connected-react-router";

// Single customer page relies on this
// import pickups from "reducers/pickups.reducer";
import locations from "pages/donors/donor-single/donor-locations/donor-locations.reducer";
import PickupRoutes from "reducers/routes.reducer";
import Zones from "reducers/zones.reducer";
import customer from "pages/donors/donor-single/customers.reducer";
import Auth from "reducers/login.reducer";
import Dashboard from "reducers/dashboard.reducer";
import Reports from "reducers/reports.reducer";
// import pickupSources from "reducers/pickup-sources.reducer";
import users from "reducers/users.reducer";
import searchCustomers from "reducers/donor-search.reducer";
import Settings from "reducers/settings.reducer";
import Regions from "reducers/regions.reducer";
import RegionsFiltered from "reducers/regions-filtered.reducer";
import analytics from "reducers/analytics.reducer";
import marketingReports from "reducers/marketing-reports.reducer";
import leadSources from "reducers/lead-sources.reducer";
import customerActions from "pages/donors/donor-single/donor-timeline/customer-timeline.reducer";
import pickups from "pages/donors/donor-single/donor-pickups/pickups.reducer";

export default function createReducer(injectedReducers, history) {
  return combineReducers({
    // pickups,
    ...PickupRoutes,
    ...Zones,

    ...Auth,
    ...Dashboard,
    ...Reports,
    ...Settings,
    ...Regions,
    ...RegionsFiltered,
    // pickupSources,
    pickups,
    locations,
    customerActions,
    customer,
    users,
    leadSources,
    searchCustomers,
    analytics,
    marketingReports,
    form: formReducer,
    donor_search: reducerFactory("DONOR_SEARCH"),
    region_pickup_blocks: reducerFactory("REGION_PICKUP_BLOCKS"),
    user_db: reducerFactory("USERS"),
    pickupStatuses: reducerFactory("PICKUP_STATUSES"),
    pickupSources: reducerFactory("PICKUP_SOURCES"),
    router: connectRouter(history),
    ...injectedReducers,
  });
}
