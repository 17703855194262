import { REACT_APP_MP_OLD_ROUTING, REACT_APP_MP_SINGLE_REGION } from "config";

export default {
  Pickups: [
    { to: "/dashboard", icon: "dashboard", text: "Dashboard" },
    { to: "/dashboard/donors", icon: "search", text: "Search" },
    { to: "/dashboard/donors/new", icon: "addDonor", text: "Add New" },
  ],
  Growth: [
    { to: "/dashboard/analytics", icon: "analytics", text: "Analytics" },
    { to: "/dashboard/campaigns", icon: "campaigns", text: "Campaigns" },
    { to: "/dashboard/exportData", icon: "download", text: "Reports" },
    { to: "/dashboard/lead-sources", icon: "users", text: "Lead Sources" },
  ],
  Logistics: [
    ...(REACT_APP_MP_SINGLE_REGION !== "true"
      ? [{ to: "/dashboard/regions", icon: "regions", text: "Regions" }]
      : [
          { to: "/dashboard/routes", icon: "timeWindow", text: "Routes" },
          { to: "/dashboard/calendar", icon: "timeWindow", text: "Zones" },
          {
            to: "/dashboard/pickup-blocks",
            icon: "timeWindow",
            text: "Pickup Blocks",
          },
          { to: "/dashboard/settings", icon: "timeWindow", text: "Settings" },
        ]),
    ...(REACT_APP_MP_OLD_ROUTING === "true"
      ? [
          {
            to: "/dashboard/time-windows",
            icon: "timeWindow",
            text: "TimeWindows",
          },
        ]
      : []),
  ],
  Admin: [
    { to: "/dashboard/users", icon: "users", text: "Users" },
    { to: "/dashboard/policy", icon: "fileAlt", text: "Policies" },
    {
      to: "/dashboard/notification-templates",
      icon: "bell",
      text: "Notifications",
    },
    { to: "/dashboard/location-types", icon: "map", text: "Location Types" },
  ],
};
