// import request from "utils/request";
// import {
//   REQUEST_PICKUP_SOURCES,
//   RECEIVE_PICKUP_SOURCES,
// } from "../actionsConstants";
//
// export function requestPickupSources() {
//   return (dispatch) => {
//     dispatch({ type: REQUEST_PICKUP_SOURCES });
//
//     request({ url: `/pickupSources` })
//       .then((res) => dispatch(receivePickupSources(res.data)))
//       .catch((res) => console.error(res));
//   };
// }
//
// function receivePickupSources(data = {}) {
//   return {
//     type: RECEIVE_PICKUP_SOURCES,
//     pickupSources: data.data,
//     count: data.count,
//     pagination: data.pagination,
//     receivedAt: Date.now(),
//   };
// }

import { apiModel } from "reducers/_reducer-factory";
import request from "utils/request";

export const get = (query = {}) =>
  request({ url: `/pickupSources`, params: query });
export const update = (values) =>
  request({ url: `/pickupSources/${values.id}`, method: "put", data: values });
export const createUpdate = (values) =>
  values.id
    ? request({
        url: `/pickupSources/${values.id}`,
        method: "put",
        data: values,
      })
    : request({ url: `/pickupSources`, method: "post", data: values });

const userActions = new apiModel({
  name: "PICKUP_SOURCES",
  key: "pickupSources",
  api: { get, update, createUpdate },
});

export default userActions;
