import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";

import NewCustomer from "./donor-new";
import SingleCustomer from "./donor-single";
import DonorSearch from "./donor-search";

class DonorLayoutComponent extends Component {
  handleNewDonorClick = () => {
    this.props.history.push("/dashboard/donors/new");
  };

  render() {
    return (
      <div className="content-wrap">
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            component={DonorSearch}
          />
          <Route
            path={`${this.props.match.path}/new`}
            component={NewCustomer}
          />
          <Route
            path={`${this.props.match.path}/:customerId`}
            component={SingleCustomer}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(DonorLayoutComponent);
