import { toast } from "react-toastify";
export const SHOW_TOAST = "SHOW_TOAST";
export const showToast = ({
  message,
  timeToClose,
  position,
  type,
  noClose,
}) => {
  // position can be one of the following:
  // TOP_RIGHT, TOP-CENTER, TOP-LEFT, BOTTOM-RIGHT, BOTTOM-CENTER, BOTTOM-LEFT
  const toastConfig = {
    autoClose: noClose ? false : timeToClose || 2000,
    position: position ? toast.POSITION[position] : toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    // hideProgressBar: true,
    className: `toast-notification ${type && `toast-notification--${type}`}`,
  };
  toast[type || "success"](message, toastConfig);
};
