import request from "utils/request";
import { addDonor } from "api/users";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_COMPLETED = "CREATE_CUSTOMER_COMPLETED";

export function createCustomer(data = {}) {
  return (dispatch) => {
    dispatch({ type: CREATE_CUSTOMER, data: data });

    return addDonor(data).then((res) =>
      dispatch(createCustomerCompleted(res.data))
    );
  };
}

function createCustomerCompleted(data = {}) {
  return {
    type: CREATE_CUSTOMER_COMPLETED,
    customer: data.data[0],
    receivedAt: Date.now(),
  };
}
