import { connect } from "react-redux";

import { requestSettings, updateSetting } from "actions/settings.actions";

import SMSPrivacyPolicy from "./sms-privacy-policy.component";

const policySettings = {
  "sms-policy": "SMS Policy",
  "privacy-policy": "Privacy Policy",
  "donation-guidelines": "Donation Guidelines",
};
const mapStateToProps = (state) => ({
  policies: state.settings.globalSettings
    .filter((setting) => policySettings[setting.label])
    .map((setting) => ({ ...setting, name: policySettings[setting.label] })),
});

const mapDispatchToProps = { requestSettings, updateSetting };

export default connect(mapStateToProps, mapDispatchToProps)(SMSPrivacyPolicy);
