import { connect } from "react-redux";
import Login from "./login.component";

import withForm from "forms/form-class";
import { authenticateUser } from "actions/login.actions";

let Connected = withForm(Login, "Login", null, null, true, [], "Log In");

const mapDispatchToProps = {
  onSubmit: authenticateUser,
};

export default connect(null, mapDispatchToProps)(Connected);
