import request from "utils/request";
import createNotesAPI from "utils/createNotesAPI";

export const createPickup = (values) =>
  request({ url: `/pickups`, data: values, method: "post" });

export const uploadPickupImage = (pickup, image, categoryId) => {
  const data = new FormData();
  data.append("image", image);

  return request({
    url: `/pickups/${pickup.id}/images?${
      categoryId ? `categoryId=${categoryId}` : ""
    }`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
  });
};

export const deletePickupImage = async (pickup, image) =>
  await request({
    url: `/pickups/${pickup.id}/images/${image.id}`,
    method: "delete",
  });

export const getPickupImages = async (pickup) =>
  (
    await request({
      url: `/pickups/${pickup.id}/images`,
    })
  ).data.data;

export const getPickupImageCategories = async () =>
  (await request({ url: "/pickups/imageCategories" })).data.data;

export const getPickups = async (params) =>
  await request({ url: "/pickups", params });

export const postPickup = async (data) =>
  await request({ url: "/pickups", method: "post", data });

export const putPickup = async (id, data) =>
  await request({ url: `/pickups/${id}`, method: "put", data });

export const pickupsNotesAPI = createNotesAPI((id) => `/pickups/${id}/notes`);
