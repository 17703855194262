import { useCallback } from "react";

import PickupTypesContext from "./context";
import { getPickupTypes } from "api/pickupTypes";
import useResource from "../useResource";

const PickupTypesProvider = ({ children }) => {
  const [
    pickupTypes,
    setPickupTypes,
    pickupTypesLoading,
    pickupTypesError,
    clearPickupTypesError,
    wrapper,
  ] = useResource([]);

  const getTypes = wrapper(
    useCallback(() => getPickupTypes().then(setPickupTypes), [setPickupTypes])
  );

  return (
    <PickupTypesContext.Provider
      value={{
        pickupTypes,
        pickupTypesLoading,
        pickupTypesError,
        getPickupTypes: getTypes,
        clearPickupTypesError,
      }}
    >
      {children}
    </PickupTypesContext.Provider>
  );
};

export default PickupTypesProvider;
