import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Icons from "../icon";
import { getNotifications } from "api/users";

const InternalNotifications = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    getNotifications(userId).then(({ data }) => setNotifications(data.data));
  }, [userId]);

  return (
    <Dropdown id="top-nav-header-notifications">
      <Dropdown.Toggle
        as={React.forwardRef(({ onClick }, ref) => (
          <div
            ref={ref}
            onClick={onClick}
            id="top-nav-header-notifications-toggle"
          >
            <Icons type="bell" />
          </div>
        ))}
      />
      <Dropdown.Menu>
        {notifications.length !== 0 ? (
          notifications.map((notification) => (
            <Dropdown.Item key={notification.id}>
              {notification.text}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item>No notifications</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InternalNotifications;
