import request from "utils/request";
import querystring from "query-string";

export const REQUEST_ZONES = "REQUEST_ZONES";
export const RECEIVE_ZONES = "RECEIVE_ZONES";

export const CREATE_ZONE = "CREATE_ZONE";
export const CREATE_ZONE_COMPLETED = "CREATE_ZONE_COMPLETED";

export const UPDATE_ZONE = "UPDATE_ZONE";
export const UPDATE_ZONE_COMPLETED = "UPDATE_ZONE_COMPLETED";

export const DELETE_ZONE = "DELETE_ZONE";
export const DELETE_ZONE_COMPLETED = "DELETE_ZONE_COMPLETED";

export const REQUEST_PICKUP_BLOCKS = "REQUEST_PICKUP_BLOCKS";
export const RECEIVE_PICKUP_BLOCKS = "RECEIVE_PICKUP_BLOCKS";
export const CLEAR_PICKUP_BLOCKS = "CLEAR_PICKUP_BLOCKS";

export const CREATE_PICKUP_BLOCK = "CREATE_PICKUP_BLOCK";
export const CREATE_PICKUP_BLOCK_COMPLETED = "CREATE_PICKUP_BLOCK_COMPLETED";

export const UPDATE_PICKUP_BLOCK = "UPDATE_PICKUP_BLOCK";
export const UPDATE_PICKUP_BLOCK_COMPLETED = "UPDATE_PICKUP_BLOCK_COMPLETED";

export const DELETE_PICKUP_BLOCK = "DELETE_PICKUP_BLOCK";
export const DELETE_PICKUP_BLOCK_COMPLETED = "DELETE_PICKUP_BLOCK_COMPLETED";

export function requestZones({ regionId }) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ZONES });
    return request(`/zones?${querystring.stringify({ regionId })}`).then(
      (res) => dispatch(receiveZones(res.data))
    );
  };
}

export function receiveZones(data) {
  return {
    type: RECEIVE_ZONES,
    zones: data.data,
    receivedAt: new Date(),
  };
}

export function createZone(data) {
  return (dispatch) => {
    dispatch({ type: CREATE_ZONE });

    return request({
      url: "/zones",
      method: "post",
      data: data,
    }).then((res) => dispatch(createZoneCompleted(res.data)));
  };
}

function createZoneCompleted(data) {
  return {
    type: CREATE_ZONE_COMPLETED,
    zone: data.data[0],
    receivedAt: new Date(),
  };
}

export function updateZone(zoneId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ZONE });

    return request({
      url: `/zones/${zoneId}`,
      method: "put",
      data: data,
    }).then((res) => dispatch(updateZoneCompleted(res.data)));
  };
}

function updateZoneCompleted(data) {
  return {
    type: UPDATE_ZONE_COMPLETED,
    zone: data.data[0],
    receivedAt: new Date(),
  };
}

export function deleteZone(zoneId, data) {
  return (dispatch) => {
    dispatch({ type: DELETE_ZONE });

    return request({
      url: `/zones/${zoneId}`,
      method: "put",
      data: { ...data, disabled: true },
    }).then((res) => dispatch(deleteZoneCompleted(res.data)));
  };
}

function deleteZoneCompleted(data) {
  return {
    type: DELETE_ZONE_COMPLETED,
    zone: data.data[0],
    receivedAt: new Date(),
  };
}

// -- pickup blocks -- //
export function requestPickupBlocks(query) {
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUP_BLOCKS });

    return request(`/pickupBlocks/?${querystring.stringify(query)}`).then(
      (res) => dispatch(receivePickupBlocks(res.data))
    );
  };
}

export function receivePickupBlocks(data) {
  return {
    type: RECEIVE_PICKUP_BLOCKS,
    pickupBlocks: data.data,
    receivedAt: new Date(),
  };
}

export function createPickupBlock(data) {
  return (dispatch) => {
    dispatch({ type: CREATE_PICKUP_BLOCK });

    return request({
      url: "/pickupBlocks",
      method: "post",
      data: {
        pickupBlocks: data,
      },
    }).then((res) => dispatch(createPickupBlockCompleted(res.data)));
  };
}

function createPickupBlockCompleted(data) {
  return {
    type: CREATE_PICKUP_BLOCK_COMPLETED,
    pickupBlock: data.data,
    receivedAt: new Date(),
  };
}

export function updatePickupBlock(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PICKUP_BLOCK, pickupBlock: data });

    return request({
      url: `/pickupBlocks/${data.id}`,
      method: "put",
      data: { zoneId: data.zoneId, date: data.date, stops: data.stops },
    }).then((res) => dispatch(updatePickupBlockCompleted(res.data)));
  };
}

function updatePickupBlockCompleted(data) {
  return {
    type: UPDATE_PICKUP_BLOCK_COMPLETED,
    pickupBlock: data.data[0],
    receivedAt: new Date(),
  };
}

export function deletePickupBlock(data) {
  return (dispatch) => {
    dispatch({ type: DELETE_PICKUP_BLOCK, pickupBlockId: data.id });

    return request({
      url: `/pickupBlocks/${data.id}`,
      method: "delete",
    }).then((res) => dispatch(deletePickupBlockCompleted(res.data)));
  };
}

function deletePickupBlockCompleted(data) {
  return {
    type: DELETE_PICKUP_BLOCK_COMPLETED,
    // pickupBlock: data.pickupBlock,
    receivedAt: new Date(),
  };
}
