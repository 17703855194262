import { connect } from "react-redux";
import {
  requestCustomerActions,
  clearCustomerActions,
} from "./customer-timeline.actions";

import DonorTimeline from "./customer-timeline-new.component";

function mapStateToProps(state) {
  return {
    customerActions: {
      ...state.customerActions,
    },
    isFetching: state.customerActions.isFetching,
    isLoaded: state.customerActions.loaded,
  };
}

export default connect(mapStateToProps, {
  requestCustomerActions,
  clearCustomerActions,
})(DonorTimeline);
