import { connect } from "react-redux";
import { loadUser } from "actions/dashboard.actions";
import { logout } from "actions/login.actions";
import WithAuth from "./with-auth.component";

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
  user: {
    ...(state.auth.credentials || {}),
  },
});

const mapDispatchToProps = {
  loadUser,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth);
