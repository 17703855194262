import React from "react";

import { Col, Row, ProgressBar, Button } from "react-bootstrap";

var percentColors = [
  { pct: 0.0, color: { r: 67, g: 214, b: 67 } },
  { pct: 0.9, color: { r: 240, g: 236, b: 78 } },
  { pct: 1.0, color: { r: 217, g: 83, b: 79 } },
];

var getColorForPercentage = function (pct) {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;

  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;

  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  // or output as hex if preferred
};

const CountPlusMinusWithProgressBar = (props) => {
  const {
    title,
    onPlus,
    onMinus,
    count,
    maximum,
    enabled,
    onToggleDisabled,
    id,
  } = props;
  const progress = (count / maximum) * 100;

  const style = {};

  return (
    <div className={"count-with-progress"}>
      <div>
        {title} ({maximum})
      </div>
      <br />
      <div>
        <ProgressBar
          // color={getColorForPercentage(progress * 0.01)}
          className={"count-progress"}
          now={progress || 0}
        />
      </div>

      <Row noGutters>
        <Col>
          Open
          <div className={"count-container"}>
            <Button
              variant={"light"}
              size={"md"}
              className={"count-button"}
              onClick={() => onMinus()}
            >
              -
            </Button>
            <div className={"count"}>{maximum - count}</div>
            <Button
              variant={"light"}
              size={"md"}
              className={"count-button"}
              onClick={() => onPlus()}
            >
              +
            </Button>
          </div>
        </Col>
        <Col>
          Filled
          <div className={"display-count"}>
            {count} of {maximum}
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          Scheduling {enabled ? "Enabled" : "Disabled"}
          <div className="checkbox-input-group">
            <label className="switch" htmlFor={`enabled-${id}`}>
              <input
                type="checkbox"
                id={`enabled-${id}`}
                checked={enabled}
                onChange={onToggleDisabled}
              />
              <div className="slider round" />
            </label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CountPlusMinusWithProgressBar;
