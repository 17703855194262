import request from "utils/request";

export const REQUEST_LOCATIONS = "REQUEST_LOCATIONS";
export const RECEIVE_LOCATIONS = "RECEIVE_LOCATIONS";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_COMPLETED = "CREATE_LOCATION_COMPLETED";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_COMPLETED = "UPDATE_LOCATION_COMPLETED";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";

export function requestLocations(query = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_LOCATIONS, query });
    request(`/locations`, { params: query }).then((res) =>
      dispatch(receiveLocations(res.data))
    );
  };
}

export function receiveLocations(data = {}) {
  return {
    type: RECEIVE_LOCATIONS,
    data: data.data,
    receivedAt: Date.now(),
  };
}

export function clearLocations() {
  return {
    type: CLEAR_LOCATIONS,
  };
}

export function createLocation(data = {}) {
  return (dispatch) => {
    dispatch({ type: CREATE_LOCATION, data: data });

    return request({
      url: "/locations",
      method: "post",
      data: data,
    }).then((res) => dispatch(createLocationCompleted(res.data)));
  };
}

function createLocationCompleted(data = {}) {
  return {
    type: CREATE_LOCATION_COMPLETED,
    location: data.data[0],
    receivedAt: Date.now(),
  };
}

export function updateLocation(data = {}) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOCATION, data: data });

    return request({
      url: `/locations/${data.id}`,
      method: "put",
      data: data,
    }).then((res) => dispatch(updateLocationCompleted(res.data)));
  };
}

function updateLocationCompleted(data) {
  return {
    type: UPDATE_LOCATION_COMPLETED,
    location: data.data[0],
    receivedAt: Date.now(),
  };
}
