import React, { Component } from "react";
import PickupBlocks from "../../pickup-blocks";

class RegionPickupBlocks extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeRegion } = this.props;

    return <PickupBlocks regionId={activeRegion.region.id} />;
  }
}

export default RegionPickupBlocks; 
