import { getCustomerActions } from "api/users";

export const REQUEST_CUSTOMER_ACTIONS = "REQUEST_CUSTOMER_ACTIONS";
export const RECEIVE_CUSTOMER_ACTIONS = "RECEIVE_CUSTOMER_ACTIONS";
export const CLEAR_CUSTOMER_ACTIONS = "CLEAR_CUSTOMER_ACTIONS";

// User Actions
export function requestCustomerActions(userId) {
  return (dispatch) => {
    dispatch({ type: REQUEST_CUSTOMER_ACTIONS, data: { customerId: userId } });
    getCustomerActions(userId)
      .then((res) => dispatch(receiveCustomerActions(res.data)))
      .catch((err) => alert(JSON.stringify(err.response.data)));
  };
}

function receiveCustomerActions(data = {}) {
  return {
    type: RECEIVE_CUSTOMER_ACTIONS,
    actions: data.data,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}

export function clearCustomerActions() {
  return {
    type: CLEAR_CUSTOMER_ACTIONS,
  };
}
