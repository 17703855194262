import { withLeaflet, MapControl } from "react-leaflet";
import LeafletSearch from "leaflet-search";

class SearchControl extends MapControl {
  createLeafletElement(props) {
    this.data = {};

    // Create search leaflet element
    return new LeafletSearch({
      sourceData: () => this.data,
      layer: props.geoJSONLayer,
      propertyName: "ZCTA5CE10",
      initial: false,
      zoom: 12,
      collapsed: false,
      textPlaceholder: "Ex. 78721",
      minLength: 1,
      autoType: true,
      firstTipSubmit: true,
      tipAutoSubmit: true,
    });
  }

  updateLeafletElement(fromProps, toProps) {
    if (fromProps.zipCodeList !== toProps.zipCodeList)
      for (const zipCode of toProps.zipCodeList)
        this.data[zipCode.zipCode] = [zipCode.lng, zipCode.lat];
  }
}

export default withLeaflet(SearchControl);
