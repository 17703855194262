import { connect } from "react-redux";
import CreateUpdateDonor from "./create-update-donor.component";
import withForm from "forms/form-class";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  return {
    leadSourceOptions: {
      ...state.leadSources,
    },

    pickupStatuses: {
      ...state.pickupStatuses,
    },

    pickupSources: {
      ...state.pickupSources,
    },

    editing: !!ownProps.initialValues?.id,

    onSubmit: (values) => {
      if (values.leadSourceId === "-1") delete values.leadSourceId;

      return ownProps.onSubmit(values);
    },
  };
};

// Connect Redux Form
let FormConnected = withForm(CreateUpdateDonor, "NewDonor");

// Connect Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(FormConnected);
