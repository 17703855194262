import React from "react";

import {
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  FormCheck,
} from "react-bootstrap";

import Box from "components/box";

import RouteAssignPickupButton from "../route-assign-pickup-button";
import RouteEditPickup from "../route-edit-pickup";
import RoutePickup from "pages/regions/routes/route-pickup";

const renderUnassignedPickupsSection = ({
  unassignedPickups,
  showUnassignedOnMap,
  setShowUnassignedOnMap,
  routes,
}) => {
  return (
    <Box>
      {routes.length !== 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span style={{ display: "flex" }}>
            Show on map:{" "}
            <FormCheck
              style={{ marginLeft: 5 }}
              checked={showUnassignedOnMap}
              onChange={(e) => setShowUnassignedOnMap(e.target.checked)}
            />
          </span>
        </div>
      )}
      <h3 style={{ textAlign: "center" }}>Need Routes</h3>
      <ListGroup id="unassignedPickups">
        {unassignedPickups.map((pickup, index) => {
          return (
            <ListGroupItem
              className="clearfix"
              key={pickup.id}
              data-id={pickup.id}
              id={pickup.id}
            >
              <RoutePickup pickup={pickup} index={index} color={"#000"} />
              <ButtonGroup className="float-right">
                <RouteEditPickup pickup={pickup} />
                {pickup.statusId === 1 && routes.length > 0 ? (
                  <RouteAssignPickupButton label={"Assign"} pickup={pickup} />
                ) : null}
              </ButtonGroup>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </Box>
  );
};

export default renderUnassignedPickupsSection;
