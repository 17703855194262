import React from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

const DateRangeChart = (props) => {
  DateRangeChart.propTypes = {
    dataset: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  };

  const { dataset, labels } = props;

  return (
    <Line
      data={{
        datasets: dataset,
        labels,
      }}
      options={{
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 10,
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      }}
    />
  );
};

export default DateRangeChart;
