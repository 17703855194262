import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { Table, RowActions, checkComponentOriginProp } from "components/table";
import Tabs from "components/tabs";

import PageLayout from "layouts/pages/page-default";

const Regions = ({
  regions,
  disabledRegions: receivedDisabledRegions,
  loaded,
  history,
}) => {
  const [showEnabled, setShowEnabled] = useState(true);
  const [enabledRegions, setEnabledRegions] = useState([]);
  const [disabledRegions, setDisabledRegions] = useState([]);

  useEffect(() => {
    setEnabledRegions(regions);
  }, [regions]);

  useEffect(() => {
    setDisabledRegions(receivedDisabledRegions);
  }, [receivedDisabledRegions]);

  const sortByLabel = (a, b) => {
    if (a.label < b.label) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.label > b.label) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  };

  const AddRegion = (props) => (
    <Link to={"/dashboard/regions/new"}>
      <Button variant={"primary"}>Add New</Button>
    </Link>
  );

  const goToRegionRouteHandler = (id, route) => (e) => {
    e.stopPropagation();
    history.push(`regions/${id}/${route || "routes"}`);
  };
  const goToRegionRouteHandlerRow = (id, route) => {
    history.push(`regions/${id}/${route || "routes"}`);
  };

  return (
    <PageLayout title={"Regions"} actionComponents={[AddRegion]}>
      <Tabs active={showEnabled} onChange={setShowEnabled}>
        <Tabs.Tab value={true}>Enabled</Tabs.Tab>
        <Tabs.Tab value={false}>Disabled</Tabs.Tab>
      </Tabs>
      <Table
        loading={!loaded}
        columns={[
          {
            Header: "Title",
            accessor: "label",
            Cell: (row) =>
              checkComponentOriginProp(row, (original) => (
                <span>{original.label}</span>
              )),
          },
          {
            Header: "Zones",
            accessor: "zoneCount",
            Cell: (row) =>
              checkComponentOriginProp(row, (original) => (
                <Badge variant={"secondary"}>{original.zoneCount}</Badge>
              )),
          },
          {
            Header: "Zip Codes",
            accessor: "zipCount",
            Cell: (row) =>
              checkComponentOriginProp(row, (original) => (
                <Badge variant={"secondary"}>{original.zipCount}</Badge>
              )),
          },
          {
            Header: "Drivers",
            accessor: "driverCount",
            Cell: (row) =>
              checkComponentOriginProp(row, (original) => (
                <Badge variant={"secondary"}>{original.driverCount}</Badge>
              )),
          },
        ]}
        actions={[
          {
            Header: "Shortcuts",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.id}
                  actions={[
                    {
                      text: "Routes",
                      icon: "truck",
                      onClick: goToRegionRouteHandler(original.id, "routes"),
                    },
                    {
                      text: "Map",
                      icon: "map",
                      onClick: goToRegionRouteHandler(original.id, "map"),
                    },
                    {
                      text: "Calendar",
                      icon: "calendar",
                      onClick: goToRegionRouteHandler(original.id, "zones"),
                    },
                    {
                      text: "Blocks",
                      icon: "mapSigns",
                      onClick: goToRegionRouteHandler(
                        original.id,
                        "pickup-blocks"
                      ),
                    },
                    {
                      text: "Settings",
                      icon: "cog",
                      onClick: goToRegionRouteHandler(original.id, "settings"),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={(showEnabled ? enabledRegions : disabledRegions).sort(
          sortByLabel
        )}
        getTdProps={(state, { original: { id } }) => ({
          onClick: goToRegionRouteHandler(id),
        })}
        onClickRow={({ original: { id } }) => {
          goToRegionRouteHandlerRow(id);
        }}
      />
    </PageLayout>
  );
};

export default Regions;
