import React, { useCallback, useContext } from "react";
import { FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import { UserContext } from "contexts/user";

const NotificationSettings = () => {
  const { user, updateUser } = useContext(UserContext);

  const setNotificationType = useCallback(
    async (type, value) => {
      await updateUser({
        ...user,
        pickupNotifications: { ...user.pickupNotifications, [type]: value },
      });
    },
    [updateUser, user.pickupNotifications]
  );

  return (
    <>
      <FormGroup>
        <FormLabel>Pickup Notifications</FormLabel>
        <FormCheck
          label="Email"
          id="allow-email-notifications"
          checked={user.pickupNotifications.email}
          onChange={(e) => setNotificationType("email", e.target.checked)}
        />
        <FormCheck
          label="SMS"
          id="allow-sms-notifications"
          checked={user.pickupNotifications.text}
          onChange={(e) => setNotificationType("text", e.target.checked)}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="allowMarketingCalls">Marketing Calls Allowed</label>
        <FormCheck
          label="Yes"
          id="allow-marketing-calls"
          checked={user.allowMarketingCalls}
          onChange={(e) =>
            updateUser({ ...user, allowMarketingCalls: e.target.checked })
          }
        />
      </FormGroup>
    </>
  );
};

export default NotificationSettings;
