import { connect } from "react-redux";

import { requestLeadSources } from "actions/lead-sources.actions";
import { createCustomer } from "./customers.new.actions";

import NewCustomerComponent from "./customers.new.component";

const mapStateToProps = (state) => ({
  leadSourceOptions: {
    ...state.leadSources,
  },
});

const mapDispatchToProps = {
  requestLeadSources,
  createCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCustomerComponent);
