import {
  REQUEST_MARKETING_REPORTS,
  RECEIVE_MARKETING_REPORTS_SUCCESS,
  RECEIVE_MARKETING_REPORTS_ERROR,
  CREATE_MARKETING_REPORT,
  CREATE_MARKETING_REPORT_COMPLETED,
  CREATE_MARKETING_REPORT_ERROR,
  UPDATE_MARKETING_REPORT,
  UPDATE_MARKETING_REPORT_COMPLETED,
  UPDATE_MARKETING_REPORT_ERROR,
  DELETE_MARKETING_REPORT,
  DELETE_MARKETING_REPORT_COMPLETED,
  DELETE_MARKETING_REPORT_ERROR,
} from "actions/marketing-reports.actions";

const defaultState = {
  isFetching: false,
  loading: false,
  marketingReports: [],
  error: null,
};

const marketingReportsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_MARKETING_REPORTS:
    case CREATE_MARKETING_REPORT:
    case UPDATE_MARKETING_REPORT:
    case DELETE_MARKETING_REPORT:
      return {
        ...state,
        loaded: false,
        isFetching: true,
        error: null,
      };
    case RECEIVE_MARKETING_REPORTS_SUCCESS:
      return {
        ...state,
        marketingReports: action.marketingReports,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: null,
      };
    case CREATE_MARKETING_REPORT_COMPLETED:
      return {
        ...state,
        marketingReports: state.marketingReports.concat([
          action.marketingReport,
        ]),
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: null,
      };
    case UPDATE_MARKETING_REPORT_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        marketingReports: state.marketingReports.map((report) => {
          if (report.id === action.marketingReport.id) {
            return action.marketingReport;
          }
          return report;
        }),
        lastUpdated: action.receivedAt,
      };
    case DELETE_MARKETING_REPORT_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        lastUpdated: action.receivedAt,
        marketingReports: state.marketingReports.filter((report) => {
          return report.id !== action.marketingReportId;
        }),
      };
    case RECEIVE_MARKETING_REPORTS_ERROR:
    case CREATE_MARKETING_REPORT_ERROR:
    case UPDATE_MARKETING_REPORT_ERROR:
    case DELETE_MARKETING_REPORT_ERROR:
      return {
        ...state,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default marketingReportsReducer;
