import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Icon from "components/icon";
// linkTo is so that we can link to the same tab, when using the region dropdown you switch region but remain on "routes", "calendar", "settings", etc

const CustomToggle = () => {
  return <div>Toggle</div>;
};
const RegionDropdown = ({ regions, label, linkTo }) => {
  const [isOpen, toggleOpen] = useState(false);

  const defaultHeader = "Regions";

  // Make alphabetical
  function sortByLabel(a, b) {
    if (a.label < b.label) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.label > b.label) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={{ fontSize: "2rem", cursor: "pointer" }}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span style={{ fontSize: ".75rem" }}>{"  "}&#x25bc;</span>
    </span>
  ));

  if (
    regions.regions.length === 1 ||
    regions.regions.filter((region) => !region.disabled).length === 1
  ) {
    return <h1>{label}</h1>;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {regions.regions.sort(sortByLabel).map((region) => {
          if (region.disabled) return null;
          const { label, id } = region;
          // link to accepts a string and converts it to a template literal to interpret variables
          return (
            <Link
              className="dropdown-link dropdown-item"
              onClick={() => {
                toggleOpen(false); // close when selected
              }}
              to={eval("`" + linkTo + "`")}
            >
              {label}
            </Link>
          );
        })}
        {/*<Button className={'float-right'} style={{marginTop: 20}} onClick={this.handleNewRegionClick}>Add New</Button>*/}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RegionDropdown;
