/*
 * The purpose of this class is to consolidate code among the forms
 *
 * All forms within the app are based on the same form library, we may only use specific parts of that library and
 * need to ensure that all of our forms are consistent with one another.
 *
 * We also want a way to minimize code duplication by storing functionality in a singular location where possible
 *
 * Possible targets for functionality are
 * * Buttons (save/cancel)
 * * Errors
 *
 *
 *
 * */
import Loader from "components/loader";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";

import RenderIf from "hoc/render-if";
import { reduxForm } from "redux-form";

const ActionButton = ({ label, action, props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      onClick={async () => {
        setLoading(true);
        await action(props);
        setLoading(false);
      }}
      disabled={loading}
    >
      {loading ? <Loader width={30} /> : label}
    </Button>
  );
};

export default function withForm(
  FieldsComponent,
  formName,
  validation,
  changed,
  inModal = true,
  actions = [],
  submitText = "Save",
  allowPristine
) {
  FieldsComponent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  const validate = validation ? validation : null;
  const onChange = changed ? changed : null;

  const WrappedForm = class extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {
        error,
        pristine,
        submitting,
        handleSubmit,
        onCancel,
        actionButtons = [],
      } = this.props;
      return (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <FieldsComponent {...this.props} />
            <div className={"form-footer"}>
              <div className="error">{error}</div>

              <div>
                <RenderIf condition={!!onCancel}>
                  <Button variant="link" onClick={onCancel}>
                    Cancel
                  </Button>
                </RenderIf>

                {actions.map((action, idx) => (
                  <ActionButton {...action} props={this.props} key={idx} />
                ))}

                {actionButtons.map((action, idx) => (
                  <ActionButton {...action} props={this.props} key={idx} />
                ))}
                <Button
                  type="submit"
                  disabled={submitting || (!allowPristine && pristine)}
                  variant="primary"
                >
                  {submitting ? <Loader color="#fff" width={30} /> : submitText}
                </Button>
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    }
  };

  return reduxForm({ form: formName, validate: validate, onChange: onChange })(
    WrappedForm
  );
}
