import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import admin from "./admin";
import agent from "./agent";
import driver from "./driver";
import Icons from "components/icon";

import { REACT_APP_MP_PRIMARY_COLOR } from "config";

export const NavHeader = ({ children }) => (
  <div className="sidebar-header">{children}</div>
);
//TODO: Remove inline style
export const NavLink = ({ to, icon, children, ...rest }) => (
  <LinkContainer to={to} exact={true} className="sidebar-nav-item" {...rest}>
    <a className="sidebar-nav-item nav-item">
      <div>
        <span
          style={{ marginRight: ".5rem", color: REACT_APP_MP_PRIMARY_COLOR }}
        >
          <Icons type={icon} />
        </span>
        {children}
      </div>
    </a>
  </LinkContainer>
);

const AdminNavigation = ({ userTypeId }) => (
  <div id="sidebar">
    {userTypeId
      ? Object.entries({ 1: admin, 2: agent, 3: driver }[userTypeId]).map(
          ([heading, items]) => (
            <>
              <NavHeader>{heading}</NavHeader>
              {items.map((item) => (
                <NavLink to={item.to} icon={item.icon}>
                  {item.text}
                </NavLink>
              ))}
            </>
          )
        )
      : null}
  </div>
);

export default AdminNavigation;
