import { useEffect, useState } from "react";
import { getNotificationTemplatePreview } from "api/notification-templates";
import WithLoader from "hoc/with-loader";

const SMSPreview = ({ smsPreview }) => {
  const [loading, setLoading] = useState(false);
  const [smsPreviewText, setSmsPreviewText] = useState("");

  // Get the preview SMS text when preview changes
  useEffect(() => {
    if (smsPreview) {
      setLoading(true);
      getNotificationTemplatePreview(smsPreview, "sms")
        .then(setSmsPreviewText)
        .then(() => setLoading(false));
    }
  }, [smsPreview]);

  return (
    <WithLoader isLoading={loading}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <div className="imessage">
          <p className="from-them"> {smsPreviewText}</p>
        </div>
      </div>
    </WithLoader>
  );
};

export default SMSPreview;
