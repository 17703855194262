import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import querystring from "query-string";
import history from "../../../history";
import Icon from "components/icon";
import PlacesAutocompleteField from "forms/PlacesAutocompleteField";
import { setSearchQuery } from "actions/donor-search.actions";

class DonorSearchForm extends Component {
  constructor() {
    super();

    this.state = {
      addressKey: 0,
      searchType: "account",
    };
  }

  clearAddress = () => {
    // This is a bit of a hack to clear the address input
    this.setState({ addressKey: this.state.addressKey + 1 });
  };

  // Todo, move to connect function
  searchChanged = (e) => {
    this.clearAddress();

    const { setSearchQuery, normalizeQuery } = this.props;
    const query = e.target.value;

    setSearchQuery({ query: normalizeQuery(query) });
  };

  // Todo, move to connect function
  searchSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.clearAddress();

    const {
      searchCustomers,
      searchCustomerResults: { searchQuery },
      setSearchQuery,
      openTabs,
    } = this.props;
    const { searchType, value, type } = searchQuery;
    // hard coding the limit of users returned from the server to be 1000
    // we will want to properly handle pagination in the future
    const SEARCH_LIMIT = 1000;

    // Don't submit of the field is empty
    if (!value) {
      return;
    }

    // Update the query parameter
    history.push(
      history.location.pathname +
        "?" +
        querystring.stringify(
          {
            query: [
              ...openTabs.map((tab) => tab.query),
              JSON.stringify({
                type: this.state.searchType,
                name:
                  this.state.searchType !== "address"
                    ? value
                    : value.mapAddress,
              }),
            ],
          },
          { arrayFormat: "bracket" }
        )
    );
  };

  render() {
    const {
      searchCustomerResults: { searchQuery },
    } = this.props;
    //TODO: Move this to the form directory
    return (
      <Form onSubmit={this.searchSubmit}>
        <Form.Row>
          <InputGroup className="mb-4">
            {this.state.searchType === "address" ? (
              <PlacesAutocompleteField
                meta={{}}
                onPlaceSelected={(query) => {
                  this.props.setSearchQuery({ query });
                  this.searchSubmit();
                }}
                input={{
                  key: this.state.addressKey,
                  className: "form-control donor-search-form_input",
                  autoFocus: true,
                }}
                fieldOnly={true}
              />
            ) : (
              <>
                <FormControl
                  className="donor-search-form_input"
                  type="text"
                  value={
                    typeof searchQuery.value === "string"
                      ? searchQuery.value
                      : ""
                  }
                  onChange={this.searchChanged}
                  placeholder={
                    {
                      account: "Phone, Last Name, User ID, or Email",
                      pickupId: "Pickup ID",
                    }[this.state.searchType]
                  }
                  autoFocus
                />
                <InputGroup.Append onClick={this.searchSubmit}>
                  <InputGroup.Text>
                    <Icon type="search" />
                  </InputGroup.Text>
                </InputGroup.Append>
              </>
            )}
          </InputGroup>
        </Form.Row>
        <Form.Row className={"inline-form-row pl-2"}>
          <FormCheck
            type="checkbox"
            name="search-type-selector"
            id="account-search-selector"
            label="Account"
            checked={this.state.searchType === "account"}
            onChange={(e) => this.setState({ searchType: "account" })}
          />
          <FormCheck
            type="checkbox"
            name="search-type-selector"
            id="address-search-selector"
            label="Address"
            checked={this.state.searchType === "address"}
            onChange={(e) => this.setState({ searchType: "address" })}
          />
          <FormCheck
            type="checkbox"
            name="search-type-selector"
            id="pickup-id-search-selector"
            label="Pickup ID"
            checked={this.state.searchType === "pickupId"}
            onChange={(e) => this.setState({ searchType: "pickupId" })}
          />
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  openTabs: state.searchCustomers.searchTabs,
});

const mapDispatchToProps = {
  setSearchQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearchForm);
