import {
  REQUEST_PICKUP_ANALYTICS,
  RECEIVE_PICKUP_ANALYTICS_SUCCESS,
  RECEIVE_PICKUP_ANALYTICS_ERROR,
  REQUEST_NEW_USER_ANALYTICS,
  RECEIVE_NEW_USER_ANALYTICS_SUCCESS,
  RECEIVE_NEW_USER_ANALYTICS_ERROR,
} from "actions/analytics.actions";

const defaultState = {
  isFetching: false,
  loading: false,
  newUserAnalytics: {},
  pickupAnalytics: {},
  error: null,
};

const analyticsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_PICKUP_ANALYTICS:
    case REQUEST_NEW_USER_ANALYTICS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
        error: null,
      };
    case RECEIVE_PICKUP_ANALYTICS_SUCCESS:
      return {
        ...state,
        pickupAnalytics: action.pickupAnalytics,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: null,
      };
    case RECEIVE_NEW_USER_ANALYTICS_SUCCESS:
      return {
        ...state,
        newUserAnalytics: action.newUserAnalytics,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: null,
      };
    case RECEIVE_PICKUP_ANALYTICS_ERROR:
    case RECEIVE_NEW_USER_ANALYTICS_ERROR:
      return {
        ...state,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
