import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  ListGroupItem,
  Badge,
} from "react-bootstrap";
import ConfirmModal from "components/confirm-modal";
import React from "react";
import RoutePickup from "pages/regions/routes/route-pickup";
import RouteAssignPickupButton from "../route-assign-pickup-button";
import RouteEditPickup from "../route-edit-pickup";

class RouteSinglePickup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { pickup, index, color } = this.props;

    return (
      <ListGroupItem className="clearfix" key={index} id={pickup.id}>
        <RoutePickup pickup={pickup} index={index} color={color} />
        <ButtonToolbar className="justify-content-between align-items-center">
          <Badge variant={"primary"}>
            <span style={{ fontSize: "1rem" }}>
              {pickup.pickupStatus.label}
            </span>
          </Badge>

          <ButtonGroup>
            <RouteEditPickup pickup={pickup} />
            {pickup.statusId === 1 && (
              <>
                <RouteAssignPickupButton pickup={pickup} />

                {!pickup.nextStopNotificationSent && (
                  <ConfirmModal
                    title={`Start Pickup`}
                    confirmText={`Confirm you want to start this pickup`}
                    confirmSubText={`A notification will be sent to the donor that the driver is on the way.`}
                    onConfirm={() => {
                      this.props.startPickup(pickup.id).catch(() => {});
                    }}
                    buttonText="Start"
                  />
                )}

                {pickup.nextStopNotificationSent && !pickup.arrivedAt ? (
                  <ConfirmModal
                    title="Arrived at Pickup"
                    confirmText="Confirm you have arrived"
                    confirmSubText="A notification will be sent to the donor that the driver has arrived."
                    onConfirm={() => {
                      this.props.updatePickup({
                        ...pickup,
                        arrivedAt: new Date(),
                      });
                    }}
                    buttonText="Arrived"
                  />
                ) : null}

                {(pickup.nextStopNotificationSent || !!pickup.arrivedAt) && (
                  <ConfirmModal
                    title="Delay Pickup"
                    confirmText="Confirm you want to delay pickup"
                    confirmSubText="A notification will be sent to the donor that pickup was delayed."
                    onConfirm={() => {
                      this.props.delayPickup(pickup.id).catch(() => {});
                    }}
                    buttonText="Delay"
                  />
                )}
              </>
            )}
          </ButtonGroup>
        </ButtonToolbar>
      </ListGroupItem>
    );
  }
}

export default RouteSinglePickup;
