import React from "react";
import config from "config";
// https://www.npmjs.com/package/react-loading

const Loader = ({ color, fullScreen, loading = true, width }) =>
  loading ? (
    <div className={`loader-container ${fullScreen ? "full-screen" : ""}`}>
      <svg
        version="1.1"
        className="loader"
        width={width || "auto"}
        id="L9"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <path
          fill={color || config.REACT_APP_MP_PRIMARY_COLOR || "#fff"}
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          transform="rotate(50.9166 50 50)"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
      </svg>
    </div>
  ) : null;

export default Loader;
