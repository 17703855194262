import { useState } from "react";
import Layout from "layouts/login";
import ForgotPasswordForm from "forms/forgot-password";
import ForgotPasswordChangePasswordForm from "forms/forgot-password-change-password";
import { forgotPassword, changePassword } from "../../api/users";

const ForgotPassword = ({ authenticateUserCode }) => {
  const [email, setEmail] = useState(null);
  const [step, setStep] = useState(0);

  const onForgotPassword = async (values) => {
    await forgotPassword(values);
    setEmail(values.email);
    setStep(1);
  };

  const onChangePassword = async ({ verificationCode, password }) => {
    const user = (await authenticateUserCode({ email, verificationCode })).auth;
    await changePassword(user.id, password);
  };

  return (
    <Layout>
      {
        [
          <ForgotPasswordForm onSubmit={onForgotPassword} />,
          <ForgotPasswordChangePasswordForm onSubmit={onChangePassword} />,
        ][step]
      }
    </Layout>
  );
};

export default ForgotPassword;
