import {
  REQUEST_REGIONS_FILTERED,
  RECEIVE_REGIONS_FILTERED,
} from "actions/regions.actions";

const regionsFiltered = (
  state = { isFetching: false, loaded: false, regionsFiltered: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_REGIONS_FILTERED:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_REGIONS_FILTERED:
      const { regions } = action;
      return {
        ...state,
        loaded: true,
        isFetching: false,
        regionsFiltered: regions,
      };

    default:
      return state;
  }
};

export default { regionsFiltered };
