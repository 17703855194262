import { connect } from "react-redux";
import {
  createMarketingReports,
  requestMarketingReports,
  updateMarketingReports,
  deleteMarketingReports,
} from "actions/marketing-reports.actions";
import CampaignsComponent from "./campaigns.component";

const mapStateToProps = ({
  marketingReports: { isFetching, marketingReports },
}) => ({
  isFetching,
  marketingReports,
});

const mapDispatchToProps = {
  createMarketingReports,
  requestMarketingReports,
  updateMarketingReports,
  deleteMarketingReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsComponent);
