import { connect } from "react-redux";
import withForm from "forms/form-class";
import MarketingReportsForm from "./create-update-campaign.component";

const mapStateToProps = ({ marketingReports: { isFetching } }) => ({
  isFetching,
});

const MarketingReportsFormComponent = withForm(
  MarketingReportsForm,
  "MarketingReportsForm"
);

export default connect(mapStateToProps)(MarketingReportsFormComponent);
