import PickupModal from "components/pickup-modal";
import { formatDateParenWeekday, toMeridiemTime } from "utils/dates";
import React from "react";
import ListGroupWithEdit from "components/list-group-with-edit";
import WidgetHeader from "components/widget-header";
import Loader from "components/loader";
import config from "config";

class DonorPickups extends React.Component {
  constructor(props) {
    super(props);

    this.state = { pendingOnly: true };
  }

  fetchData() {
    const { isLoaded, isFetching, donorId, requestPickups } = this.props;
    if (!isFetching) {
      requestPickups({ userId: donorId });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.clearPickups();
  }

  render() {
    const { pickups, donorId, isFetching } = this.props;

    const selectSubtitle = (
      route,
      pickupStatusId,
      estimatedArrivalTime,
      timeWindow
    ) => {
      if (route && pickupStatusId === 1) {
        if (
          estimatedArrivalTime &&
          estimatedArrivalTime !== "Invalid date" &&
          !config.REACT_APP_MP_OLD_ROUTING
        ) {
          return toMeridiemTime(estimatedArrivalTime);
        }
        if (timeWindow && timeWindow !== "Invalid date") {
          return timeWindow;
        }
        return "";
      }
    };

    const renderPickup = (pickup) => {
      return {
        title: formatDateParenWeekday(pickup.pickupDate),
        badge: pickup.pickupStatus.label,
        subtitle: selectSubtitle(
          pickup.route,
          pickup.pickupStatus.id,
          pickup.estimatedArrivalTime,
          pickup.timeWindow
        ),
        icon: pickup.route && pickup.pickupStatus.id === 1 && "timeWindow",
      };
    };

    if (isFetching) {
      return <Loader />;
    }

    const hasPending = pickups.some((pickup) => pickup.statusId === 1);

    return (
      <React.Fragment>
        <WidgetHeader title={"Pickups"} count={pickups.length} />
        <ListGroupWithEdit
          name={"Pickup"}
          objectArray={pickups.filter(
            (pickup) =>
              !this.state.pendingOnly || pickup.statusId === 1 || !hasPending
          )}
          EditComponent={PickupModal}
          EditSubmit={async (data) => {
            await this.props.updatePickup(data);
            await this.props.requestCustomerActions(this.props.donorId);
            this.fetchData();
          }}
          NewComponent={PickupModal}
          NewSubmit={async (data) => {
            await this.props.createPickup(data);
            await this.props.requestCustomerActions(this.props.donorId);
            this.fetchData();
          }}
          displayFunction={renderPickup}
          ownerId={donorId}
          actions={
            pickups.some((pickup) => pickup.statusId !== 1) && hasPending
              ? [
                  {
                    label: `Show ${this.state.pendingOnly ? "More" : "Less"}`,
                    badge: this.state.pendingOnly
                      ? `+${
                          pickups.filter((pickup) => pickup.statusId === 1)
                            .length
                        }`
                      : null,
                    handler: () =>
                      this.setState({ pendingOnly: !this.state.pendingOnly }),
                  },
                ]
              : []
          }
        />
      </React.Fragment>
    );
  }
}

export default DonorPickups;
