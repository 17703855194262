/**
 *
 * GeneratePin
 *
 */

import React from "react";
import { Button } from "react-bootstrap";
// import styled from 'styled-components';
import { getVerificationCode } from "api/users";

class GeneratePin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedPin: "",
    };
  }

  handlePinGeneration = async () => {
    const { customer } = this.props;
    const pinGnerationResponse = await getVerificationCode({
      phoneNumber: customer.phoneNumber,
    });
    const pin = pinGnerationResponse.data.verificationCode;
    this.setState({ generatedPin: pin });
  };

  render = () => {
    if (this.state.generatedPin) {
      return (
        <div>
          {this.state.generatedPin}
          <p style={{ lineHeight: "1.2em", marginTop: "1rem" }}>
            <small>This pin can only be used once to access the account</small>
          </p>
          <Button size={"sm"} onClick={() => this.handlePinGeneration()}>
            retry
          </Button>
        </div>
      );
    }
    return (
      <div>
        <p style={{ lineHeight: "1.2em", marginTop: "1rem" }}>
          <small>
            Create a pin that can be used on the forgot password page.
          </small>
        </p>

        <Button variant={"primary"} onClick={() => this.handlePinGeneration()}>
          Create New Pin
        </Button>
      </div>
    );
  };
}

export default GeneratePin;
