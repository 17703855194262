import { forwardRef, useState } from "react";
import {
  Button,
  FormControl,
  Row,
  Col,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { formatTime } from "utils/helpers";
import Icon from "components/icon";

export const Note = ({ note, updateNote, deleteNote }) => {
  const [editing, setEditing] = useState(false);
  const [editingNote, setEditingNote] = useState(note.note);
  return (
    <Row className="p-3 note" style={{ borderBottom: "1px solid #efefef" }}>
      <Col xs={1} style={{ padding: 0 }}>
        <img
          style={{ width: "100%" }}
          src={note.poster.image || note.poster.profileImage}
          alt=""
        />
      </Col>
      <Col xs={10}>
        <div>
          <span style={{ fontWeight: "bold" }}>{note.poster.fullName}</span>
          <br />
          <small>
            (
            {note.createdAt !== note.updatedAt
              ? "Edited: " + formatTime(note.updatedAt)
              : formatTime(note.createdAt)}
            )
          </small>{" "}
          <Badge variant="primary">{note.category.label}</Badge>
        </div>

        <div>
          {editing && updateNote ? (
            <>
              <FormControl
                as="textarea"
                rows={2}
                value={editingNote}
                onChange={(e) => setEditingNote(e.target.value)}
              />
              <div style={{ marginTop: 12 }}>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={async () => {
                    setEditing(false);
                    await updateNote({ ...note, note: editingNote });
                  }}
                >
                  Update
                </Button>
                <Button variant="link" onClick={() => setEditing(false)}>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <div style={{ whiteSpace: "break-spaces" }}>{note.note}</div>
          )}
        </div>
      </Col>
      <Col
        className="note-ellipsis-container"
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!editing && updateNote && deleteNote && (
          <Dropdown>
            <Dropdown.Toggle
              as={forwardRef(({ onClick }, ref) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 25,
                    backgroundColor: "white",
                    border: "1px solid #dee2e6",
                    padding: 4,
                  }}
                  onClick={onClick}
                  ref={ref}
                >
                  <Icon type="ellipsis" />
                </div>
              ))}
            />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setEditing(true)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => deleteNote(note)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
};

const NotesDisplay = ({ notes, updateNote, deleteNote }) => (
  <div>
    {notes.map((note) => (
      <Note
        key={note.id}
        note={note}
        updateNote={updateNote}
        deleteNote={deleteNote}
      />
    ))}
  </div>
);

export default NotesDisplay;
