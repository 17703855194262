import { connect } from "react-redux";

import {
  requestCustomer,
  updateCustomer,
  clearCustomer,
} from "pages/donors/donor-single/customer.actions";

import { requestCustomerActions } from "../donor-timeline/customer-timeline.actions";

import { requestLeadSources } from "actions/lead-sources.actions";

import DonorInfo from "./donor-info.component";

function mapStateToProps(state) {
  return {
    customer: state.customer.customer,
    leadSources: state.leadSources.leadSources,
    isFetching: state.leadSources.isFetching,
    isLoaded: state.leadSources.loaded,
  };
}

export default connect(mapStateToProps, {
  requestCustomer,
  updateCustomer,
  requestLeadSources,
  clearCustomer,
  requestCustomerActions,
})(DonorInfo);
