import ConfirmButton from "components/confirm-button";

const PickupImagesDisplay = ({ images, deleteImage }) => (
  <div>
    {images.map(({ id, url, category }) => (
      <div key={id}>
        <span>{category?.label}</span>
        <img style={{ width: "100%" }} src={url} alt="" />
        <ConfirmButton
          onConfirm={() => deleteImage({ id })}
          buttonText="Delete"
        />
      </div>
    ))}
  </div>
);

export default PickupImagesDisplay;
