import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { REACT_APP_MP_COPYRIGHT_TEXT } from "config";

const Footer = () => (
  <div id="footer-container">
    <Container id="footer" className="text-center">
      <Row>
        <Col xs={12}>
          <div>
            {REACT_APP_MP_COPYRIGHT_TEXT} v{process.env.VERSION}
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
