import { connect } from "react-redux";
import userActions from "actions/_user.actions";
import DriverPriorityField from "./driver-priority-field.component";

const mapStateToProps = (state) => ({
  user_db: state.user_db,
  activeRegion: state.regions.activeRegion,
});

const mapDispatchToProps = {
  getUsers: userActions.get,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverPriorityField);
