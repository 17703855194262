import React, { useContext } from "react";

import TabContext from "./tab-context";
import Loader from "../loader";
import { REACT_APP_MP_PRIMARY_COLOR } from "../../config";
import Icon from "../icon";
import { Badge } from "react-bootstrap";

const Tab = ({
  value,
  children,
  badge = null,
  special = false,
  loading = false,
  removable = true,
}) => {
  const { eq, active, onChange, onRemove } = useContext(TabContext);

  return (
    <li
      key="selected-donors"
      role="presentation"
      className={
        "donor-search-tab " +
        (eq(value, active) ? "active " : "") +
        (loading ? "donor-search-tab--loading " : "")
      }
      onClick={() => onChange(value)}
    >
      <a
        {...(special
          ? { id: "donor-search-tabs-tab-selected-donor" }
          : { className: "donor-search-tab-title" })}
        role="tab"
        aria-selected={eq(value, active)}
        {...(!onRemove || !removable ? { style: { paddingRight: 0 } } : {})}
      >
        {loading ? (
          <div className="tab-loader" role="tab" aria-selected="false">
            <Loader width="2rem" color={REACT_APP_MP_PRIMARY_COLOR} />
          </div>
        ) : (
          children
        )}
      </a>

      {badge === null || (
        <Badge
          variant="secondary"
          style={{ marginLeft: 10, display: "flex", alignItems: "center" }}
        >
          {badge}
        </Badge>
      )}

      {!loading && onRemove && removable && (
        <Icon
          type="close"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(value);
          }}
        />
      )}
    </li>
  );
};

export default Tab;
