import {
  REQUEST_REGIONS,
  RECEIVE_REGIONS,
  DELETE_REGION,
  REGIONS_FETCH_FAILED,
  REQUEST_REGION,
  RECEIVE_REGION,
  CLEAR_ACTIVE_REGION,
} from "actions/regions.actions";

const regions = (
  state = {
    isFetching: false,
    loaded: false,
    regions: [],
    activeRegion: null,
    disabledRegions: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_REGIONS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_REGIONS:
      const { regions } = action;
      const receivedRegions = [];
      const receivedDisabledRegions = [];
      regions.forEach((region) => {
        if (region.disabled) {
          receivedDisabledRegions.push(region);
        } else {
          receivedRegions.push(region);
        }
      });
      return {
        ...state,
        loaded: true,
        isFetching: false,
        regions: receivedRegions,
        disabledRegions: receivedDisabledRegions,
      };
    case REQUEST_REGION:
      return {
        ...state,
        activeRegion: {
          loaded: false,
          isFetching: true,
        },
      };
    case RECEIVE_REGION:
      const { activeRegion } = action;
      return {
        ...state,
        activeRegion: {
          loaded: true,
          isFetching: false,
          region: activeRegion,
        },
      };
    case CLEAR_ACTIVE_REGION:
      return { ...state, activeRegion: null };
    case DELETE_REGION:
      const { regionId } = action;
    // const updatedglobalSettings = state.globalSettings.filter(
    // 		setting => setting.id !== settingId
    // );
    case REGIONS_FETCH_FAILED:
      return {
        ...state,
        failed: true,
      };
    default:
      return state;
  }
};

export default { regions };
