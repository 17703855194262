import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../icon";

const TableAction = ({ id, text, icon, onClick }) => (
  <OverlayTrigger
    placement={"top"}
    overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
  >
    <span>
      <Icon type={icon} onClick={onClick} />
    </span>
  </OverlayTrigger>
);

const RowActions = ({ id, actions }) => (
  <div
    className={"table-actions"}
    style={{
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      width: "100%",
    }}
  >
    {actions.map((action) => (
      <TableAction
        id={id}
        text={action.text}
        icon={action.icon}
        onClick={action.onClick}
      />
    ))}
  </div>
);

export default RowActions;
