import React from "react";
import { ListGroupItemModal } from "components/modal/modal.component";
import ExportPickups from "forms/export-pickups";
import ExportDonors from "forms/export-donors";
import ExportAgentReport from "forms/export-agent-report";
import ExportCampaignReport from "forms/export-campaign-report";
import PageLayout from "layouts/pages/page-default";
import List from "components/list";

const ExportDataComponent = () => (
  <PageLayout title={"Reports"}>
    <List>
      <ListGroupItemModal label={"Export Pickups"} title={"Export Pickups"}>
        <ExportPickups />
      </ListGroupItemModal>
      <ListGroupItemModal label={"Export Donors"} title={"Export Donors"}>
        <ExportDonors />
      </ListGroupItemModal>
      <ListGroupItemModal label={"Agent Report"} title={"Agent Report"}>
        <ExportAgentReport />
      </ListGroupItemModal>
      <ListGroupItemModal label={"Campaign Report"} title={"Campaign Report"}>
        <ExportCampaignReport />
      </ListGroupItemModal>
    </List>
  </PageLayout>
);

export default ExportDataComponent;
