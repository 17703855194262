import { connect } from "react-redux";

import withForm from "../form-class";
import ForgotPasswordChangePassword from "./forgot-password-change-password.component";

export default connect()(
  withForm(
    ForgotPasswordChangePassword,
    "ForgotPasswordChangePassword",
    (values) => ({
      confirmPassword:
        values.password !== values.confirmPassword && "Password does not match",
    }),
    null,
    true,
    [],
    "Continue"
  )
);
