import React from "react";
import { Button } from "react-bootstrap";
import Modal from "components/modal";
import Loader from "components/loader";

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
    };
  }

  openConfirm = () => this.setState({ showConfirm: true });
  closeConfirm = () => this.setState({ showConfirm: false });
  handleClose = () => {
    const { close } = this.props;
    if (close) {
      close();
    } else {
      this.closeConfirm();
    }
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.showConfirm || this.props.show}
          title={this.props.title}
          onHide={this.handleClose}
          footer={
            <div>
              <Button variant={"link"} onClick={this.handleClose}>
                Cancel
              </Button>
              <Button
                variant="default"
                onClick={() => {
                  this.props.onConfirm();
                  this.handleClose();
                }}
                variant="primary"
              >
                Confirm
              </Button>{" "}
            </div>
          }
        >
          <div className={"p-3"}>
            <h4 className={"m-0"}>{this.props.confirmText}</h4>
            {this.props.confirmSubText ? (
              <h5>{this.props.confirmSubText}</h5>
            ) : null}
          </div>
        </Modal>
        {!this.props.hideButton && (
          <Button onClick={this.openConfirm} disabled={this.props.loading}>
            {this.props.loading ? (
              <Loader color="#fff" width={"1rem"} />
            ) : (
              this.props.buttonText
            )}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default ConfirmModal;
