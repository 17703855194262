import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tab, Button, Badge } from "react-bootstrap";
import querystring from "query-string";
import history from "../../../history";
import ConfirmButton from "components/confirm-button";
import DonorSearchResults from "./donor-search-results.component";
import classnames from "classnames";
import { changeActiveTab, setSearchQuery } from "actions/donor-search.actions";
import { showToast } from "actions/toast.actions";
import { clearSearchCustomers } from "actions/customers.actions";
import { mergeUsers } from "api/users";
import RenderIf from "hoc/render-if";
import Tabs from "components/tabs";

const DonorSearchTabs = (props) => {
  const [currentActiveTab, setActiveTab] = useState(null);
  const [userSelection, setUserSelection] = useState(null);
  const [isLoading, setLoadingState] = useState(false);

  useEffect(() => {
    const {
      openTabs: openTabsFromProps,
      selectedUsers: selectedUsersFromProps,
    } = props;
    const areSelectedUsersChanging =
      !userSelection || selectedUsersFromProps.length !== userSelection.length;
    const openedTabs = openTabsFromProps.length > 0 ? openTabsFromProps : false;
    const foundActiveTab =
      openedTabs && openTabsFromProps.find((tab) => tab.active);
    const activeTab = foundActiveTab ? foundActiveTab.name : "selected-donor";
    setActiveTab(activeTab);
    if (areSelectedUsersChanging) {
      setUserSelection(selectedUsersFromProps);
    }
  });

  const removeSearchTab = ({ tab }) => {
    const isClosingTabActive = tab.name === currentActiveTab;
    const {
      removeSearchTab,
      clearSearchCustomers,
      changeActiveTab,
      openTabs: searchTabs,
    } = props;
    const isOneTabOpen = searchTabs && searchTabs.length === 1;
    const areMultipleTabsOpen = searchTabs && searchTabs.length > 1;
    // we only want to automatically change the active tab on close if the tab being closed was/is the active tab
    if (isClosingTabActive) {
      if (isOneTabOpen) {
        clearSearchCustomers();
      } else if (areMultipleTabsOpen) {
        const isClosingFirstTab = searchTabs[0].query === tab.query;
        // if more than one tab is open and they close the active tab, we want to make the previous tab the current active tab
        const indexOfCurrentTab = searchTabs.findIndex(
          (openTab) => openTab.query === tab.query
        );
        const previousTab = searchTabs[indexOfCurrentTab - 1];
        const nextTab = searchTabs[indexOfCurrentTab + 1];
        setActiveTab(isClosingFirstTab ? nextTab.query : previousTab.query);
        changeActiveTab({ tab: isClosingFirstTab ? nextTab : previousTab });
      }
    }

    // Update the query parameter
    history.push(
      history.location.pathname +
        "?" +
        querystring.stringify(
          {
            query: searchTabs
              .map((tab) => tab.query)
              .filter((t) => t !== tab.query),
          },
          { arrayFormat: "bracket" }
        )
    );
  };

  const handleActiveTabChange = ({ tab }) => {
    const { changeActiveTab } = props;
    changeActiveTab({ tab });
  };

  const logSelection = async () => {
    const {
      selectedUsers,
      searchCustomers,
      setSearchQuery,
      clearSearchCustomers,
    } = props;
    const areMultipleUsersSelected = selectedUsers && selectedUsers.length > 1;
    if (areMultipleUsersSelected) {
      setLoadingState(true);
      const userIdsForMerge = selectedUsers.map(
        (selectedUser) => selectedUser.id
      );
      try {
        const {
          data: { data: mergedUsers },
        } = await mergeUsers(userIdsForMerge);
        // since we are merging users, we know that the returned array will only return one result, the merged user
        const mergedUser = mergedUsers[0];
        // set the search query to to be the value of the newly merged user id
        setSearchQuery({
          query: mergedUser.id,
        });
        // clear existing search tabs
        clearSearchCustomers();
        // create one new search tab with the value of the newly merged user id
        searchCustomers(
          {
            name: mergedUser.id,
            type: "account",
          },
          [4, 5]
        );
      } catch (err) {
        showToast({ message: "Merge Failed", type: "error" });
      } finally {
        setLoadingState(false);
      }
    }
  };
  const { openTabs: searchTabs, selectedUsers } = props;
  const shouldRenderTabs = searchTabs && searchTabs.length > 0;
  const shouldShowSelectedUsersTab = userSelection && userSelection.length > 0;
  const isSelectedDonorTabActive =
    currentActiveTab && currentActiveTab === "selected-donor";
  const selectedDonorTabClasses = classnames("donor-search-tab", {
    "donor-search-tab active": isSelectedDonorTabActive,
  });
  const currentTabObj = searchTabs.find((tab) => tab.name === currentActiveTab);

  return shouldRenderTabs ? (
    <>
      <Tabs
        eq={(a, b) => a.name === b.name}
        active={{ name: currentActiveTab }}
        onChange={(tab) => handleActiveTabChange({ tab })}
        onRemove={(tab) => removeSearchTab({ tab })}
        header={
          userSelection && (
            <div className="donor-search-tabs_action-container">
              {userSelection.length > 0 && (
                <span style={{ marginRight: "1.5rem" }}>
                  <Badge variant={"secondary"}>{userSelection.length}</Badge>{" "}
                  Donors selected from <Badge>{searchTabs.length}</Badge>{" "}
                  searches
                </span>
              )}
              <Button
                style={{ margin: "0 .75rem" }}
                variant={"link"}
                onClick={() => {
                  // Clear the query parameter
                  history.push(history.location.pathname);
                }}
              >
                Clear
              </Button>
              <RenderIf condition={userSelection && userSelection.length >= 2}>
                <ConfirmButton
                  openConfirmCallback={() =>
                    handleActiveTabChange({ tab: { name: "selected-donor" } })
                  }
                  disabled={!userSelection || userSelection.length < 2}
                  onConfirm={logSelection}
                  confirmText={`Merge ${userSelection.length} users?`}
                  buttonText="Merge"
                  loading={isLoading}
                />
              </RenderIf>
            </div>
          )
        }
      >
        {shouldShowSelectedUsersTab && (
          <Tabs.Tab
            value={{ name: "selected-donor" }}
            special={true}
            removable={false}
          >
            <Badge variant={"secondary"}>{userSelection.length}</Badge> Selected
          </Tabs.Tab>
        )}
        {searchTabs.map((tab, i) => (
          <Tabs.Tab value={tab} key={i} loading={tab.loading}>
            "{tab.name}"
          </Tabs.Tab>
        ))}
      </Tabs>

      {shouldShowSelectedUsersTab && currentActiveTab === "selected-donor" && (
        <Tab.Pane>
          <DonorSearchResults
            mode={currentActiveTab}
            selectedCurrentTab={selectedUsers}
            searchCustomerResults={userSelection}
            activateFirstTab={() => {
              handleActiveTabChange({ tab: searchTabs[0] });
            }}
          />
        </Tab.Pane>
      )}
      {currentTabObj && (
        <Tab.Pane>
          {!currentTabObj.loading && (
            <DonorSearchResults
              tab={currentTabObj}
              searchCustomerResults={currentTabObj.results}
              selectedCurrentTab={currentTabObj.selected}
            />
          )}
        </Tab.Pane>
      )}
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  openTabs: state.searchCustomers.searchTabs,
  selectedUsers: state.searchCustomers.selectedUsers,
});

const mapDispatchToProps = {
  changeActiveTab,
  setSearchQuery,
  clearSearchCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearchTabs);
