import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

class ToastWrapper extends Component {
  render() {
    return <ToastContainer />;
  }
}

export default ToastWrapper;
