import request from "utils/request";
import querystring from "query-string";

export const REQUEST_STATS = "REQUEST_STATS";
export const RECEIVE_STATS = "RECEIVE_STATS";

export const REQUEST_PICKUPS_SCHEDULED_ON_STATS =
  "REQUEST_PICKUPS_SCHEDULED_ON_STATS";
export const RECEIVE_PICKUPS_SCHEDULED_ON_STATS =
  "RECEIVE_PICKUPS_SCHEDULED_ON_STATS";
export const CLEAR_PICKUPS_SCHEDULED = "CLEAR_PICKUPS_SCHEDULED";

export function requestStats(startDate, endDate, query = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_STATS });

    return request(
      `/pickups/stats?startDate=${startDate}&endDate=${endDate}&${querystring.stringify(
        query
      )}`
    ).then((res) => dispatch(receiveStats(res)));
  };
}

export function requestPickupsScheduledOnStats(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUPS_SCHEDULED_ON_STATS });

    return request(
      `/pickups/stats?startDate=` +
        startDate +
        "&endDate=" +
        endDate +
        "&scheduledOn=true"
    ).then((res) => dispatch(receivePickupsScheduledOnStats(res)));
  };
}

export function receivePickupsScheduledOnStats(data) {
  return {
    type: RECEIVE_PICKUPS_SCHEDULED_ON_STATS,
    count: data.data.count,
    pickupTypes: data.data.pickupTypes,
    days: data.data.days,
    sources: data.data.sources,
    statuses: data.data.statuses,
    receivedAt: new Date(),
  };
}

export function clearStats() {
  return {
    type: CLEAR_PICKUPS_SCHEDULED,
    receivedAt: new Date(),
  };
}

export function receiveStats(data) {
  return {
    type: RECEIVE_STATS,
    count: data.data.count,
    pickupTypes: data.data.pickupTypes,
    days: data.data.days,
    sources: data.data.sources,
    statuses: data.data.statuses,
    receivedAt: new Date(),
  };
}
