import React from "react";
import NewDonorForm from "forms/create-update-donor";
import { formatPhone } from "utils/helpers";
import { Badge, Row, Col } from "react-bootstrap";
import ListGroupWithEdit from "components/list-group-with-edit";
import Icon from "components/icon";
import WithLoader from "hoc/with-loader";

const RenderDonor = (props) => {
  const customer = props.record;
  const phoneNumbers = customer.phoneNumbers;

  return (
    <React.Fragment>
      <Row>
        <Col sm={4}>
          <img src={customer.profileImage} alt="" style={{ width: "100%" }} />
        </Col>
        <Col sm={8}>
          <h3 className="list-group-item-heading">
            {customer.fname} {customer.lname}
          </h3>
          {customer.email ? (
            <div>
              <Badge variant="primary">
                <Icon type="mail" />
              </Badge>{" "}
              {customer.email}
            </div>
          ) : null}

          {(phoneNumbers || []).map((p, i) => {
            return (
              <div key={i}>
                <Badge variant="primary">
                  <Icon type="phone" />
                </Badge>{" "}
                {formatPhone(p.number)}
              </div>
            );
          })}
          {customer.userType && customer.userType.label && (
            <div>
              <Badge variant="primary">
                <Icon type="user" />
              </Badge>{" "}
              {customer.userType.label}
            </div>
          )}
          {customer.leadSource ? (
            <div>
              <Badge variant="primary">
                <Icon type="flag" />
              </Badge>{" "}
              {customer.leadSource.label}
            </div>
          ) : null}
          {customer.language ? (
            <div>
              <Badge variant="primary">
                <Icon type="language" />
              </Badge>{" "}
              {customer.language.name}
            </div>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.requestData();
  }

  requestData() {
    const { requestLeadSources } = this.props;
    const { isFetching, isLoaded } = this.props;

    if (!isFetching && !isLoaded) {
      requestLeadSources();
    }
  }

  componentDidUpdate() {
    this.requestData();
  }

  componentWillUnmount() {
    this.props.clearCustomer();
  }

  render() {
    const { isFetching, isLoaded } = this.props;
    const { customer } = this.props;

    return (
      <WithLoader isLoading={isFetching || !isLoaded}>
        <ListGroupWithEdit
          name={"Donor"}
          objectArray={[customer]}
          EditComponent={NewDonorForm}
          EditSubmit={async (data) => {
            await this.props.updateCustomer(data);
            await this.props.requestCustomerActions(customer.id);
          }}
          DisplayComponent={RenderDonor}
        />
      </WithLoader>
    );
  }
}

export default CustomerInfo;
