import { createContext } from "react";

export default createContext({
  notificationTemplates: [],
  notificationTemplatesLoading: false,
  notificationTemplatesError: false,

  getNotificationTemplates: () => {},
  updateNotificationTemplate: () => {},
  clearNotificationTemplatesError: () => {},
});
