import { connect } from "react-redux";
import {
  requestNewUserAnalytics,
  requestPickupAnalytics,
} from "actions/analytics.actions";
import Analytics from "./analytics.component";
import pickupSourcesActions from "actions/pickup-sources.actions";

const mapStateToProps = (state) => ({
  isFetching: state.analytics.isFetching,
  newUserAnalytics: state.analytics.newUserAnalytics,
  pickupAnalytics: state.analytics.pickupAnalytics,
  regions: state.regions.regions,
  pickupSources: state.pickupSources.data,
});

const mapDispatchToProps = {
  requestNewUserAnalytics,
  requestPickupAnalytics,
  requestPickupSources: pickupSourcesActions.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
