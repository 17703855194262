export const option = {
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const index = tooltipItem.index;
        const label = data.labels[index];
        const item = data.datasets[0].data[index];
        const labelFormatted = label.replace(` ${item}`, `: ${item}`);
        return labelFormatted;
      },
    },
  },
};
