import {
  REQUEST_LEAD_SOURCES,
  RECEIVE_LEAD_SOURCES,
} from "actions/lead-sources.actions";

function leadSources(
  state = { isFetching: false, loaded: false, leadSources: [] },
  action
) {
  switch (action.type) {
    case REQUEST_LEAD_SOURCES:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_LEAD_SOURCES:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        leadSources: action.leadSources,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export default leadSources;
