import {
  CREATE_PICKUP_COMPLETED,
  RECEIVE_PICKUPS,
  REQUEST_PICKUPS,
  CLEAR_PICKUPS,
  UPDATE_PICKUP_COMPLETED,
} from "./pickups.actions";

const defaultPickupState = {
  isFetching: false,
  loaded: false,
  pickups: [],
  pickupStatuses: [],
  pickupStatusDescriptions: [],
  pickupSources: [],
};

function pickups(state = defaultPickupState, action) {
  switch (action.type) {
    case REQUEST_PICKUPS:
      return {
        pickups: [],
        meta: {},
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_PICKUPS:
      return {
        pickups: action.pickups,
        pickupStatuses: action.pickupStatuses,
        pickupStatusDescriptions: action.pickupStatusDescriptions.sort((a, b) =>
          a.description.localeCompare(b.description)
        ),
        pickupSources: action.pickupSources,
        count: action.count,
        pagination: action.pagination,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
      };

    case UPDATE_PICKUP_COMPLETED:
      return {
        ...defaultPickupState,
        pickups: state.pickups.map((pickup) =>
          pickup.id === action.pickup.id ? action.pickup : pickup
        ),
      };

    case CREATE_PICKUP_COMPLETED:
      return { ...defaultPickupState };

    case CLEAR_PICKUPS:
      return { ...defaultPickupState };
    default:
      return state;
  }
}

export default pickups;
