import React from "react";
import { Button } from "react-bootstrap";
import DropZone from "react-dropzone";

let apiHostname = window.location.origin;
if (process.env.NODE_ENV === "development") {
  apiHostname = "https://localhost:3002";
}

const baseUrl = `${apiHostname}/api`;

const RouteImportExport = (props) => {
  const { date, onFileSelect, regionId, onSuccess } = props;

  const onDrop = (files) => {
    onFileSelect({ file: files[0], regionId, date }).then(() => onSuccess());
  };

  return (
    <div>
      <h3>No routes yet.</h3>
      <p>
        <Button
          className="button-default button"
          href={`${baseUrl}/pickups/csv?date=${date}&regionId=${regionId}&statusId=1&pickupFilterType=pickupDate&integration=myRouteOnline`}
        >
          Download Pickups CSV
        </Button>
      </p>
      <DropZone onDrop={onDrop} multiple={false} className="drop-zone-routes">
        <div>
          Drag manual route import file here
          <br />
          or <Button>Click Here</Button> to choose the file
        </div>
      </DropZone>
    </div>
  );
};

export default RouteImportExport;
