import React, { useContext, useEffect } from "react";
import { Field } from "redux-form";
import FormField from "forms/FormField";
import { Row, Col } from "react-bootstrap";
import Autocomplete from "forms/PlacesAutocompleteField";
import { required } from "utils/validators";
import { LocationTypesContext } from "contexts/location-types";
import Notes from "../notes";
import { locationsNotesAPI } from "api/locations";

let NewLocationForm = ({
  isGatedCommunity,
  handleSelect,
  onAddressChange,
  initialValues,
}) => {
  const {
    locationTypes,
    getLocationTypes,
    locationTypesLoading,
    locationTypesError,
    locationTypesLoaded,
  } = useContext(LocationTypesContext);

  useEffect(() => {
    if (!locationTypesLoaded && !locationTypesLoading) {
      getLocationTypes();
    }
  }, [getLocationTypes]);

  return (
    <React.Fragment>
      <Field
        name="label"
        component={FormField}
        label="Label"
        placeholder=""
        type="text"
      />
      <Field
        name="locationTypeId"
        component={FormField}
        label="Location Type"
        type="select"
        options={locationTypes}
      />
      <Field
        name="addressSearch"
        component={Autocomplete}
        label="Address Search"
        onPlaceSelected={handleSelect}
        placeholder=""
        type="text"
        onChange={onAddressChange}
      />
      <Row>
        <Col sm={9}>
          <Field
            name="address"
            component={FormField}
            label="Address"
            onPlaceSelected={handleSelect}
            placeholder=""
            type="text"
            required={true}
            validate={required}
            onChange={onAddressChange}
          />
        </Col>
        <Col sm={3}>
          <Field
            name="unit"
            component={FormField}
            label="Unit"
            placeholder=""
            type="text"
            onChange={onAddressChange}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={5}>
          <Field
            name="city"
            component={FormField}
            label="City"
            placeholder=""
            type="text"
            required={true}
            validate={required}
            onChange={onAddressChange}
          />
        </Col>
        <Col sm={2}>
          <Field
            name="state"
            component={FormField}
            label="State"
            placeholder=""
            type="text"
            maxLength={2}
            required={true}
            validate={required}
            onChange={onAddressChange}
          />
        </Col>
        <Col sm={5}>
          <Field
            name="zip"
            component={FormField}
            label="Zip"
            placeholder=""
            type="text"
            maxLength={5}
            required={true}
            validate={required}
            onChange={onAddressChange}
          />
        </Col>
      </Row>
      <Field
        name="mapAddress"
        component={FormField}
        label="Map Address"
        type="text"
        disabled={true}
      />
      <hr />
      <h4>Community Info</h4>
      <hr />
      <Row>
        <Col sm={6}>
          <Field
            label={"Gated Community"}
            name="gated"
            component={FormField}
            className="form-control"
            type="checkbox"
          />
        </Col>
        <Col sm={6}>
          <Field
            name="gateCode"
            component={FormField}
            renderCondition={isGatedCommunity}
            label="Gate Code"
            placeholder=""
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name="development"
            component={FormField}
            label="Development"
            placeholder=""
            type="text"
          />
        </Col>
        <Col sm={6}>
          <Field
            name="subdivision"
            component={FormField}
            label="Subdivision"
            placeholder=""
            type="text"
          />
        </Col>
      </Row>
      <Field
        name="notes"
        component={FormField}
        label="Please list cross street and any entry instructions"
        type="text"
      />

      {initialValues?.id && (
        <>
          <Field
            label="Archive this address"
            name="disabled"
            id="disabled"
            component={FormField}
            className="form-control"
            type="checkbox"
            normalize={(v) => !!v}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default NewLocationForm;
