import request from "utils/request";

export const REQUEST_LEAD_SOURCES = "REQUEST_LEAD_SOURCES";
export const RECEIVE_LEAD_SOURCES = "RECEIVE_LEAD_SOURCES";

export function requestLeadSources() {
  return (dispatch) => {
    dispatch({ type: REQUEST_LEAD_SOURCES });

    request({ url: `/leadSources` })
      .then((res) => dispatch(receiveLeadSources(res.data)))
      .catch((res) => console.error(res));
  };
}

function receiveLeadSources(data = {}) {
  return {
    type: RECEIVE_LEAD_SOURCES,
    leadSources: data.data,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}
