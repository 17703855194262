import { connect } from "react-redux";
import {
  requestZones,
  createZone,
  updateZone,
  deleteZone,
} from "actions/zones.actions";

import ZipMap from "./ZipMap.component";

const mapStateToProps = (state) => ({
  region: state.regions.activeRegion.region,
  zones: state.zones.zones
    .filter((zone) => !zone.disabled)
    .map((zone) => ({
      id: zone.id,
      name: zone.name,
      pickupTypeId: zone.pickupTypeId,
      // hasFurniture: zone.hasFurniture,
      regionId: zone.regionId,
      defaultBlockStops: zone.defaultBlockStops,
      color: zone.color
        ? `#${zone.color}`
        : `#${Math.floor(Math.random() * 0xffffff)
            .toString(16)
            .padStart(6, "0")}`, // Generate a random color
      zipCodes: zone.zipCodes.map((zipCodes) => zipCodes.zip),
      editing: false,
      enteringZip: "",
      errors: {},
    }))
    .sort((a, b) => a.id - b.id),
  zonesLoaded: state.zones.loaded,
  zonesFetching: state.zones.isFetching,
  stopsSetting: state.settings.globalSettings.find(
    (setting) => setting.label === "pickup-stops"
  ),
});

const mapDispatchToProps = {
  requestZones,
  createZone,
  updateZone,
  deleteZone,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZipMap);
