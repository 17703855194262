import { connect } from "react-redux";
import { requestRoutes } from "actions/routes.actions";

import RouteMap from "./route-map.component";

const mapStateToProps = (state) => {
  return {
    routesMap: {
      ...state.pickupRoutes.routesMap,
    },
    pickupsMap: {
      ...state.pickupRoutes.pickupsMap,
    },

    unnassignedPickups: [...state.pickupRoutes.unnassignedPickups],
  };
};

const mapDispatchToProps = {
  requestRoutes,
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);
