import React, { useState, useEffect, useRef } from "react";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Map, TileLayer, ScaleControl, FeatureGroup } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { EditControl } from "react-leaflet-draw";
import protobuf from "protobufjs";
import SearchControl from "./SearchControl.component";
import ZipCodeLayer from "./ZipCodeLayer.component";
import ZipCodeZoneLayer from "./ZipCodeZoneLayer.component";
import config from "config";

import ZipCodeZoneDisplay from "./ZipCodeZoneDisplay.component";

import "./style.scss";
import Loader from "components/loader";

function ZipMap(props) {
  // Get the zip code GeoJSON data
  const [notFoundZipCodeChunks, setNotFoundZipCodeChunks] = useState(new Set());
  const [previouslyLoadedZipCodeChunks, setPreviouslyLoadedZipCodeChunks] =
    useState(new Set());
  const [loadedZipCodeChunks, setLoadedZipCodeChunks] = useState([]);
  const [zipCodeData, setZipCodeData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [zipCodeList, setZipCodeList] = useState([]);

  const savedZipCodeZones = props.zones;

  // Setup state for dynamically loaded values
  const [zonesLoaded, setZonesLoaded] = useState(false);
  const [allZipCodes, setAllZipCodes] = useState();
  const [zipCodeZones, setZipCodeZones] = useState();

  // Dynamically load required information
  const [geoJSONLayer, setGeoJSONLayer] = useState();
  useEffect(() => {
    // Get the zones from the API
    props.requestZones({ regionId: props.region.id });

    // Get first zip code chunk
    const [x, y] = computeZipCodeGridCoords(position);
    const filename = `${x}x${y}.json`;
    fetch(`${config.REACT_APP_MP_ZIP_CODE_GEO_JSON_FOLDER_URL}/${filename}`)
      .then((res) => res.json())
      .then((data) => setLoadedZipCodeChunks([{ filename, data }]));
    fetch(config.REACT_APP_MP_ZIP_CODE_LIST_BUF)
      .then((res) => res.arrayBuffer())
      .then((data) =>
        Promise.all([
          new Uint8Array(data),
          fetch(config.REACT_APP_MP_ZIP_CODE_LIST_PROTO),
        ])
      )
      .then(([data, res]) => Promise.all([data, res.text()]))
      .then(([data, proto]) => {
        const root = new protobuf.Root();
        protobuf.parse(proto, root);
        const ZipCodeList = root.lookupType("ZipCodeList");
        setZipCodeList(ZipCodeList.toObject(ZipCodeList.decode(data)).zipCodes);
      });

    // Create GeoJSON layer to parse the GeoJSON
    // This is used by leaflet-search, handleRectangularSelection, and handleTagClickFactory
    setGeoJSONLayer(
      new L.GeoJSON().on("add", (e) => {
        // leaflet-search adds the layer to the map for us,
        // which we don't want cause GeoJSON layers are slow.
        // This work around removes the layer as soon as it's added.
        e.sourceTarget._map.removeLayer(e.sourceTarget);
      })
    );
  }, []);

  // Update values when new zip code chunks are loaded
  useEffect(() => {
    setZipCodeData({
      type: "FeatureCollection",
      features: loadedZipCodeChunks
        .map(({ data: { features } }) => features)
        .flat(),
    });
    setAllZipCodes(
      loadedZipCodeChunks
        .map(({ data: { features } }) =>
          features.map((feature) => feature.properties.ZCTA5CE10)
        )
        .flat()
    );

    const newChunks = loadedZipCodeChunks.filter(
      ({ filename }) => !previouslyLoadedZipCodeChunks.has(filename)
    );
    for (const chunk of newChunks) {
      geoJSONLayer.addData(chunk.data);
    }
    setPreviouslyLoadedZipCodeChunks(
      new Set([
        ...previouslyLoadedZipCodeChunks,
        ...newChunks.map(({ filename }) => filename),
      ])
    );
  }, [loadedZipCodeChunks]);

  useEffect(() => {
    // Don't update zonesUpdate after the first time
    // This keeps the loading screen from showing whenever the zones state is modified
    setZonesLoaded(zonesLoaded || props.zonesLoaded);
  }, [props.zonesLoaded]);

  // Update the zones when updated data is passed in
  useEffect(() => {
    if (!props.zonesFetching)
      setZipCodeZones(JSON.parse(JSON.stringify(props.zones)));
  }, [props.zones]);

  // Combine createZone and updateZone into one function
  const saveZone = (zone) => {
    if ("id" in zone) props.updateZone(zone.id, zone);
    else props.createZone(zone);
  };

  // Setup initial state
  const [activeZipCodeZoneIdx, setActiveZipCodeZoneIdx] = useState(0);

  let startCoords = { lat: "29.7604", lng: "-95.3698" };
  const {
    region: { routeStartCoordinates },
  } = props;
  if (routeStartCoordinates) {
    startCoords = routeStartCoordinates;
  }
  const [position, setPosition] = useState([startCoords.lat, startCoords.lng]);
  const [highlightedZipCode, setHighlightedZipCode] = useState(null);

  // The zone in the new zip code zone form
  const initialNewZipCodeZone = () => ({
    name: "",
    color: `#${Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, "0")}`,
    zipCodes: [],
    defaultBlockStops:
      (props.stopsSetting.value && props.stopsSetting.value.stops) || 1,
    pickupTypeId: null,
    editing: false,
    enteringZip: "",
  });
  const [newZipCodeZone, setNewZipCodeZone] = useState(initialNewZipCodeZone());

  // Store the indexes of any zip code zones that have been modified
  const [modifiedZipCodeZones, setModifiedZipCodeZones] = useState(new Set());
  const addModifiedZipCodeZone = (idx) => {
    const newModifiedZipCodeZones = new Set(modifiedZipCodeZones);
    newModifiedZipCodeZones.add(idx);
    setModifiedZipCodeZones(newModifiedZipCodeZones);
  };
  const removeModifiedZipCodeZone = (idx) => {
    const newModifiedZipCodeZones = new Set(modifiedZipCodeZones);
    newModifiedZipCodeZones.delete(idx);
    setModifiedZipCodeZones(newModifiedZipCodeZones);
  };

  const removeZipCode = (zipCode) => {
    // Remove the zip code from any zones it currently is in
    if (activeZipCodeZoneIdx < zipCodeZones.length) {
      setZipCodeZones(
        zipCodeZones.map((zone, idx) =>
          idx === activeZipCodeZoneIdx
            ? {
                ...zone,
                zipCodes: zipCodeZones[activeZipCodeZoneIdx].zipCodes.filter(
                  (testZipCode) => testZipCode !== zipCode
                ),
              }
            : zone
        )
      );
      addModifiedZipCodeZone(activeZipCodeZoneIdx);
    } else if (newZipCodeZone.zipCodes.includes(zipCode)) {
      setNewZipCodeZone({
        ...newZipCodeZone,
        zipCodes: newZipCodeZone.zipCodes.filter(
          (testZipCode) => testZipCode !== zipCode
        ),
      });
    }
  };

  const addZipCode = (zipCode) => {
    // Add the zip code to the active zone
    if (activeZipCodeZoneIdx === zipCodeZones.length) {
      setNewZipCodeZone({
        ...newZipCodeZone,
        zipCodes: [...newZipCodeZone.zipCodes, zipCode],
      });
    } else {
      const newZipCodeZones = [...zipCodeZones];
      newZipCodeZones[activeZipCodeZoneIdx].zipCodes = [
        ...newZipCodeZones[activeZipCodeZoneIdx].zipCodes,
        zipCode,
      ];
      setZipCodeZones(newZipCodeZones);
      addModifiedZipCodeZone(activeZipCodeZoneIdx);
    }
  };

  const addMultipleZipCodes = (zipCodes) => {
    if (activeZipCodeZoneIdx === zipCodeZones.length) {
      setNewZipCodeZone({
        ...newZipCodeZone,
        zipCodes: [...newZipCodeZone.zipCodes, ...zipCodes],
      });
    } else {
      const newZipCodeZones = [...zipCodeZones];
      newZipCodeZones[activeZipCodeZoneIdx].zipCodes = [
        ...newZipCodeZones[activeZipCodeZoneIdx].zipCodes,
        ...zipCodes,
      ];
      setZipCodeZones(newZipCodeZones);
      addModifiedZipCodeZone(activeZipCodeZoneIdx);
    }
  };

  // Toggle a zip code when it is clicked on
  const handleZipCodeClick = (e) => {
    addZipCode(
      "feature" in e.layer
        ? e.layer.feature.properties.ZCTA5CE10
        : e.layer.properties.ZCTA5CE10
    );
  };
  const handleSelectedZipCodeClick = (e) => {
    // Ignore if zoomed too far out show zip codes
    if (!showZipCodes) return;

    removeZipCode(e.layer.feature.properties.ZCTA5CE10);
  };

  // Set leaflet-draw options
  const drawOptions = {
    polyline: false,
    polygon: false,
    circle: false,
    marker: false,
    circlemarker: false,
  };
  L.drawLocal.draw.handlers.rectangle.tooltip.start =
    "Click and drag to select zip codes.";
  L.drawLocal.draw.handlers.simpleshape.tooltip.end = "Release to select";
  L.drawLocal.draw.toolbar.actions.title = "Cancel selection";
  L.drawLocal.draw.toolbar.buttons.rectangle = "Select zip codes";
  const editOptions = {
    edit: false,
    remove: false,
    toolbar: {
      cancel: {
        title: "Cancel selection",
      },
    },
  };

  // Select zip codes with the rectangular selection tool
  const handleRectangularSelection = ({ layer }) => {
    const selectionLayer = layer;

    // Go through each layer in the GeoJSON layer and check if it intersects the selection
    // If it does add it to the selected zip codes
    const selectedZipCodes = new Set();
    geoJSONLayer.eachLayer((layer) => {
      if (selectionLayer.getBounds().intersects(layer.getBounds())) {
        selectedZipCodes.add(layer.feature.properties.ZCTA5CE10);
      }
    });

    // Add the zip codes to the active zone
    addMultipleZipCodes(selectedZipCodes);

    // Remove the selection layer
    selectionLayer._map.removeLayer(selectionLayer);
    addModifiedZipCodeZone(activeZipCodeZoneIdx);
  };

  const mapRef = useRef();

  // Generate GeoJSON for the zip code zones
  const [zipCodeZoneGeoJSONs, setZipCodeZoneGeoJSONs] = useState();
  const [newZipCodeZoneGeoJSON, setNewZipCodeZoneGeoJSON] = useState();
  const [initializedMapBounds, setInitializedMapBounds] = useState(false);
  useEffect(() => {
    const newZipCodeZoneGeoJSONs = [];

    for (const idx in zipCodeZones) {
      if (idx >= newZipCodeZoneGeoJSONs.length) {
        newZipCodeZoneGeoJSONs.push([]);
      }

      newZipCodeZoneGeoJSONs[idx] = {
        type: "FeatureCollection",
        features: [],
      };
    }

    const newZipCodeZoneFeatures = [];

    const mapLayersBounds = L.latLngBounds();
    for (const feature of zipCodeData.features) {
      for (const [idx, zone] of zipCodeZones.entries()) {
        if (zone.zipCodes.includes(feature.properties.ZCTA5CE10)) {
          newZipCodeZoneGeoJSONs[idx].features.push(feature);

          // Flattened to [lng, lat, lng, lat, ...] to account for polygons and multipolygons
          const coords = feature.geometry.coordinates.flat(Infinity);
          for (let i = 0; i < coords.length; i += 2)
            mapLayersBounds.extend(L.latLng(coords[i + 1], coords[i]));
        }
      }

      if (newZipCodeZone.zipCodes.includes(feature.properties.ZCTA5CE10)) {
        newZipCodeZoneFeatures.push(feature);
      }
    }
    if (
      !initializedMapBounds &&
      mapRef.current &&
      Object.keys(mapLayersBounds).length !== 0
    ) {
      mapRef.current.leafletElement.fitBounds(mapLayersBounds);
      setInitializedMapBounds(true);
    }

    setNewZipCodeZoneGeoJSON({
      type: "FeatureCollection",
      features: newZipCodeZoneFeatures,
    });
    setZipCodeZoneGeoJSONs(newZipCodeZoneGeoJSONs);
  }, [zipCodeData.features, zipCodeZones, newZipCodeZone]);

  const computeZipCodeGridCoords = ([lon, lat]) => [
    Math.floor(
      (lon - Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LON)) /
        Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
    ),
    Math.floor(
      (lat - Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LAT)) /
        Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
    ),
  ];

  const onMapViewportChange = async ({ center }) => {
    const mapBounds = mapRef.current.leafletElement.getBounds();
    const gridCoords = [];
    for (
      let lon = mapBounds.getSouth();
      lon <
      mapBounds.getNorth() +
        Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE);
      lon += Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
    )
      for (
        let lat = mapBounds.getWest();
        lat <
        mapBounds.getEast() +
          Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE);
        lat += Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
      ) {
        gridCoords.push(computeZipCodeGridCoords([lon, lat]));
      }

    const notFoundChunks = new Set();
    setLoadedZipCodeChunks([
      ...loadedZipCodeChunks,
      ...(
        await Promise.all(
          gridCoords.map(async ([x, y]) => {
            const filename = `${x}x${y}.json`;
            if (
              notFoundZipCodeChunks.has(filename) ||
              loadedZipCodeChunks.find(({ filename: f }) => f === filename)
            )
              return Promise.resolve();

            try {
              return {
                filename,
                data: await (
                  await fetch(
                    `${config.REACT_APP_MP_ZIP_CODE_GEO_JSON_FOLDER_URL}/${filename}`
                  )
                ).json(),
              };
            } catch (e) {
              notFoundChunks.add(filename);
              return Promise.resolve();
            }
          })
        )
      ).filter(Boolean),
    ]);
    setNotFoundZipCodeChunks(
      new Set([...notFoundZipCodeChunks, ...notFoundChunks])
    );
  };

  // Return the loader if all the information hasn't been loaded yet
  if (
    zipCodeData.features.length === 0 ||
    !allZipCodes ||
    !zipCodeZones ||
    !zonesLoaded
  )
    return (
      <div id="zipmap-wrapper">
        <div id="zipmap-loader">
          <Loader />
        </div>
      </div>
    );

  const showZipCodes =
    mapRef?.current?.leafletElement?.getZoom?.() <
      Number(config.REACT_APP_MP_ZIP_CODE_SHOW_MAX_ZOOM) &&
    mapRef?.current?.leafletElement?.getZoom?.() >
      Number(config.REACT_APP_MP_ZIP_CODE_SHOW_MIN_ZOOM);

  return (
    <div id="zipmap-wrapper">
      <ZipCodeZoneDisplay
        zipCodeZones={zipCodeZones}
        setZipCodeZones={setZipCodeZones}
        savedZipCodeZones={savedZipCodeZones}
        activeZipCodeZoneIdx={activeZipCodeZoneIdx}
        setActiveZipCodeZoneIdx={setActiveZipCodeZoneIdx}
        setHighlightedZipCode={setHighlightedZipCode}
        modifiedZipCodeZones={modifiedZipCodeZones}
        setModifiedZipCodeZones={setModifiedZipCodeZones}
        addModifiedZipCodeZone={addModifiedZipCodeZone}
        removeModifiedZipCodeZone={removeModifiedZipCodeZone}
        newZipCodeZone={newZipCodeZone}
        setNewZipCodeZone={setNewZipCodeZone}
        removeZipCode={removeZipCode}
        allZipCodes={allZipCodes}
        geoJSONLayer={geoJSONLayer}
        setPosition={setPosition}
        initialNewZipCodeZone={initialNewZipCodeZone}
        regionId={props.region.id}
        saveZone={saveZone}
        deleteZone={props.deleteZone}
        stopsSetting={props.stopsSetting}
      />
      <Map
        center={position}
        zoom="12"
        maxZoom="18"
        minZoom="8"
        zoomControl={true}
        zoomAnimationThreshold="5"
        id="map"
        onViewportChanged={onMapViewportChange}
        ref={mapRef}
      >
        <TileLayer
          zIndex={0}
          url="https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=oc0kqgi4KpEJGWbrQpc5"
          zoomOffset={-1}
          tileSize={512}
        />
        {showZipCodes &&
          loadedZipCodeChunks.map(({ data }, idx) => (
            <ZipCodeLayer
              key={idx}
              zipCodeData={data}
              onClick={handleZipCodeClick}
            />
          ))}
        {zipCodeZones.map(
          (zone, idx) =>
            zipCodeZoneGeoJSONs &&
            zipCodeZoneGeoJSONs[idx] && (
              <ZipCodeZoneLayer
                color={zone.color}
                geoJSON={zipCodeZoneGeoJSONs && zipCodeZoneGeoJSONs[idx]}
                onClick={handleZipCodeClick}
                activeZips={
                  (zipCodeZones[activeZipCodeZoneIdx] || newZipCodeZone)
                    .zipCodes
                }
                key={idx}
              />
            )
        )}
        <ZipCodeZoneLayer
          color={newZipCodeZone.color}
          geoJSON={newZipCodeZoneGeoJSON}
          onClick={handleZipCodeClick}
          activeZips={
            (zipCodeZones[activeZipCodeZoneIdx] || newZipCodeZone).zipCodes
          }
        />
        <ZipCodeZoneLayer
          color={(zipCodeZones[activeZipCodeZoneIdx] || newZipCodeZone).color}
          highlightedZipCode={highlightedZipCode}
          geoJSON={
            zipCodeZoneGeoJSONs &&
            (zipCodeZoneGeoJSONs[activeZipCodeZoneIdx] || newZipCodeZoneGeoJSON)
          }
          onClick={handleSelectedZipCodeClick}
        />
        <FeatureGroup>
          <EditControl
            draw={drawOptions}
            edit={editOptions}
            onCreated={handleRectangularSelection}
          />
        </FeatureGroup>
        <ScaleControl metric={false} imperial={true} />
        <SearchControl geoJSONLayer={geoJSONLayer} zipCodeList={zipCodeList} />
      </Map>
    </div>
  );
}

export default ZipMap;
