import { connect } from "react-redux";

import ForgotPassword from "./forgot-password.component";
import { authenticateUserCode } from "../../actions/login.actions";

const mapDispatchToProps = {
  authenticateUserCode,
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
