import { useCallback } from "react";

import LeadSourcesContext from "./context";
import {
  createLeadSource,
  getLeadSources,
  updateLeadSource,
} from "api/lead-sources";
import useResource from "../useResource";

const LeadSourcesProvider = ({ children }) => {
  const [
    leadSources,
    setLeadSources,
    leadSourcesLoading,
    leadSourcesError,
    clearLeadSourcesError,
    wrapper,
  ] = useResource([]);

  const getSources = wrapper(
    useCallback((opts) => getLeadSources(opts).then(setLeadSources), [])
  );

  const createSource = wrapper(
    useCallback(
      (source) =>
        createLeadSource(source).then((source) =>
          setLeadSources([...leadSources, source])
        ),
      [leadSources]
    )
  );

  const updateSource = wrapper(
    useCallback(
      (source) =>
        updateLeadSource(source).then((updated) =>
          setLeadSources(
            leadSources.map((source) =>
              source.id === updated.id ? { ...source, ...updated } : source
            )
          )
        ),
      [leadSources]
    )
  );

  return (
    <LeadSourcesContext.Provider
      value={{
        leadSources,
        getLeadSources: getSources,
        leadSourcesLoading,
        leadSourcesError,
        createLeadSource: createSource,
        updateLeadSource: updateSource,
        clearLeadSourcesError,
      }}
    >
      {children}
    </LeadSourcesContext.Provider>
  );
};

export default LeadSourcesProvider;
