import React from "react";
import {
  Button,
  Col,
  ListGroup,
  ProgressBar,
  Row,
  FormCheck,
} from "react-bootstrap";

import { FormControl } from "react-bootstrap";

import { getFormatedTime } from "utils/dates";
import Box from "components/box";
import Icon from "components/icon";
import Modal from "components/modal";
import SinglePickup from "../route-single-pickup";
import RouteDriverSelectDropdown from "./route-assign-driver";
import RouteTimesEstimateForm from "forms/create-update-time-windows";
import { REACT_APP_MP_OLD_ROUTING } from "config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LabelWithCount from "components/label-with-count-box";
import Loader from "components/loader";

class SingleRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.noCollapse || false,
      showTimeWindowModal: false,
    };
  }

  toggleExpand() {
    if (!this.state.expanded) {
      const { requestRoutePickupsByRouteId, pickupDate, routeId } = this.props;
      requestRoutePickupsByRouteId({ pickupDate, routeId });
    }
    this.setState({ expanded: !this.state.expanded });
  }

  getPickups() {
    const { requestRoutePickupsByRouteId, pickupDate, routeId } = this.props;
    requestRoutePickupsByRouteId({ pickupDate, routeId });
  }

  _closeModal = () => this.setState({ showTimeWindowModal: false });

  openTimeWindowModal = () => this.setState({ showTimeWindowModal: true });

  updateTimeWindow = (pickupWindows) => {
    return this.props
      .updateRoute({
        ...this.props.pickupRoute,
        ...pickupWindows,
      })
      .then(() => {
        this._closeModal();
      });
  };

  renderTimeWindowsModal() {
    const { timeWindows, pickupRoute, furnitureTimeWindows } = this.props;
    const showModalContent = () => {
      const defaultTimeWindows =
        pickupRoute && pickupRoute.hasFurniture
          ? furnitureTimeWindows
          : timeWindows;
      const pickupWindowsToRender =
        pickupRoute.pickupWindows && pickupRoute.pickupWindows.length > 0
          ? pickupRoute.pickupWindows
          : defaultTimeWindows;
      return (
        <RouteTimesEstimateForm
          initialValues={{
            pickupWindows: pickupWindowsToRender,
          }}
          form={`pickupWindows`}
          onSubmit={this.updateTimeWindow}
          onSubmitSuccess={() => {
            this.props.rehydrateRoutes(
              pickupRoute.routeDate,
              pickupRoute.regionId
            );
          }}
        />
      );
    };
    let routeId = this.state.showTimeWindowModal;
    return routeId ? (
      <Modal
        show={routeId}
        onHide={() => {
          this._closeModal();
        }}
        backdrop={"static"}
        title={"Time Windows"}
      >
        {showModalContent()}
      </Modal>
    ) : null;
  }
  componentDidMount() {
    if (this.props.noCollapse) {
      this.getPickups();
    }
  }

  selectPickupType = (e, route, pickupRoute) => {
    const selected = this.props.pickupTypes.find(
      (pickupType) => pickupType.id === parseInt(e.target.value)
    );
    if (selected) {
      this.props.updateRoute({
        ...route,
        pickupTypeId: e.target.value,
      });
    }
  };

  render() {
    const {
      pickupRoute,
      pickupRoutes,
      pickupStatuses,
      auth,
      pickups,
      routeIndex,
      showOnMap,
      setShowOnMap,
      pickupsMin,
      routesMap,
    } = this.props;
    const { pickupsLoaded, pickupsFetching } = pickupRoute;
    // If there are routes loop through them

    const routeId = pickupRoute.id;
    const pickupTypeId = pickupRoute.pickupTypeId;
    let pickupsCount = 0;
    const pickupStatusesCount = {};
    const expanded = this.state.expanded;
    const expandedStyle = expanded ? {} : { display: "none" };
    // AUTH: If this is a driver return null for routes that he is not assigned to
    if (
      auth.credentials.userTypeId === 3 &&
      pickupRoute.driverId !== auth.credentials.id
    ) {
      return null;
    }

    // Get the route object ( this is a computed property that contains a pickup array within the route object)
    let route = pickupRoute;

    let routeLoading = routesMap[route.id].loadingStatus;

    // Loops through pickup statuses and creates an array for counting

    pickupStatuses.forEach((status) => {
      pickupStatusesCount[status.id] = 0;
    });

    for (const pickup of pickupsMin) {
      pickupStatusesCount[pickup.pickupStatus.id]++;
      pickupsCount++;
    }
    // Count of pickups which are not in the "pending" state -- everything else has been handled and can go toward route completion
    const pickupsNotPendingCount = pickupsCount - pickupStatusesCount[1];
    return (
      <Box key={route.id}>
        {this.renderTimeWindowsModal()}
        {routeLoading ? (
          <Loader />
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>
                <Icon style={{ color: route.color }} type="truck" /> Truck{" "}
                {route.position + 1}{" "}
                {route.pickupType && "- " + route.pickupType.label + " "}
                {!this.props.noCollapse ? (
                  <small>
                    (
                    <a onClick={() => this.toggleExpand()}>
                      {this.state.expanded ? "Hide Stops" : "Show Stops"}
                    </a>
                    )
                  </small>
                ) : null}
              </h3>
              {this.props.auth.credentials.userType.label !== "driver" &&
                REACT_APP_MP_OLD_ROUTING !== "true" && (
                  <span style={{ display: "flex" }}>
                    Show on map:{" "}
                    <FormCheck
                      style={{ marginLeft: 5 }}
                      checked={showOnMap}
                      onChange={(e) => setShowOnMap(e.target.checked)}
                    />
                  </span>
                )}
            </div>
            <ProgressBar
              // color={color}
              now={(pickupsNotPendingCount / pickupsMin.length) * 100 + 0.1}
            />

            <Row className={"mt-4"}>
              <Col sm={REACT_APP_MP_OLD_ROUTING === "true" ? "5" : "9"}>
                <div style={{ display: "flex" }}>
                  {/*<div>Total Donations: {route.pickups.length}</div>*/}
                  {pickupStatuses.map((status) => {
                    let count = pickupStatusesCount[status.id];
                    if (count > 0) {
                      return (
                        <LabelWithCount
                          label={status.label}
                          count={pickupStatusesCount[status.id]}
                          key={status.id}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Col>
              {REACT_APP_MP_OLD_ROUTING === "true" && (
                <Col sm="4" style={{ marginBottom: "15px" }}>
                  <div>
                    <strong>Time Windows</strong>
                  </div>
                  {pickupRoute.pickupWindows &&
                    pickupRoute.pickupWindows.map((window) => (
                      <div>
                        <strong>
                          Stops {window.first} - {window.last}:
                        </strong>{" "}
                        {getFormatedTime(window.start)} -{" "}
                        {getFormatedTime(window.end)}
                      </div>
                    ))}
                  <div>
                    {this.props.auth.credentials.userType.label !==
                      "driver" && (
                      <Button onClick={this.openTimeWindowModal}>
                        Change Time Windows
                      </Button>
                    )}
                  </div>
                </Col>
              )}
              <Col sm={"3"} style={{ marginBottom: "15px" }}>
                {this.props.auth.credentials.userType.label !== "driver" && (
                  <RouteDriverSelectDropdown route={route} />
                )}
                {REACT_APP_MP_OLD_ROUTING === "true" &&
                  this.props.auth.credentials.userType.label !== "driver" && (
                    <React.Fragment>
                      <FormControl
                        as="select"
                        value={pickupTypeId || -1}
                        onChange={(e) =>
                          this.selectPickupType(e, route, pickupRoute)
                        }
                      >
                        {!pickupTypeId &&
                          this.props.auth.credentials.userType.label !==
                            "driver" && (
                            <option value="-1">Select Pickup Type</option>
                          )}
                        {this.props.auth.credentials.userType.label !== "driver"
                          ? this.props.pickupTypes.map((pickupType) => (
                              <option value={pickupType.id}>
                                {pickupType.label}
                              </option>
                            ))
                          : null}
                      </FormControl>
                    </React.Fragment>
                  )}
              </Col>
            </Row>
          </div>
        )}
        {this.state.expanded &&
          (pickupsLoaded && !pickupsFetching ? (
            <ListGroup
              className="pickups"
              data-route-id={routeId}
              style={expandedStyle}
            >
              {/*<ListGroupItem className={'clearfix'}>*/}
              {/*  <h4>*/}
              {/*    <a*/}
              {/*      href={`http://maps.google.com/?q=${encodeURIComponent(*/}
              {/*        route.startLocation*/}
              {/*      )}`}*/}
              {/*      target="_blank"*/}
              {/*    >*/}
              {/*      {route.startLocation}*/}
              {/*    </a>*/}
              {/*  </h4>*/}
              {/*  /!*<p>{getFormatedTime(route.startTime)}</p>*!/*/}
              {/*  {route.startedTime ? <div>Route Started @ {Moment(route.startedTime).format('H:mm A')}</div>: */}
              {/*  <ButtonGroup>*/}
              {/*    <ConfirmModal*/}
              {/*        title={`Start Route`}*/}
              {/*        confirmText={`Confirm you want to start this route`}*/}
              {/*        confirmSubText={`A notification will be seen to the first stop that the driver is on the way.`}*/}
              {/*        onConfirm={()=>{this.props.startRoute(routeId)}}*/}
              {/*        buttonText="Start"*/}
              {/*    />*/}
              {/*  </ButtonGroup>*/}
              {/*  }*/}
              {/*</ListGroupItem>*/}

              <DragDropContext
                onDragEnd={async ({ source, destination }) => {
                  //
                  const pickup = pickups.find(
                    (pickup) => pickup.stopNumber === source.index
                  );

                  // Only make a request if a change was made
                  if (pickup.stopNumber !== destination.index) {
                    await this.props.updateRoutePickup({
                      ...pickup,
                      stopNumber: destination.index,
                    });
                    await this.props.requestRoutePickups({ routeId: route.id });
                  }
                }}
              >
                <Droppable droppableId="routes">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {pickups
                        .sort((a, b) => a.stopNumber - b.stopNumber)
                        .map((pickup, index) => (
                          <Draggable
                            key={pickup.id}
                            draggableId={`${pickup.id}`}
                            index={pickup.stopNumber}
                            isDragDisabled={
                              this.props.auth.credentials.userType.label ===
                              "driver"
                            }
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SinglePickup
                                  pickup={pickup}
                                  index={index}
                                  color={route.color}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/*<ListGroupItem className={'clearfix'}>*/}
              {/*  <h4>*/}
              {/*    <a*/}
              {/*      href={`http://maps.google.com/?q=${encodeURIComponent(*/}
              {/*        route.endLocation*/}
              {/*      )}`}*/}
              {/*      target="_blank"*/}
              {/*    >*/}
              {/*      {route.endLocation}*/}
              {/*    </a>*/}
              {/*  </h4>*/}
              {/*  /!*<p>{getFormatedTime(route.endTime)}</p>*!/*/}
              {/*  <ButtonGroup>*/}
              {/*    <Button*/}
              {/*      variant="primary"*/}
              {/*      onClick={() => {*/}
              {/*        // this._handleEditPickupClick(pickup);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      End Route*/}
              {/*    </Button>*/}
              {/*  </ButtonGroup>*/}
              {/*</ListGroupItem>*/}
            </ListGroup>
          ) : (
            <Loader />
          ))}
      </Box>
    );
  }
}

export default SingleRoute;
