import { connect } from "react-redux";
import {
  requestZones,
  createZone,
  updateZone,
  deleteZone,
  requestPickupBlocks,
  createPickupBlock,
  updatePickupBlock,
  deletePickupBlock,
} from "actions/zones.actions";
import { clearPickupBlocks } from "actions/pickup-blocks.actions";

import ZonesComponent from "./zones.component";

const mapStateToProps = (state) => {
  return {
    zones: {
      ...state.zones,
    },
    pickupBlocks: {
      ...state.pickupBlocks,
    },
  };
};

const mapDispatchToProps = {
  requestZones,

  createZone,

  updateZone,

  deleteZone,

  requestPickupBlocks,

  createPickupBlock,

  updatePickupBlock,

  deletePickupBlock,

  clearPickupBlocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZonesComponent);
