import request from "utils/request";

export const REQUEST_PICKUP_ANALYTICS = "REQUEST_PICKUP_ANALYTICS";
export const RECEIVE_PICKUP_ANALYTICS_SUCCESS =
  "RECEIVE_PICKUP_ANALYTICS_SUCCESS";
export const RECEIVE_PICKUP_ANALYTICS_ERROR = "RECEIVE_PICKUP_ANALYTICS_ERROR";

export const REQUEST_NEW_USER_ANALYTICS = "REQUEST_NEW_USER_ANALYTICS";
export const RECEIVE_NEW_USER_ANALYTICS_SUCCESS =
  "RECEIVE_NEW_USER_ANALYTICS_SUCCESS";
export const RECEIVE_NEW_USER_ANALYTICS_ERROR =
  "RECEIVE_NEW_USER_ANALYTICS_ERROR";

export const requestPickupAnalytics = (data) => (dispatch) => {
  dispatch({ type: REQUEST_PICKUP_ANALYTICS });

  return request({
    url: "/pickups/analytics-count",
    params: data,
  })
    .then(
      (res) =>
        new Promise((resolve) =>
          resolve(dispatch(receivePickupAnalyticsSuccess(res.data)))
        )
    )
    .catch(
      (err) =>
        new Promise((resolve) =>
          resolve(dispatch(receivePickupAnalyticsError(err.response)))
        )
    );
};

const receivePickupAnalyticsSuccess = (data) => ({
  type: RECEIVE_PICKUP_ANALYTICS_SUCCESS,
  pickupAnalytics: data,
  receivedAt: new Date(),
});

const receivePickupAnalyticsError = (err) => ({
  type: RECEIVE_PICKUP_ANALYTICS_ERROR,
  error: err,
  receivedAt: new Date(),
});

export const requestNewUserAnalytics = (data) => (dispatch) => {
  dispatch({ type: REQUEST_NEW_USER_ANALYTICS });

  return request({
    url: "/users/analytics/new-user-count",
    params: data,
  })
    .then(
      (res) =>
        new Promise((resolve) =>
          resolve(dispatch(receiveNewUserAnalyticsSuccess(res.data)))
        )
    )
    .catch(
      (err) =>
        new Promise((resolve) =>
          resolve(dispatch(receiveNewUserAnalyticsError(err.response)))
        )
    );
};

const receiveNewUserAnalyticsSuccess = (data) => ({
  type: RECEIVE_NEW_USER_ANALYTICS_SUCCESS,
  newUserAnalytics: data,
  receivedAt: new Date(),
});

const receiveNewUserAnalyticsError = (err) => ({
  type: RECEIVE_NEW_USER_ANALYTICS_ERROR,
  error: err,
  receivedAt: new Date(),
});
