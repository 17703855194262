import { connect } from "react-redux";
// import { getFormValues } from 'redux-form';
import {
  requestRoutes,
  receiveRoutes,
  requestRoutePickups,
  receivePickupRoutes,
  updatePickup,
  requestUsers,
  updateRoute,
  updateRouteCompleted,
  setDriverDate,
  setDate,
  deleteRoute,
  updatePickupOrder,
  sendReminderNotifications,
} from "actions/routes.actions";

import DriverRouteComponent from "./driver-route.component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },

    pickupRoutes: {
      ...state.pickupRoutes,
    },

    pickups: [...state.pickupRoutes.pickups],
    pickupsMin: [...state.pickupRoutes.pickupsMin],
  };
};

const mapDispatchToProps = {
  requestRoutes,

  requestRoutePickups,

  receivePickupRoutes,

  updatePickup,

  updateRoute,

  updateRouteCompleted,

  setDriverDate,

  setDate,

  receiveRoutes,

  sendReminderNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverRouteComponent);
