import React, { useContext, useEffect, useState } from "react";

import {
  NotificationTemplatesContext,
  NotificationTemplatesProvider,
} from "contexts/notification-templates";
import Table, { RowActions, checkComponentOriginProp } from "components/table";
import Modal from "components/modal";
import PageLayout from "layouts/pages/page-default";
import EditNotificationTemplate from "forms/edit-notification-template";
import SMSPreview from "./previews/sms-preview.component";
import EmailPreview from "./previews/email-preview.component";

const NotificationTemplatesTable = () => {
  const {
    notificationTemplates,
    notificationTemplatesLoading,
    getNotificationTemplates,
    updateNotificationTemplate,
  } = useContext(NotificationTemplatesContext);
  useEffect(getNotificationTemplates, []);

  const [editing, setEditing] = useState(false);
  const [emailPreview, setEmailPreview] = useState(false);
  const [smsPreview, setSmsPreview] = useState(false);

  return (
    <PageLayout title="Notifications">
      <Table
        columns={[
          {
            Header: "Label",
            accessor: "label",
          },
          {
            Header: "Email Subject",
            accessor: "emailSubject",
            truncated: true,
          },
          {
            Header: "SMS",
            accessor: "sms",
            truncated: true,
          },
        ]}
        actions={[
          {
            Header: "Actions",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.slug}
                  actions={[
                    {
                      text: "Edit",
                      icon: "edit",
                      onClick: () => setEditing(original),
                    },
                    {
                      text: "Preview Email",
                      icon: "mail",
                      onClick: () => setEmailPreview(original),
                    },
                    {
                      text: "Preview SMS",
                      icon: "comments",
                      onClick: () => setSmsPreview(original),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={notificationTemplates}
        loading={notificationTemplatesLoading}
      />

      <Modal
        show={!!editing}
        onHide={() => setEditing(false)}
        title="Edit Notification Template"
      >
        <EditNotificationTemplate
          initialValues={{
            emailSubject: editing.emailSubject,
            sms: editing.sms,
          }}
          onSubmit={(data) => {
            updateNotificationTemplate(editing.slug, data);
            setEditing(false);
          }}
        />
      </Modal>

      <Modal
        show={!!emailPreview}
        onHide={() => {
          setEmailPreview(false);
        }}
        title="Notification Template Email Preview"
      >
        <EmailPreview emailPreview={emailPreview} />
      </Modal>

      <Modal
        show={!!smsPreview}
        onHide={() => setSmsPreview(false)}
        title="Notification Template SMS Preview"
      >
        <SMSPreview smsPreview={smsPreview} />
      </Modal>
    </PageLayout>
  );
};

const NotificationTemplates = () => (
  <NotificationTemplatesProvider>
    <NotificationTemplatesTable />
  </NotificationTemplatesProvider>
);

export default NotificationTemplates;
