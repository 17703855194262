import { connect } from "react-redux";
import HomeComponent from "./home.component";
import actions from "actions/pickup-statuses.actions";

import {
  requestStats,
  requestPickupsScheduledOnStats,
} from "actions/reports.actions";
// import { requestPickupStatuses } from "actions/pickup-statuses.actions";

const mapStateToProps = (state) => {
  return {
    stats: {
      ...state.stats,
    },

    pickupsScheduledOnStats: {
      ...state.pickupsScheduledOnStats,
    },

    pickupStatuses: [...state.pickupStatuses.data],
  };
};

const mapDispatchToProps = {
  requestStats,
  requestPickupsScheduledOnStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
