import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import polyline from "@mapbox/polyline";
import { formatPhone } from "utils/helpers";
import { Badge } from "react-bootstrap";
import RoutePolyline from "./polylines";
import { LabelMarker, IconMarker, TruckMarker } from "./markers";
import React from "react";
import colors from "utils/generateColors";

import { REACT_APP_MP_GOOGLE_MAPS_API } from "config";

class RouteMap extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.state = {
      zoom: 11,
      center: { lat: 0, lng: 0 },
    };
  }

  shouldDisplayMap() {
    const { pickupRoutes, unnassignedPickups, showUnassignedOnMap } =
      this.props;

    if (
      !pickupRoutes.length &&
      (!unnassignedPickups.length || !showUnassignedOnMap)
    ) {
      return false;
    }

    return !pickupRoutes.some(
      // If finds one where polyline doesn't exist returns true
      ({ polylines }) => !polylines
    );
  }

  //TODO: Shouldn't have to do it this way, routeMap was being updated each time redux-form updated (blur, focus, etc)
  shouldComponentUpdate(nextProps, nextState) {
    let samePickupRoutes =
      JSON.stringify(this.props.pickupRoutes) ===
      JSON.stringify(nextProps.pickupRoutes);
    let samePickupsMap =
      JSON.stringify(this.props.pickupsMap) ===
      JSON.stringify(nextProps.pickupsMap);
    let sameRoutesMap =
      JSON.stringify(this.props.routesMap) ===
      JSON.stringify(nextProps.routesMap);
    let sameDriverPositions =
      JSON.stringify(this.props.driverPositions) ===
      JSON.stringify(nextProps.driverPositions);

    return !(
      samePickupRoutes &&
      samePickupsMap &&
      sameRoutesMap &&
      sameDriverPositions &&
      this.props.showUnassignedOnMap === nextProps.showUnassignedOnMap
    );
  }

  initialMapBoundsSet = false;
  setInitialMapBounds = () => {
    if (this.initialMapBoundsSet) return;
    this.initialMapBoundsSet = true;
    const { pickupRoutes, unnassignedPickups, driverPositions } = this.props;

    const pointsInInitialMapView = pickupRoutes
      .map((route) => polyline.decode(route.polylines, 6))
      .flat(1)
      .concat(
        unnassignedPickups.map((pickup) => [
          pickup.location.lat,
          pickup.location.lng,
        ])
      )
      .concat(
        Object.values(driverPositions).map((position) => [
          position.lat,
          position.lng,
        ])
      )
      .map(([lat, lng]) => new window.google.maps.LatLng(lat, lng));
    const mapInitialBounds = new window.google.maps.LatLngBounds();
    for (const point of pointsInInitialMapView) mapInitialBounds.extend(point);

    this.mapRef.current.fitBounds(mapInitialBounds);
  };

  render() {
    const {
      routesMap,
      pickupRoutes,
      pickupsMap,
      unnassignedPickups,
      showUnassignedOnMap,
      driverPositions,
      onMarkerClick,
    } = this.props;

    if (
      (!pickupRoutes.length &&
        (!unnassignedPickups.length || !showUnassignedOnMap)) ||
      !this.shouldDisplayMap()
    ) {
      return null;
    }

    let markers = [];
    let polylines = [];

    // Unassigned Pickups
    if (showUnassignedOnMap)
      unnassignedPickups.map((p, index) => {
        markers.push(
          <IconMarker
            onClick={() => onMarkerClick(p)}
            position={{ lat: p.location.lat, lng: p.location.lng }}
            label={index + 1}
            color={"#000"}
            InfoComponent={() => (
              <div>
                <h3>{`${p.user.fullName}`}</h3>
                <div>{`${p.location.fullAddress}`}</div>
                <Badge variant={"primary"}>{p.pickupStatus.label}</Badge>
              </div>
            )}
          />
        );
      });

    pickupRoutes
      .sort(function (route1, route2) {
        return route1.position - route2.position;
      })
      .map((route, routeIndex) => {
        if (!routesMap[route.id].pickups.length > 0) {
          return null;
        }

        // let decodedPoly = MapboxPolyline.decode(routesMap[route.id].polylines)
        polylines.push(
          <RoutePolyline
            color={colors[routesMap[route.id].position]}
            polyline={routesMap[route.id].polylines}
          />
        );

        routesMap[route.id].pickups.map((pickupId, pickupIndex) => {
          // First Marker Should be Starting Location
          markers.push(
            <IconMarker
              onClick={() => onMarkerClick(pickupsMap[pickupId])}
              label={pickupIndex + 1}
              color={colors[routesMap[route.id].position]}
              position={{
                lat: pickupsMap[pickupId].location.lat,
                lng: pickupsMap[pickupId].location.lng,
              }}
              InfoComponent={() => (
                <div>
                  <h3>{`${pickupsMap[pickupId].user.fullName}`}</h3>
                  <div>{`${pickupsMap[pickupId].location.fullAddress}`}</div>
                  <div>{`${
                    isNaN(pickupsMap[pickupId].distanceFromPreviousMiles)
                      ? ""
                      : pickupsMap[pickupId].distanceFromPreviousMiles
                  } miles`}</div>
                  {/*<div>*/}
                  {/*  <a*/}
                  {/*    href={`tel:${pickupsMap[pickupId].user.phoneNumbers[0].number}`}*/}
                  {/*  >{`${formatPhone(*/}
                  {/*    pickupsMap[pickupId].user.phoneNumbers[0].number*/}
                  {/*  )}`}</a>*/}
                  {/*</div>*/}
                  <Badge variant={"primary"}>
                    {pickupsMap[pickupId].pickupStatus.label}
                  </Badge>
                </div>
              )}
            />
          );
        });

        markers.push(
          <LabelMarker position={route.startCoordinates} label={"start"} />
        );
        markers.push(
          <LabelMarker position={route.endCoordinates} label={"end"} />
        );
      });

    for (const driver of Object.values(driverPositions))
      markers.push(
        <TruckMarker
          color={colors[routesMap[driver.routeId].position]}
          position={{ lat: driver.lat, lng: driver.lng }}
          // label={`T${routesMap[driver.routeId].position}`}
          label={``}
          InfoComponent={() => (
            <div>
              <h3>{`${routesMap[driver.routeId].driver.fullName}`}</h3>
            </div>
          )}
        />
      );

    const MyMapComponent = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          ref={this.mapRef}
          zoom={this.state.zoom}
          center={this.state.center}
          onZoomChanged={() =>
            this.setState({ zoom: this.mapRef.current.getZoom() })
          }
          onCenterChanged={() =>
            this.setState({ center: this.mapRef.current.getCenter() })
          }
          onTilesLoaded={this.setInitialMapBounds}
          options={{
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          {markers}
          {polylines}
        </GoogleMap>
      ))
    );

    return (
      <div
        style={{
          height: "800px",
          marginBottom: 40,
          backgroundColor: "#e7e7e7",
        }}
      >
        <MyMapComponent
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_MP_GOOGLE_MAPS_API}&v=3.exp&libraries=drawing,places,geometry`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `800px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default RouteMap;
