import { connect } from "react-redux";
import { requestRoutePickupBlocks } from "actions/routes.actions";

import RoutePickup from "./route-import-export.component";

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },

  pickupRoutes: {
    ...state.pickupRoutes,
  },

  routePickupBlocks: {
    pickupBlocks: state.routePickupBlocks.routePickupBlocks,
    isFetching: state.routePickupBlocks.isFetching,
    loaded: state.routePickupBlocks.loaded,
  },

  regionId: state.regions.activeRegion.region.id,
});

const mapDispatchToProps = {
  requestRoutePickupBlocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutePickup);
