import React from "react";
import { Tab } from "react-bootstrap";

import TabContext from "./tab-context";

const Tabs = ({
  eq = (a, b) => a === b,
  active,
  onChange,
  onRemove = null,
  header = null,
  children,
}) => {
  return (
    <TabContext.Provider value={{ eq, active, onChange, onRemove }}>
      <Tab.Container id="donor-search-tabs" activeKey={active}>
        <div className="donor-search-tabs_header">
          <ul role="tablist" className="nav nav-tabs">
            {children}
          </ul>
          {header}
        </div>
      </Tab.Container>
    </TabContext.Provider>
  );
};

export default Tabs;
