import React from "react";
import config from "config";
import Loader from "components/loader";

function WithLoaderHOC(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return <Loader color={config.REACT_APP_MP_PRIMARY_COLOR} />;
  };
}
const WithLoader = ({
  isLoading = true,
  cssHide = false,
  children,
  fullScreen,
}) => {
  if (cssHide) {
    return (
      <>
        <div style={!isLoading ? { display: "none" } : { display: "contents" }}>
          <Loader
            fullScreen={fullScreen}
            color={config.REACT_APP_MP_PRIMARY_COLOR}
          />
        </div>
        <div style={isLoading ? { display: "none" } : { display: "contents" }}>
          {children}
        </div>
      </>
    );
  } else if (isLoading) {
    return (
      <Loader
        fullScreen={fullScreen}
        color={config.REACT_APP_MP_PRIMARY_COLOR}
      />
    );
  } else {
    return <>{children}</>;
  }
};
export default WithLoader;
export { WithLoaderHOC };
