import { useCallback, useState } from "react";

import LocationTypesContext from "./context";
import {
  createLocationType,
  getLocationTypes,
  updateLocationType,
} from "api/location-types";
import useResource from "../useResource";

const LocationTypesProvider = ({ children }) => {
  const [
    locationTypes,
    setLocationTypes,
    locationTypesLoading,
    locationTypesError,
    clearLocationTypesError,
    wrapper,
    locationTypesLoaded,
  ] = useResource([]);

  const getTypes = wrapper(
    useCallback((opts) => getLocationTypes(opts).then(setLocationTypes), [])
  );

  const createType = wrapper(
    useCallback(
      (type) =>
        createLocationType(type).then((type) =>
          setLocationTypes([...locationTypes, type])
        ),
      [locationTypes]
    )
  );

  const updateType = wrapper(
    useCallback(
      (type) =>
        updateLocationType(type).then((updated) =>
          setLocationTypes(
            locationTypes.map((type) =>
              type.id === updated.id ? updated : type
            )
          )
        ),
      [locationTypes]
    )
  );

  return (
    <LocationTypesContext.Provider
      value={{
        locationTypes,
        locationTypesLoading,
        locationTypesError,
        locationTypesLoaded,
        getLocationTypes: getTypes,
        createLocationType: createType,
        updateLocationType: updateType,
        clearLocationTypesError,
      }}
    >
      {children}
    </LocationTypesContext.Provider>
  );
};

export default LocationTypesProvider;
