import { getUser, updateUser } from "api/users";
export const REQUEST_CUSTOMER = "REQUEST_CUSTOMER";
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_COMPLETED = "UPDATE_CUSTOMER_COMPLETED";

export function requestCustomer(userId) {
  return (dispatch) => {
    dispatch({ type: REQUEST_CUSTOMER, data: { customerId: userId } });
    getUser(userId)
      .then((res) => dispatch(receiveCustomer(res.data)))
      .catch((err) => alert(JSON.stringify(err.response.data)));
  };
}

function receiveCustomer(data = { data: {} }) {
  return {
    type: RECEIVE_CUSTOMER,
    customer: data.data[0],
    receivedAt: Date.now(),
  };
}

export function clearCustomer() {
  return { type: CLEAR_CUSTOMER };
}

export function updateCustomer(data = {}) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER,
      data,
    });

    return updateUser(data.id, data)
      .then((res) => dispatch(updateCustomerCompleted(res.data)))
      .catch((err) => {
        // alert(JSON.stringify(err.response.data));
        dispatch(requestCustomer(data.id));
      });
  };
}

function updateCustomerCompleted(data = {}) {
  return {
    type: UPDATE_CUSTOMER_COMPLETED,
    customer: data.data[0],
    receivedAt: Date.now(),
  };
}
