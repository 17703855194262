import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_CODE,
  AUTHENTICATE_USER_COMPLETE,
  AUTHENTICATE_USER_FAILED,
  LOGOUT_USER,
} from "actions/login.actions";

function auth(
  state = {
    isFetching: false,
    loaded: false,
    credentials: {},
    authenticated: false,
  },
  action
) {
  switch (action.type) {
    case AUTHENTICATE_USER:
    case AUTHENTICATE_USER_CODE:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case AUTHENTICATE_USER_COMPLETE:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        credentials: action.auth,
        authenticated: true,
        lastUpdated: action.receivedAt,
      };
    case AUTHENTICATE_USER_FAILED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        credentials: {},
        authenticated: false,
        lastUpdated: action.receivedAt,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        credentials: {},
        authenticated: false,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export default { auth };
